export const loadLocalStorage = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err: unknown) {
    return undefined;
  }
};

export const saveLocalStorage = (key: string, state: unknown) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err: unknown) {
    if (err instanceof Error) {
      window.Rollbar.error(err);
    }
  }
};

export const clearLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err: unknown) {
    if (err instanceof Error) {
      window.Rollbar.error(err);
    }
  }
};
