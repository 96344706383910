import { Reducer } from 'redux';
import { EventActions, EventActionTypes, EventState } from './types';

const initialState: EventState = {
  event: null,
  organizerEventState: null, // Default to live segment
  organizerRoomGroupId: null,
  permissions: null,
  pageState: null,
  isLoading: false,
  error: false,
  systemCheckPassed: false,
};

export const event: Reducer<EventState> = (
  state: EventState = initialState,
  action: EventActions
) => {
  switch (action.type) {
    case EventActionTypes.LOAD_EVENT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EventActionTypes.LOAD_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        event: action.event,
        permissions: action.permissions,
        organizerEventState: null,
        pageState: action.breakoutRoomId,
      };
    case EventActionTypes.LOAD_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        event: null,
        permissions: null,
        pageState: null,
      };
    case EventActionTypes.EVENT_STATE_CHANGE:
      return {
        ...state,
        event: {
          ...state.event,
        },
        pageState: action.pageState,
      };
    case EventActionTypes.PAGE_STATE_CHANGE:
      return {
        ...state,
        pageState: action.pageState,
      };
    case EventActionTypes.ORGANIZER_EVENT_STATE_CHANGE:
      return {
        ...state,
        organizerEventState: action.eventState,
        organizerRoomGroupId: action.roomGroupId,
      };
    case EventActionTypes.SYSTEM_CHECK_PASSED:
      return {
        ...state,
        systemCheckPassed: true,
      };
    case EventActionTypes.MOVE_ATTENDEE:
      return {
        ...state,
        organizerEventState: action.eventState,
        pageState: action.pageState,
      };
    case EventActionTypes.MOVE_TO_LIVE_TRACK:
      return {
        ...state,
        pageState: null,
        organizerEventState: null,
        organizerRoomGroupId: null,
      };
    default:
      return state;
  }
};
