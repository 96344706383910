import {
  httpAccepted,
  httpNoContent,
  httpNotFound,
} from '@common/httpStatusCodes';
import { API_URL } from '@venue/services/venue';
import axios from 'axios';
import { StarRating } from './types';

export const fetchExistenceOfPriorQuantitativeFeedback = async () => {
  const response = await axios.get<void>(
    `${API_URL}/presenter_quantitative_feedback`,
    // By validating status, 404 can be passed to the caller of this function, and not be treated as an error
    {
      validateStatus: (status) =>
        [httpNoContent, httpNotFound].includes(status),
    }
  );
  return response.status === httpNotFound;
};

export const submitPresenterQuantitativeFeedback = async ({
  venueExperienceRating,
  audienceEngagementRating,
  wasDismissed,
}: {
  venueExperienceRating: StarRating;
  audienceEngagementRating: StarRating;
  wasDismissed: boolean;
}) => {
  const response = await axios.post<void>(
    `${API_URL}/presenter_quantitative_feedback`,
    {
      venue_experience_rating: venueExperienceRating,
      audience_engagement_rating: audienceEngagementRating,
      dismiss: wasDismissed,
    }
  );
  return response.status === httpAccepted;
};
