import { ChangeEventHandler, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { STAR_RATINGS } from './constants';
import { StarRating } from './types';

export interface StarRatingRadioProps {
  value: StarRating;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const StarRatingRadio = ({
  value: currentValue,
  onChange,
}: StarRatingRadioProps) => {
  const [hover, setHover] = useState<StarRating>(null);

  return (
    <>
      <div className='tw-text-3xl tw-flex tw-items-center tw-justify-center'>
        {STAR_RATINGS.map((value) => (
          <label
            key={value}
            className='tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-2'
            onMouseEnter={() => setHover(value)}
            onMouseLeave={() => setHover(currentValue)}
          >
            <input
              type='radio'
              name='audience_engagement_rating'
              value={value}
              className='tw-hidden tw-peer'
              checked={value === currentValue}
              onChange={onChange}
            />
            {/* On hover, continuously fill with colour the emoji in use */}
            <span
              className={`${
                value <= hover ? '' : 'filter-grayscale'
              } tw-transition-all hover:tw-filter-none peer-checked:tw-text-4xl peer-checked:tw-filter-none`}
            >
              ⭐️
            </span>
          </label>
        ))}
      </div>
      {/* On hover, show the kind of rating the user would be submitting */}
      <div className='tw-h-[2em] tw-text-sm tw-flex tw-items-center tw-justify-center'>
        {hover === '1' ? <FormattedMessage defaultMessage='Terrible' /> : null}
        {hover === '2' ? <FormattedMessage defaultMessage='Poor' /> : null}
        {hover === '3' ? <FormattedMessage defaultMessage='Average' /> : null}
        {hover === '4' ? <FormattedMessage defaultMessage='Good' /> : null}
        {hover === '5' ? <FormattedMessage defaultMessage='Great' /> : null}
      </div>
    </>
  );
};
