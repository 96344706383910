import { LocalStreamState } from '@venue/types/agora';
import { ScreenShareStatus } from '@venue/types/stage';
import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { StreamsActions, StreamsActionTypes, StreamsState } from './types';

const initialState: StreamsState = {
  clientId: null,
  localStreamId: null,
  remoteStreamIds: [],
  localStreamState: LocalStreamState.Idle,
  screenShareClientId: null,
  localScreenStreamId: null,
  remoteScreenStreamId: null,
  audioMutedMap: {},
  videoMutedMap: {},
  disconnectedMap: {},
  screenShareStatus: ScreenShareStatus.Available,
  recordingStatuses: [],
  channelName: null,
};

export const streams: Reducer<StreamsState> = produce(
  (draft: Draft<StreamsState>, action: StreamsActions) => {
    switch (action.type) {
      case StreamsActionTypes.SET_SCREEN_SHARE_AGORA_CLIENT:
        draft.screenShareClientId = action.clientId;
        break;
      case StreamsActionTypes.SET_LOCAL_STREAM_STATE:
        draft.localStreamState = action.state;
        break;
      case StreamsActionTypes.SET_AUDIO_MUTED_MAP:
        draft.audioMutedMap[action.streamId] = action.isMuted;
        break;
      case StreamsActionTypes.SET_VIDEO_MUTED_MAP:
        draft.videoMutedMap[action.streamId] = action.isMuted;
        break;
      case StreamsActionTypes.SET_DISCONNECTED_MAP:
        draft.disconnectedMap = action.disconnectedMap;
        break;
      case StreamsActionTypes.SET_SCREEN_SHARE_STATUS:
        draft.screenShareStatus = action.status;
        break;
      case StreamsActionTypes.SET_RECORDING_STATUSES:
        draft.recordingStatuses = action.statuses;
        break;
      case StreamsActionTypes.SET_CHANNEL_NAME:
        // We need an ID to signal that the client has finished joining the room
        draft.clientId = Math.random().toString(36).substring(7);
        draft.channelName = action.channelName;
        break;
      case StreamsActionTypes.RESET_RTC:
        draft.channelName = null;
        draft.audioMutedMap = {};
        draft.videoMutedMap = {};
        draft.disconnectedMap = {};
        draft.clientId = null;
        draft.localStreamState = LocalStreamState.Idle;
        break;
      case StreamsActionTypes.DESTROY_LOCAL_STREAM:
        draft.localStreamState = LocalStreamState.Idle;
        draft.audioMutedMap[action.streamId] = null;
        draft.videoMutedMap[action.streamId] = null;
        draft.disconnectedMap[action.streamId] = null;
        draft.localStreamId = null;
        break;
      case StreamsActionTypes.REMOVE_STREAM:
        draft.audioMutedMap[action.streamId] = null;
        draft.videoMutedMap[action.streamId] = null;
        draft.disconnectedMap[action.streamId] = null;
        break;
      case StreamsActionTypes.CLEAR_STREAMS:
        draft.localScreenStreamId = null;
        draft.remoteScreenStreamId = null;
        draft.localStreamId = null;
        draft.remoteStreamIds = [];
        draft.screenShareStatus = ScreenShareStatus.Available;
        break;
      case StreamsActionTypes.SET_STREAM_IDS:
        draft.localStreamId = action.localStreamId;
        draft.remoteStreamIds = action.remoteStreamIds;
        draft.localScreenStreamId = action.localScreenStreamId;
        draft.remoteScreenStreamId = action.remoteScreenStreamId;
        if (action.remoteScreenStreamId) {
          draft.screenShareStatus = ScreenShareStatus.RemotelyBusy;
        } else if (action.localScreenStreamId) {
          draft.screenShareStatus = ScreenShareStatus.LocallyBusy;
        } else {
          draft.screenShareStatus = ScreenShareStatus.Available;
        }
        break;
    }
  },
  initialState
);
