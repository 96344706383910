import { defineMessage } from 'react-intl';

export const VideoShapeChoices = {
  circle: defineMessage({
    defaultMessage: 'Circle',
    description: 'video shape',
  }),
  square: defineMessage({
    defaultMessage: 'Square',
    description: 'video shape',
  }),
  hidden: defineMessage({
    defaultMessage: 'Hidden',
    description: 'video shape',
  }),
};

export type VideoShape = keyof typeof VideoShapeChoices;

export const DEFAULT_SHAPE: VideoShape = 'circle';

export interface ShapeStyle {
  opacity: string;
  borderRadius: string;
}

export const SHAPE_TABLE: {
  [key in VideoShape]: ShapeStyle;
} = {
  circle: {
    opacity: '100%',
    borderRadius: '100%',
  },
  square: {
    opacity: '100%',
    borderRadius: '0.625rem',
  },
  hidden: {
    opacity: '0%',
    borderRadius: '100%',
  },
};

export const VideoSizeChoices = {
  'extra-small': defineMessage({
    defaultMessage: 'Extra Small',
    description: 'video size',
  }),
  small: defineMessage({
    defaultMessage: 'Small',
    description: 'video size',
  }),
  medium: defineMessage({
    defaultMessage: 'Medium',
    description: 'video size',
  }),
  large: defineMessage({
    defaultMessage: 'Large',
    description: 'video size',
  }),
  'extra-large': defineMessage({
    defaultMessage: 'Extra Large',
    description: 'video size',
  }),
};

export type VideoSize = keyof typeof VideoSizeChoices;

export const DEFAULT_SIZE: VideoSize = 'small';

export type VideoSizeWidth = '15vh' | '20vh' | '30vh' | '40vh' | '60vh';

export const SIZE_TABLE: {
  [key in VideoSize]: {
    size: VideoSizeWidth;
    slider: number;
  };
} = {
  'extra-small': {
    size: '15vh',
    slider: 1,
  },
  small: {
    size: '20vh',
    slider: 2,
  },
  medium: {
    size: '30vh',
    slider: 3,
  },
  large: {
    size: '40vh',
    slider: 4,
  },
  'extra-large': {
    size: '60vh',
    slider: 5,
  },
};

export const SLIDER_TABLE: { [key: number]: VideoSize } = Object.fromEntries(
  Object.entries(SIZE_TABLE).map(([size, entry]) => [
    entry.slider,
    size as VideoSize,
  ])
);

export const VideoPlacementChoices = {
  'top-left': defineMessage({
    defaultMessage: 'Top Left',
    description: 'video placement',
  }),
  top: defineMessage({
    defaultMessage: 'Top',
    description: 'video placement',
  }),
  'top-right': defineMessage({
    defaultMessage: 'Top Right',
    description: 'video placement',
  }),
  right: defineMessage({
    defaultMessage: 'Right',
    description: 'video placement',
  }),
  'bottom-right': defineMessage({
    defaultMessage: 'Bottom Right',
    description: 'video placement',
  }),
  bottom: defineMessage({
    defaultMessage: 'Bottom',
    description: 'video placement',
  }),
  'bottom-left': defineMessage({
    defaultMessage: 'Bottom Left',
    description: 'video placement',
  }),
  left: defineMessage({
    defaultMessage: 'Left',
    description: 'video placement',
  }),
  center: defineMessage({
    defaultMessage: 'Center',
    description: 'video placement',
  }),
};

export type VideoPlacement = keyof typeof VideoPlacementChoices;

export const DEFAULT_PLACEMENT: VideoPlacement = 'top-right';

export interface PlacementStyle {
  left: string;
  top: string;
  transform: string;
  next: VideoPlacement;
}

export const PLACEMENT_TABLE: {
  [key in VideoPlacement]: PlacementStyle;
} = {
  'top-left': {
    left: '0%',
    top: '0%',
    transform: 'translate(0, 0)',
    next: 'top-right',
  },
  top: {
    left: '50%',
    top: '0%',
    transform: 'translate(-50%, 0)',
    next: 'top-right',
  },
  'top-right': {
    left: '100%',
    top: '0%',
    transform: 'translate(-100%, 0)',
    next: 'bottom-right',
  },
  right: {
    left: '100%',
    top: '50%',
    transform: 'translate(-100%, -50%)',
    next: 'bottom-right',
  },
  'bottom-right': {
    left: '100%',
    top: '100%',
    transform: 'translate(-100%, -100%)',
    next: 'bottom-left',
  },
  bottom: {
    left: '50%',
    top: '100%',
    transform: 'translate(-50%, -100%)',
    next: 'bottom-left',
  },
  'bottom-left': {
    left: '0%',
    top: '100%',
    transform: 'translate(0, -100%)',
    next: 'top-left',
  },
  left: {
    left: '0%',
    top: '50%',
    transform: 'translate(0, -50%)',
    next: 'top-left',
  },
  center: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    next: 'top-left',
  },
};
