import { trackEvent } from '@common/services/posthog.service';
import { trackError } from '@common/services/rollbar.service';
import {
  areDeviceListsDifferent,
  getLabelOfPreferredAudioVideoDeviceFetchingLocalStorage,
} from '@venue/features/audioVideoDevices/audioVideoDevices';
import {
  switchCamera as switchNetworkingCamera,
  switchMicrophone as switchNetworkingMicrophone,
  switchSpeakers as switchNetworkingSpeakers,
} from '@venue/store/networking/actions';
import {
  switchCamera as switchStreamsCamera,
  switchMicrophone as switchStreamsMicrophone,
  switchSpeakers as switchStreamsSpeakers,
} from '@venue/store/streams/actions';
import { RootState } from '@venue/store/types';
import { DeviceTypes, RequestStatus } from '@venue/types/device';
import {
  ApplicationThunkAction,
  ApplicationThunkDispatch,
} from '@venue/types/thunk';
import {
  AvailableBreakpoint,
  DeviceActionTypes,
  LocaleCode,
  SetBreakpointAction,
  SetCameraAction,
  SetDevicesAction,
  SetLocaleAction,
  SetMicrophoneAction,
  SetRequestStatusAction,
  SetSpeakersAction,
  ShowAudienceGalleryAction,
  ShowEmojiCannonAction,
} from './types';

export const setBreakpoint = (
  breakpoint: AvailableBreakpoint
): SetBreakpointAction => ({
  type: DeviceActionTypes.SET_BREAKPOINT,
  breakpoint,
});

export const setCamera = (deviceLabel: string): SetCameraAction => ({
  type: DeviceActionTypes.SET_CAMERA,
  deviceLabel,
});

export const switchCamera =
  (deviceLabel: string): ApplicationThunkAction =>
  async (dispatch): Promise<void> => {
    await (deviceLabel
      ? Promise.all([
          dispatch(switchNetworkingCamera(deviceLabel)),
          dispatch(switchStreamsCamera(deviceLabel)),
        ]).then(() => {})
      : Promise.resolve());
    if (deviceLabel) {
      dispatch(setCamera(deviceLabel));
      console.log(`switched camera to ${deviceLabel}`);
    }
  };

export const setMicrophone = (deviceLabel: string): SetMicrophoneAction => ({
  type: DeviceActionTypes.SET_MICROPHONE,
  deviceLabel,
});

export const switchMicrophone =
  (deviceLabel: string): ApplicationThunkAction =>
  async (dispatch): Promise<void> => {
    console.log(`switching microphone to ${deviceLabel}`);
    await (deviceLabel
      ? Promise.all([
          dispatch(switchNetworkingMicrophone(deviceLabel)),
          dispatch(switchStreamsMicrophone(deviceLabel)),
        ]).then(() => {})
      : Promise.resolve());
    if (deviceLabel) {
      dispatch(setMicrophone(deviceLabel));
      console.log(`switched microphone to ${deviceLabel}`);
    }
  };

const setSpeakers = (deviceLabel: string): SetSpeakersAction => ({
  type: DeviceActionTypes.SET_SPEAKERS,
  deviceLabel,
});

export const switchSpeakers =
  (deviceLabel: string): ApplicationThunkAction =>
  async (dispatch: ApplicationThunkDispatch): Promise<void> => {
    try {
      await (deviceLabel
        ? Promise.all([
            dispatch(switchStreamsSpeakers(deviceLabel)),
            dispatch(switchNetworkingSpeakers(deviceLabel)),
          ])
        : Promise.resolve());
      if (deviceLabel) {
        console.log(`switched speakers to ${deviceLabel}`);
        dispatch(setSpeakers(deviceLabel));
      }
    } catch (err) {
      console.log(
        'error while trying to switch to speakers with device label:',
        deviceLabel
      );
      throw err;
    }
  };

const setDevicesList = (devices: Array<MediaDeviceInfo>): SetDevicesAction => ({
  type: DeviceActionTypes.SET_DEVICES,
  devices,
});

export const setRequestStatus = (
  requestStatus: RequestStatus
): SetRequestStatusAction => ({
  type: DeviceActionTypes.SET_REQUEST_STATUS,
  requestStatus,
});

export const setLocale = (locale: LocaleCode): SetLocaleAction => ({
  type: DeviceActionTypes.SET_LOCALE,
  locale,
});

export const refreshAudioVideoDevicesList =
  (): ApplicationThunkAction => (dispatch) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      dispatch(setDevicesList(devices));
    });
  };

// When we set preferred devices
export const setPreferredDevices =
  (
    devices: Array<MediaDeviceInfo>
  ): ApplicationThunkAction<Array<{ type: DeviceTypes; label: string }>> =>
  (dispatch, getState): Array<{ type: DeviceTypes; label: string }> => {
    dispatch(setDevicesList(devices));

    const switchedDevices = [];

    const newCameraLabel =
      getLabelOfPreferredAudioVideoDeviceFetchingLocalStorage({
        devices: devices,
        type: DeviceTypes.VideoInput,
      });
    if (!!newCameraLabel && newCameraLabel !== getState().device?.cameraLabel) {
      dispatch(switchCamera(newCameraLabel));
      switchedDevices.push({
        type: DeviceTypes.VideoInput,
        label: newCameraLabel,
      });
    }

    const newMicrophoneLabel =
      getLabelOfPreferredAudioVideoDeviceFetchingLocalStorage({
        devices,
        type: DeviceTypes.AudioInput,
      });
    if (
      !!newMicrophoneLabel &&
      newMicrophoneLabel !== getState().device?.microphoneLabel
    ) {
      dispatch(switchMicrophone(newMicrophoneLabel));
      switchedDevices.push({
        type: DeviceTypes.AudioInput,
        label: newMicrophoneLabel,
      });
    }

    const newSpeakersLabel =
      getLabelOfPreferredAudioVideoDeviceFetchingLocalStorage({
        devices,
        type: DeviceTypes.AudioOutput,
      });
    if (
      !!newSpeakersLabel &&
      newSpeakersLabel !== getState().device?.speakersLabel
    ) {
      dispatch(switchSpeakers(newSpeakersLabel));
      switchedDevices.push({
        type: DeviceTypes.AudioOutput,
        label: newSpeakersLabel,
      });
    }

    return switchedDevices;
  };

export const requestDeviceAccess =
  (audio: boolean, video: boolean): ApplicationThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    dispatch(setRequestStatus(RequestStatus.Requested));
    try {
      await navigator.mediaDevices.getUserMedia({ audio, video });
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      dispatch(setRequestStatus(RequestStatus.Allowed));
      if (hasDeviceListChanged(getState(), mediaDevices)) {
        dispatch(setPreferredDevices(mediaDevices));
      }
      return true;
    } catch (err) {
      dispatch(setRequestStatus(RequestStatus.Blocked));
      const mediaDevices_1 = await navigator.mediaDevices.enumerateDevices?.();
      console.log(
        'Failed requestDeviceAccess, enumerateDevices: ',
        mediaDevices_1
      );
      if (mediaDevices_1.length > 0) {
        if (hasDeviceListChanged(getState(), mediaDevices_1)) {
          dispatch(setPreferredDevices(mediaDevices_1));
        }
        return true;
      } else {
        trackError(
          new Error('Failed to access input devices (camera & microphone)'),
          err
        );
        return false;
      }
    }
  };

const hasDeviceListChanged = (
  state: RootState,
  devices: Array<MediaDeviceInfo>
): boolean => {
  if (state.device.requestStatus === RequestStatus.Requested) {
    return true;
  }
  return areDeviceListsDifferent(state.device.devices, devices);
};

export const showEmojiCannonAction = (show: boolean): ShowEmojiCannonAction => {
  trackEvent({
    name: 'Emoji',
    attributes: { action: 'toggled visibility', show },
  });
  return {
    type: DeviceActionTypes.SHOW_EMOJI_CANNON,
    show,
  };
};

export const showAudienceGalleryAction = (
  show: boolean
): ShowAudienceGalleryAction => {
  if (show) {
    trackEvent({
      name: 'Audience Gallery::Enable',
      attributes: {},
    });
  } else {
    trackEvent({
      name: 'Audience Gallery::Disable',
      attributes: {},
    });
  }

  return {
    type: DeviceActionTypes.SHOW_AUDIENCE_GALLERY,
    show,
  };
};
