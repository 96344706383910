import { Subscription } from 'rxjs';

export interface UserProfilesContextValue {
  byListenerId: Map<string, { userId: string; subscription: Subscription }>;
  byUserId: Map<
    string,
    { listenerIds: Array<string>; unsubscriber: () => void }
  >;
}

const userProfilesContextValue: UserProfilesContextValue = {
  byListenerId: new Map<
    string,
    { userId: string; subscription: Subscription }
  >(),
  byUserId: new Map<
    string,
    { listenerIds: Array<string>; unsubscriber: () => void }
  >(),
};

export const getUserProfiles = () => userProfilesContextValue;
