import {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DATABASE_URL,
  ENV_FIREBASE_EMULATOR,
  ENV_FIREBASE_EMULATOR_AUTH_PORT,
  ENV_FIREBASE_EMULATOR_DATABASE_PORT,
  ENV_FIREBASE_EMULATOR_FIRESTORE_PORT,
  ENV_FIREBASE_EMULATOR_FUNCTIONS_PORT,
  ENV_FIREBASE_EMULATOR_STORAGE_PORT,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MESSAGING_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
} from '@common/env';
import { FIREBASE_TOKEN_KEY } from '@venue/services/venue';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  inMemoryPersistence,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import {
  connectFirestoreEmulator,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { API_URL } from './venue';

// This is needed for react-redux-firebase
// https://github.com/prescottprue/react-redux-firebase/issues/1145
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: ENV_FIREBASE_API_KEY,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_FIREBASE_DATABASE_URL,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV_FIREBASE_APP_ID,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
export const authApp = getAuth(firebaseApp);
export const firestoreApp = getFirestore(firebaseApp);
export const databaseApp = getDatabase(firebaseApp);
export const storageApp = getStorage(firebaseApp);
export const functionsApp = getFunctions(firebaseApp);
export const timestamp = serverTimestamp();

if (ENV_FIREBASE_EMULATOR) {
  connectAuthEmulator(
    authApp,
    `http://localhost:${ENV_FIREBASE_EMULATOR_AUTH_PORT}`
  );
  connectFirestoreEmulator(
    firestoreApp,
    'localhost',
    +ENV_FIREBASE_EMULATOR_FIRESTORE_PORT
  );
  firebase
    .firestore()
    .useEmulator('localhost', +ENV_FIREBASE_EMULATOR_FIRESTORE_PORT);
  connectDatabaseEmulator(
    databaseApp,
    'localhost',
    +ENV_FIREBASE_EMULATOR_DATABASE_PORT
  );
  connectStorageEmulator(
    storageApp,
    'localhost',
    +ENV_FIREBASE_EMULATOR_STORAGE_PORT
  );
  connectFunctionsEmulator(
    functionsApp,
    'localhost',
    +ENV_FIREBASE_EMULATOR_FUNCTIONS_PORT
  );
}

authApp.setPersistence(
  process.env.NODE_ENV === 'test'
    ? inMemoryPersistence
    : browserSessionPersistence
);

export const getFirebaseUser = () => {
  return authApp.currentUser;
};

export const getFirebaseToken = async (eventId, token): Promise<string> => {
  const result = await httpsCallable<
    { eventId: string; token: string },
    { token: string }
  >(
    functionsApp,
    'getFirebaseToken2ndGen'
  )({
    eventId,
    token,
  });
  return result.data.token;
};

export const getFirebaseTokenFromVenue = (eventId: string): Promise<string> => {
  return axios
    .get(API_URL + `/events/${eventId}/firebase_token`)
    .then((response) => response.data.token);
};

export const signInWithToken = (token: string) => {
  return signInWithCustomToken(authApp, token);
};

const googleProvider = new GoogleAuthProvider();

googleProvider.addScope('profile');
googleProvider.addScope('email');

export const signIn = () => signInWithPopup(authApp, googleProvider);

export const getIdToken = () =>
  authApp.currentUser ? authApp.currentUser.getIdToken(true) : Promise.reject();

export const validateFirebaseToken = async (token: string): Promise<void> => {
  const credentials = await signInWithToken(token);
  const result = await credentials.user.getIdTokenResult();
  axios.defaults.headers.common[FIREBASE_TOKEN_KEY] = result.token;
};
