import { DocumentReference, FieldValue, Timestamp } from 'firebase/firestore';

export enum Command {
  DemoteSpeaker = 'DEMOTE_SPEAKER', // is fully closedLoop (except screen share)
  KeepSinglePersonOnStage = 'KEEP_SINGLE_PERSON_ON_STAGE', // is fully closedLoop
  KickAllSpeaker = 'KICK_ALL_SPEAKER', // is fully closedLoop
  KickSpeaker = 'KICK_SPEAKER', // is fully closedLoop (except screen share)
  MoveAttendee = 'MOVE_ATTENDEE', // is fully closedLoop
  MuteAudio = 'MUTE_AUDIO', // is fully closedLoop
  MuteVideo = 'MUTE_VIDEO', // is fully closedLoop
  PromoteSpeaker = 'PROMOTE_SPEAKER', // is fully closedLoop (except screen share)
  RefreshPage = 'REFRESH_PAGE', // is fully closedLoop
  StartSystemCheck = 'START_SYSTEM_CHECK', // is fully closedLoop
  UnmuteAudio = 'UNMUTE_AUDIO', // is fully closedLoop
  UnmuteVideo = 'UNMUTE_VIDEO', // is fully closedLoop
}

export interface CommandEventHistory {
  event: string;
  timestamp: number;
}

export interface CommandItem {
  type: Command;
  senderId?: string; // only optional while the old system remains
  createdAt?: number;
  acknowledgedAt?: number;
  updatedAt?: number;
  completedAt?: number;
  serverCreatedAt?: Timestamp | FieldValue;
  serverAcknowledgedAt?: Timestamp | FieldValue;
  serverUpdatedAt?: Timestamp | FieldValue;
  serverCompletedAt?: Timestamp | FieldValue;
  stage?: string;
  status?: string;
  eventHistory?: Array<CommandEventHistory> | FieldValue;
  state?: string;
  data?: {
    user?: string;
    state?: string;
    substate?: string;
  };
  userToKeepOnStage?: string;
  speakersArray?: unknown;
  parentCommand?: Command;
  docRef?: DocumentReference<CommandItem>;
}

export const ENABLED_COMMANDS_CLOSED_LOOP_INFRASTRUCTURE = [
  Command.DemoteSpeaker,
  Command.KickSpeaker,
  Command.MuteAudio,
  Command.MuteVideo,
  Command.PromoteSpeaker,
  Command.RefreshPage,
  Command.StartSystemCheck,
  Command.UnmuteAudio,
  Command.UnmuteVideo,
  Command.MoveAttendee,
];
