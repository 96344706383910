import { UserProfile } from '@venue/types/user';
import { SelectedContent, State } from '../types';

export interface ShoutOutState {
  readonly userId: string;
  readonly state: State;
  readonly delayedAuthor: UserProfile;
  readonly delayedContent: SelectedContent;
}

export enum ShoutOutActionTypes {
  SHOW_CONTENT = '[ShoutOut] Show Content',
  LOAD_CONTENT = '[ShoutOut] Load Content',
  ANIMATION_ENDED = '[ShoutOut] Animation Ended',
}

interface ShowContent {
  readonly type: typeof ShoutOutActionTypes.SHOW_CONTENT;
  readonly delayedContent: SelectedContent;
  readonly delayedAuthor: UserProfile;
}

interface LoadContent {
  readonly type: typeof ShoutOutActionTypes.LOAD_CONTENT;
  readonly content: SelectedContent;
}

interface AnimationEnded {
  readonly type: typeof ShoutOutActionTypes.ANIMATION_ENDED;
  readonly content: SelectedContent;
}

export enum Collection {
  Backstage = 'backstage_messages',
  Messages = 'messages',
  Questions = 'questions',
  Polls = 'polls',
}

export type ShoutOutActions = ShowContent | LoadContent | AnimationEnded;
