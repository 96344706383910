import { RoomType } from '@venue/services/rtc/types';
import {
  AgoraSdkError,
  AgoraStateError,
  LocalStreamState,
} from '@venue/types/agora';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from 'axios';
import memoize from 'micro-memoize';
import { API_URL } from './venue';

export enum LogLevel {
  DEBUG = 0,
  WARNING = 2,
}
AgoraRTC.setLogLevel(LogLevel.WARNING);

export const startDebugging = () => {
  AgoraRTC.setLogLevel(LogLevel.DEBUG);
};

export const stopDebugging = () => {
  AgoraRTC.setLogLevel(LogLevel.WARNING);
};

export interface TokenResponse {
  camera_token: string;
  screen_token: string;
  channel_name: string;
  stream_id: string;
}

export const getTokens = memoize(
  (
    eventId: string,
    roomId: string,
    roomType: RoomType
  ): Promise<TokenResponse> =>
    axios
      .get(
        API_URL +
          `/events/${eventId}/rooms/${roomId}/agora_token?room_type=${roomType}`
      )
      .then((response) => response.data)
);

export const getStageToken = (eventId: string, roomId: string) => {
  return getTokens(eventId, roomId, RoomType.Stage);
};

export interface FirewallTestTokensResponse {
  publisher_token: string;
  publisher_uid: string;
  subscriber_token: string;
  subscriber_uid: string;
  channel: string;
}

export const getFirewallTestTokens = (
  eventId: string
): Promise<FirewallTestTokensResponse> => {
  return axios
    .get(API_URL + `/events/${eventId}/firewall_test`)
    .then((response) => response.data);
};

export const makeAgoraSdkError = (code: string = null): AgoraSdkError => ({
  type: 'AgoraSdkError',
  code,
});

export const makeAgoraStateError = (
  state: LocalStreamState,
  action: string
): AgoraStateError => ({
  type: 'AgoraStateError',
  current_state: state,
  action: action,
});
