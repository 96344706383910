import { Activity } from '@venue/types/activity';

export interface ActivityState {
  readonly activities: ReadonlyArray<Activity>;
}

export enum ActivityActionTypes {
  ADD_ACTIVITIES = '[Activity] Add Activities',
}

export interface AddActivitiesAction {
  readonly type: typeof ActivityActionTypes.ADD_ACTIVITIES;
  readonly activities: ReadonlyArray<Activity>;
}

export type ActivityActions = AddActivitiesAction;
