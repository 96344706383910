import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { AuthActions, AuthActionTypes, AuthState } from './types';

// TODO: Simplify this model and update all references
const initialState: AuthState = {
  currentUser: {
    attributes: { id: null, email: null, profileImage: null },
  },
};

export const auth: Reducer<AuthState> = produce(
  (draft: Draft<AuthState>, action: AuthActions) => {
    switch (action.type) {
      case AuthActionTypes.SET_CURRENT_USER:
        draft.currentUser.attributes = {
          ...draft.currentUser.attributes,
          ...action.user,
        };
        break;
    }
  },
  initialState
);
