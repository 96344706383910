import add from 'date-fns/add';
import compareAsc from 'date-fns/compareAsc';
import isWithinInterval from 'date-fns/isWithinInterval';

export const isCurrentDateWithinDateRange = (
  startDate: Date,
  endDate?: Date,
  overrideParam?: {
    startDateDelayInMinutes?: number;
    endDateDelayInMinutes?: number;
  }
) => {
  const currentDate = new Date();
  const startDateWithDelay = add(startDate, {
    minutes: overrideParam?.startDateDelayInMinutes ?? 0,
  });

  if (!!endDate) {
    const endDateWithDelay = add(endDate, {
      minutes: overrideParam?.endDateDelayInMinutes ?? 0,
    });
    return isWithinInterval(currentDate, {
      start: startDateWithDelay,
      end: endDateWithDelay,
    });
  } else {
    return compareAsc(currentDate, startDateWithDelay) >= 0;
  }
};
