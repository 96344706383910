// We must guarantee functions from this file are not being used until firebase auth completes
import { createFirestoreListener } from '@venue/lib/statsMonitor/listenersMonitor';
import { firestoreApp } from '@venue/services/firebase.service';
import { formatFirestoreError } from '@venue/utils/firestoreError';
import {
  collection,
  deleteDoc,
  doc,
  QuerySnapshot,
  setDoc,
} from 'firebase/firestore';

const roomsCollection = collection(firestoreApp, 'rooms');

interface WatchStageArgs {
  roomId: string;
  onLeave: (streamId: string) => void;
}

// This is used to fire a callback when someone leaves the stage
export const watchStage = ({
  roomId,
  onLeave,
}: WatchStageArgs): (() => void) => {
  const stageStatusesCollection = collection(
    roomsCollection,
    roomId,
    'stage_statuses'
  );

  const handleSnapshot = (snapshot: QuerySnapshot) => {
    snapshot
      .docChanges()
      .filter((change) => change.type === 'removed')
      .forEach((change) => onLeave(change.doc.id));
  };

  return createFirestoreListener({
    file: 'stage.service',
    method: 'watchStage',
    ref: stageStatusesCollection,
    onComplete: handleSnapshot,
  });
};

interface JoinStageArgs {
  roomId: string;
  userId: string;
}

// This creates a document to signify that you are now on the stage
export const joinStage = async ({
  roomId,
  userId,
}: JoinStageArgs): Promise<void> => {
  const stageStatusDoc = doc(roomsCollection, roomId, 'stage_statuses', userId);

  await setDoc(stageStatusDoc, {}, { merge: true }).catch((err) => {
    const errorMessage = formatFirestoreError(
      'stage.service',
      'joinStage',
      err
    );
    console.log(errorMessage);
    throw Error(errorMessage);
  });
};

interface LeaveStageArgs {
  roomId: string;
  userId: string;
}

// This deletes your stage document to signal to others that you've left the stage
export const leaveStage = async ({
  roomId,
  userId,
}: LeaveStageArgs): Promise<void> => {
  const stageStatusDoc = doc(roomsCollection, roomId, 'stage_statuses', userId);
  try {
    await deleteDoc(stageStatusDoc);
  } catch (err: unknown) {
    const errorMessage = formatFirestoreError(
      'stage.service',
      'leaveStage',
      err
    );
    console.log(errorMessage);
    throw Error(errorMessage);
  }
};
