import { trackEvent } from '@common/services/posthog.service';
import { trackError } from '@common/services/rollbar.service';
import toast, { Toast } from 'react-hot-toast';
import { submitPresenterQuantitativeFeedback } from './api';
import { PresenterQuantitativeFeedbackForm } from './PresenterQuantitativeFeedbackForm';
import { StarRating } from './types';

export const PresenterFeedbackToast = (toastData: Toast) => {
  const toastId = toastData.id;

  return (
    <div
      className={`${
        toastData.visible ? 'tw-animate-enter' : 'tw-animate-leave'
      } tw-max-w-[30ch] tw-bg-white dark:tw-bg-neutral-800 tw-shadow-lg
          tw-rounded-lg tw-pointer-events-auto tw-flex
          tw-transition-all`}
    >
      <PresenterQuantitativeFeedbackForm
        onComplete={(ratings) => {
          trackEvent({
            name: 'Presenter Quantitative Feedback::Rate Audience Engagement',
            attributes: {
              venueExperienceRating: ratings.venueExperienceRating,
              audienceEngagementRating: ratings.audienceEngagementRating,
            },
          });
          onCompleteFeedbackForm({
            toastId,
            ...ratings,
            wasDismissed: false,
          });
        }}
        onDismiss={(ratings) => {
          trackEvent({
            name: 'Presenter Quantitative Feedback::Dismiss',
            attributes: {
              venueExperienceRating: ratings.venueExperienceRating,
            },
          });
          onCompleteFeedbackForm({
            toastId,
            ...ratings,
            wasDismissed: true,
          });
        }}
      />
    </div>
  );
};

const onCompleteFeedbackForm = async ({
  toastId,
  venueExperienceRating,
  audienceEngagementRating,
  wasDismissed,
}: {
  toastId: Toast['id'];
  venueExperienceRating: StarRating;
  audienceEngagementRating: StarRating;
  wasDismissed: boolean;
}) => {
  const isFeedbackFormSubmitted = await submitPresenterQuantitativeFeedback({
    venueExperienceRating,
    audienceEngagementRating,
    wasDismissed,
  });

  if (!isFeedbackFormSubmitted) {
    trackError(new Error('Unable to submit presenter feedback form'));
  }

  toast.dismiss(toastId);
};
