import { Event } from '@venue/types/event';
import { StageState } from '@venue/types/stage';

export interface EventPermissions {
  can_administrate: boolean;
  can_organize: boolean;
  can_speak: boolean;
  can_record: boolean;
  recording_space: string;
  event_id: string;
  rooms: Array<string>;
  search_index: string;
}

export interface EventState {
  readonly event: Event;
  readonly organizerEventState: StageState;
  readonly organizerRoomGroupId: string;
  readonly permissions: EventPermissions | null;
  readonly pageState: string | null;
  readonly isLoading: boolean;
  readonly error: boolean | unknown;
  readonly systemCheckPassed: boolean;
}

export enum EventActionTypes {
  LOAD_EVENT_STARTED = '[Event] Load Event Started',
  LOAD_EVENT_SUCCESS = '[Event] Load Event Success',
  LOAD_EVENT_ERROR = '[Event] Load Event Error',
  PAGE_STATE_CHANGE = '[Event] Page State Change',
  EVENT_STATE_CHANGE = '[Event] Event State Change',
  ORGANIZER_EVENT_STATE_CHANGE = '[Event] Organizer Event State Change',
  SYSTEM_CHECK_PASSED = '[Event] System Check Passed',
  GUEST_SYSTEM_CHECK_PASSED = '[Event] Guest System Check Passed',
  MOVE_ATTENDEE = '[Event] Move Attendee',
  MOVE_TO_LIVE_TRACK = '[Event] Move to Live Track',
}

export interface LoadEventAction {
  readonly type: typeof EventActionTypes.LOAD_EVENT_STARTED;
}

export interface LoadEventSuccessAction {
  readonly type: typeof EventActionTypes.LOAD_EVENT_SUCCESS;
  readonly event: Event;
  readonly permissions: EventPermissions;
  readonly breakoutRoomId: string;
}

export interface LoadEventErrorAction {
  readonly type: typeof EventActionTypes.LOAD_EVENT_ERROR;
  readonly error: unknown;
}

export interface MoveAttendeeAction {
  readonly type: typeof EventActionTypes.MOVE_ATTENDEE;
  readonly eventState: StageState;
  readonly pageState: string;
}
export interface MoveToLiveTrackAction {
  readonly type: typeof EventActionTypes.MOVE_TO_LIVE_TRACK;
}

export interface EventStateChangeAction {
  readonly type: typeof EventActionTypes.EVENT_STATE_CHANGE;
  readonly eventState: StageState;
  readonly pageState: string;
}

export interface PageStateChangeAction {
  readonly type: typeof EventActionTypes.PAGE_STATE_CHANGE;
  readonly pageState: string;
}

export interface OrganizerEventStateChangeAction {
  readonly type: typeof EventActionTypes.ORGANIZER_EVENT_STATE_CHANGE;
  readonly eventState: StageState;
  readonly roomGroupId?: string;
}

export interface SystemCheckPassedAction {
  readonly type: typeof EventActionTypes.SYSTEM_CHECK_PASSED;
}

export type EventActions =
  | LoadEventAction
  | LoadEventSuccessAction
  | LoadEventErrorAction
  | MoveAttendeeAction
  | MoveToLiveTrackAction
  | EventStateChangeAction
  | OrganizerEventStateChangeAction
  | PageStateChangeAction
  | SystemCheckPassedAction;
