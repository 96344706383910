import '@venue/services/firebase.service'; // Needed same place redux store is configured.
import configureStore, { createReduxHistory } from './configureStore';
import { DEVICE_PREFERENCES_KEY } from './device/contants';
import { initialState as initialDeviceState } from './device/reducer';
import { loadLocalStorage } from './localStorage';

const retrievedDeviceState = loadLocalStorage(DEVICE_PREFERENCES_KEY) || {};
const currentDevice = {
  ...initialDeviceState,
  ...retrievedDeviceState,
};

export const store = configureStore({ device: currentDevice });

export const history = createReduxHistory(store);
