import { EmbeddableKind } from '@common/types/embeddable';
import { AvailableFlag } from '@common/types/feature_flag';
import { SelectedContent } from '@venue/features/shoutOut/types';
import {
  DEFAULT_PLACEMENT,
  DEFAULT_SHAPE,
  DEFAULT_SIZE,
  VideoPlacement,
  VideoShape,
  VideoSize,
} from '@venue/store/stageLayout/types';
import { RootState } from '@venue/store/types';
import { ActionItem } from '@venue/types/action_items';
import {
  FirestoreActions,
  FirestoreAgenda,
  FirestoreBreakoutRoomCollection,
  FirestoreCustomBackground,
  FirestoreCustomMusic,
  FirestoreEvent,
  FirestorePollsCollection,
  FirestoreRoom,
  FirestoreSharedContent,
} from '@venue/types/firestore';
import { NormalizedObjects } from '@venue/types/redux';
import { SharedContentItem } from '@venue/types/shared_content';
import { StageState } from '@venue/types/stage';
import { createSelector } from 'reselect';
import { Celebration } from '../celebration/types';

export const PENDING_VALUE = '__PENDING_VALUE' as const;

const emptyActions: NormalizedObjects<ActionItem> = {
  byId: {},
  allIds: [],
};
export const actionItemsSelector = (
  state: RootState
): NormalizedObjects<ActionItem> =>
  (state.firestore.data.actions && state.firestore.data.actions.actionItems) ||
  emptyActions;

export const currentEventSelector = (state: RootState): FirestoreEvent =>
  state.firestore.data.currentEvent;

export const currentEventStateSelector = (state: RootState): StageState =>
  state.firestore.data.currentEvent?.state || StageState.PreEvent;

export const currentRoomSelector = (state: RootState): FirestoreRoom =>
  state.firestore.data.currentRoom;

export const mainStageRoomSelector = (state: RootState): FirestoreRoom =>
  state.firestore.data.mainStageRoom;

export const currentRoomCelebrationSelector = (state: RootState): Celebration =>
  (state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.celebration) ||
  null;

const emptyObject = {};
const _breakoutRoomsSelector = (
  state: RootState
): FirestoreBreakoutRoomCollection =>
  state.firestore.data.breakoutRooms || emptyObject;

export const breakoutRoomsSelector = createSelector(
  _breakoutRoomsSelector,
  (breakoutRoomsCollection) => {
    const filteredBreakoutRoomsCollection = Object.fromEntries(
      Object.entries(breakoutRoomsCollection).filter(([_, v]) => v)
    );

    return Object.entries(filteredBreakoutRoomsCollection)
      .map(([key, value]) => {
        return { id: key, ...value };
      })
      .sort((a, b) => a.updatedAt - b.updatedAt);
  }
);

export const breakoutRoomIdSelector = createSelector(
  [breakoutRoomsSelector, (_: unknown, chatRoomId: string) => chatRoomId],
  (breakoutRooms, chatRoomId) => {
    const breakoutRoom = breakoutRooms.find(
      (breakoutRoom) => breakoutRoom.chat_room_id === chatRoomId
    );
    return breakoutRoom?.id;
  }
);

interface FeatureFlagValue {
  value: unknown;
}

export const featureFlagsSelector = createSelector(
  (state: RootState) => state.firestore.data.featureFlags,
  (featureFlags) => {
    if (featureFlags === undefined) {
      return PENDING_VALUE;
    } else if (!featureFlags) {
      return {};
    }

    return Object.entries(featureFlags)
      .filter(([_key, value]: [string, FeatureFlagValue]) => value)
      .reduce((newFlags, [key, value]: [string, FeatureFlagValue]) => {
        newFlags[key] = value.value;
        return newFlags;
      }, {});
  }
);

export const featureFlagSelector = <ValueType>(
  flag: AvailableFlag,
  defaultValue: ValueType
) =>
  createSelector(featureFlagsSelector, (featureFlags) => {
    if (featureFlags === PENDING_VALUE) {
      return PENDING_VALUE;
    }

    return featureFlags[flag] ?? defaultValue;
  });

export const featureFlagSelectorBoolean = (flag: AvailableFlag) =>
  createSelector([featureFlagSelector(flag, false)], (flagValue) =>
    flagValue === PENDING_VALUE ? false : !!flagValue
  );

export const featureFlagSelectorInteger = (
  flag: AvailableFlag,
  defaultValue: number = 0
) =>
  createSelector(
    [featureFlagSelector(flag, defaultValue)],
    (maybeFlagValue) => {
      try {
        // the feature flags array did not yet load from Firestore
        if (maybeFlagValue === PENDING_VALUE) {
          return PENDING_VALUE;
        }

        return parseInt(maybeFlagValue.toString(), 10) || defaultValue;
      } catch {
        console.error('Unable to parse feature flag', { flag, maybeFlagValue });
        return defaultValue;
      }
    }
  );

export const featureFlagSelectorMinMax = (
  flag: AvailableFlag,
  defaultValue: number = 100,
  minValue: number = 0,
  maxValue: number = 100
) =>
  createSelector([featureFlagSelectorInteger(flag, defaultValue)], (value) =>
    value === PENDING_VALUE
      ? PENDING_VALUE
      : Math.min(Math.max(value, minValue), maxValue)
  );

export const isRtcEnabledSelector = (state: RootState): boolean =>
  !!state.firestore.data.currentEvent.enableRtc;

export const speakerGroupsSelector = (state: RootState) =>
  state.firestore.data.speakerGroups;

export const videoShapeSelector = (state: RootState): VideoShape =>
  (state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.videoShape) ||
  DEFAULT_SHAPE;

export const videoSizeSelector = (state: RootState): VideoSize =>
  (state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.videoSize) ||
  DEFAULT_SIZE;

export const videoPlacementSelector = (state: RootState): VideoPlacement =>
  (state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.videoPlacement) ||
  DEFAULT_PLACEMENT;

export const sharedContentSelector = (
  state: RootState
): FirestoreSharedContent => state.firestore.data.sharedContent;

export const currentAgendaSelector = (state: RootState): FirestoreAgenda =>
  state.firestore.data.currentAgenda;

export const currentActionsSelector = (state: RootState): FirestoreActions =>
  state.firestore.data.currentActions;

export const systemCheckSelector = (state: RootState) =>
  state.firestore.data.systemCheckResults;

export const customMusicSelector = (state: RootState): FirestoreCustomMusic =>
  state.firestore.data.customMusic;

export const pollsSelector = (state: RootState): FirestorePollsCollection =>
  state.firestore.data.polls;

export const customBackgroundSelector = (
  state: RootState
): FirestoreCustomBackground => state.firestore.data.customBackground;

export const systemChecksSelector = (state: RootState) =>
  state.firestore.data.systemChecks;

export const systemCheckResultsSelector = (state: RootState) =>
  state.firestore.data.systemCheckResults;

export type SystemCheckStatus = 'pending' | 'in-progress' | 'completed';

export const systemCheckStatusSelector = (
  state: RootState
): SystemCheckStatus => {
  const systemCheckResults = systemCheckResultsSelector(state);

  const userSystemCheckPassed =
    !!systemCheckResults && systemCheckResults.completedAt;

  const isUserSystemCheckInProgress =
    !!systemCheckResults &&
    systemCheckResults.requestedAt &&
    !systemCheckResults.rejectedAt &&
    !systemCheckResults.cancelledAt &&
    !systemCheckResults.completedAt;

  if (userSystemCheckPassed) {
    return 'completed';
  } else {
    if (isUserSystemCheckInProgress) {
      return 'in-progress';
    } else {
      return 'pending';
    }
  }
};

export const showAttendanceSelector = (state: RootState) =>
  !!(
    state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.showAttendance
  );

export const showAudienceGallerySelector = (state: RootState) =>
  !!(
    state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.showAudienceGallery
  );

export const showBackstageSubtabSelector = (state: RootState) =>
  !!(
    state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.showBackstage
  );

export const showChatSubtabSelector = (state: RootState) =>
  !!(
    state.firestore.data.currentRoom &&
    state.firestore.data.currentRoom.showMessages
  );

export const selectedContentSelector = (state: RootState): SelectedContent =>
  state.firestore.data.currentRoom &&
  state.firestore.data.currentRoom.selectedContent;

export const contentShareKindSelector = (state: RootState): EmbeddableKind =>
  state.firestore.data.currentRoom &&
  state.firestore.data.currentRoom.contentShareKind;

export const reduxFirestoreErrorsSelector = (
  state: RootState
): { allIds: Array<string>; byQuery: Array<unknown> } => state.firestore.errors;

const emptySharedContent: NormalizedObjects<SharedContentItem> = {
  byId: {},
  allIds: [],
};

export const sharedContentItemsSelector = (
  state: RootState
): NormalizedObjects<SharedContentItem> =>
  (state.firestore.data.sharedContent &&
    state.firestore.data.sharedContent.sharedContentItems) ||
  emptySharedContent;

export const useGifsSelector = (state: RootState) =>
  !!(
    state.firestore.data.currentRoom && state.firestore.data.currentRoom.useGifs
  );

const emptyShadowBannedUsers = [];

export const shadowBannedUsersSelector = createSelector(
  currentEventSelector,
  (event) => event.shadowBannedUsers || emptyShadowBannedUsers
);
