import { ENV_AGORA_APP_ID } from '@common/env';
import { getErrorCode, toApplicationError } from '@common/errorTypePredicates';
import { trackEvent } from '@common/services/posthog.service';
import { translateDeviceLabelToId } from '@venue/features/audioVideoDevices/audioVideoDevices';
import {
  onReconnected,
  queueAction,
} from '@venue/services/disconnection.service';
import { logWarning } from '@venue/services/log.service';
import {
  NetworkQualityLevel,
  RoomType,
  StreamsNetworkQuality,
} from '@venue/services/rtc/types';
import {
  AgoraTokensResponse,
  getAgoraTokens,
} from '@venue/services/rtcTokens.service';
import {
  joinStage,
  leaveStage,
  watchStage,
} from '@venue/services/stage.service';
import { SCREEN_UID } from '@venue/types/agora';
import { DeviceTypes } from '@venue/types/device';
import { RtcStats } from '@venue/types/rtc_stats';
import { MediaType } from '@venue/types/stream';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  VideoEncoderConfiguration,
  VideoEncoderConfigurationPreset,
} from 'agora-rtc-sdk-ng';
import {
  CreateRtcAdapterArgs,
  CreateStreamArgs,
  RoomConfig,
  RtcService,
} from './rtc.service';
import { StreamNetworkQuality, StreamType, VideoQuality } from './types';

AgoraRTC.setLogLevel(2); // WARNING

export const AGORA_APP_ID = ENV_AGORA_APP_ID;

const VideoProfile: Record<
  string,
  VideoEncoderConfiguration | VideoEncoderConfigurationPreset
> = {
  Large: '720p_6', // 960×720, 1.33 aspect ratio, 30 fps
  Medium: '480p_4', // 640×480, 1.33 aspect ratio, 30 fps
  Small: '360p_8', // 480×360, 1.33 aspect ratio, 30 fps
  Tiny: '240p_1', // 320×240, 1.33 aspect ratio, 15 fps
  Nano: '180p_4', // 240×180, 1.33 aspect ratio, 15 fps
};

interface LocalCameraUser {
  uid: string | number;
  videoTrack: ILocalVideoTrack;
  audioTrack: ILocalAudioTrack;
}

interface LocalScreenUser {
  uid: string | number;
  videoTrack: ILocalVideoTrack;
  audioTrack: ILocalAudioTrack;
}

type AnyAgoraUser = LocalCameraUser | LocalScreenUser | IAgoraRTCRemoteUser;

export const VideoQualityMap: Record<
  VideoQuality,
  typeof VideoProfile[keyof typeof VideoProfile]
> = {
  [VideoQuality.Potato]: VideoProfile['Nano'],
  [VideoQuality.Low]: VideoProfile['Tiny'],
  [VideoQuality.Medium]: VideoProfile['Small'],
  [VideoQuality.High]: VideoProfile['Medium'],
  [VideoQuality.Ludicrous]: VideoProfile['Large'],
};

export const NetworkQualityMap: Record<number, NetworkQualityLevel> = {
  0: NetworkQualityLevel.Ludicrous,
  1: NetworkQualityLevel.Ludicrous,
  2: NetworkQualityLevel.High,
  3: NetworkQualityLevel.Medium,
  4: NetworkQualityLevel.Low,
  5: NetworkQualityLevel.Potato,
  6: NetworkQualityLevel.Disconnected,
};

export const createAgoraNgAdapter = async ({
  eventId, // TODO: get rid of this and have rooms not be nested under events
  options,
  callbacks,
}: CreateRtcAdapterArgs): Promise<RtcService> => {
  const store = createAgoraStore();

  const createClient = () => {
    return AgoraRTC.createClient({
      role: 'audience',
      mode: 'live',
      codec: 'vp8', // No support for VP9 in Agora V4
    });
  };

  const getAgoraUser = (
    streamId: string
  ): {
    user: LocalCameraUser | LocalScreenUser | IAgoraRTCRemoteUser;
    type: StreamType;
  } => {
    const users = (store.remoteCameraUsers as Array<AnyAgoraUser>).concat([
      store.localCameraUser as AnyAgoraUser,
    ]);
    const user = users.find((user) => user?.uid.toString() === streamId);
    if (user) {
      return { user, type: StreamType.Camera };
    }

    const screen = [store.localScreenUser, store.remoteScreenUser].find(
      (user) => user?.uid.toString() === streamId
    );
    if (screen) {
      return { user: screen, type: StreamType.Screen };
    }

    return { user: null, type: null };
  };

  const createAndStoreStream = async (
    streamId: string,
    microphoneLabel: string = null,
    cameraLabel: string = null
  ) => {
    console.log({ microphoneLabel, cameraLabel });
    // initiates stream with passed device ids (mic/cam) if present, or Agora API will try with any available devices if either are null
    const currentCameraId = await translateDeviceLabelToId(
      cameraLabel,
      DeviceTypes.VideoInput
    );
    const currentMicrophoneId = await translateDeviceLabelToId(
      microphoneLabel,
      DeviceTypes.AudioInput
    );

    const [audioTrack, videoTrack] =
      await createMicrophoneAndCameraTracksHelper(
        currentMicrophoneId,
        currentCameraId
      );
    store.localCameraUser = { uid: streamId, audioTrack, videoTrack };
  };

  const createStream = async (args?: CreateStreamArgs) => {
    const {
      streamId = store.tokens.stream_id,
      microphoneLabel = null,
      cameraLabel = null,
    } = args || {};

    try {
      // double try statement allows Agora to try to connect to any available mic and/or camera if the configured ones are not available at stream initiation
      // Chrome will do this automatically (at the date/time of this commit), but Safari will not
      try {
        await createAndStoreStream(streamId, microphoneLabel, cameraLabel);
      } catch (e: unknown) {
        console.log({
          message:
            'Error when creating stream attempting any available device.',
          e,
        });
        if (e instanceof Error) {
          // Only catch the recoverable case, re-throw otherwise
          // e.message === 'DEVICE_NOT_FOUND' is an error generated by us because Agora throws an unhelpful generic error code when the configured camera and/or mic is not available
          if (e.message === 'DEVICE_NOT_FOUND') {
            await createAndStoreStream(streamId);
          }
        } else {
          throw e;
        }
      }
    } catch (e: unknown) {
      console.log({
        message: 'Error when creating stream.',
        e,
      });

      // Catch the remaining hard-fail cases
      if (getErrorCode(e) === 'UNEXPECTED_ERROR') {
        throw new Error('DEVICE_NOT_FOUND');
      }

      throw e;
    }
  };

  const destroyStream = async () => {
    if (store.localCameraUser && store.client && store.roomId) {
      await unpublishStream();
    } else {
      logWarning('Destroying stream, but unable to unpublish: ', {
        localCameraUser: store.localCameraUser,
        client: store.client,
        roomId: store.roomId,
      });
    }

    // Turns off camera led after stop using it, otherwise light remains on.
    if (store.localCameraUser) {
      store.localCameraUser.videoTrack?.stop();
      store.localCameraUser.videoTrack?.close();
      store.localCameraUser.audioTrack?.stop();
      store.localCameraUser.audioTrack?.close();
    }

    store.localCameraUser = null;
  };

  const publishStream = async () => {
    if (!store.client || !store.localCameraUser) {
      throw new Error('no client or camera while attempting to publish');
    }

    const audioTrack = store.localCameraUser.audioTrack;
    const videoTrack = store.localCameraUser.videoTrack;
    const audioTrackId = audioTrack?.getTrackId();
    const videoTrackId = videoTrack?.getTrackId();

    if (!!audioTrack && !audioTrackId) {
      throw new Error('Empty audio track detected, cancelling publish');
    }

    if (!!videoTrack && !videoTrackId) {
      throw new Error('Empty video track detected, cancelling publish');
    }

    await store.client.setClientRole('host');

    if (audioTrack && videoTrack) {
      console.log('Publishing audio and video tracks', {
        audioTrack: !!audioTrack,
        videoTrack: !!videoTrack,
      });
      await store.client.publish([audioTrack, videoTrack]);
      await joinStage({
        roomId: store.roomId,
        userId: store.localCameraUser.uid.toString(),
      });
    } else if (videoTrack) {
      console.log('Publishing video only track', { videoTrack: !!videoTrack });
      await store.client.publish(videoTrack);
    } else if (audioTrack) {
      console.log('Publishing audio only track', { audioTrack: !!audioTrack });
      await store.client.publish(audioTrack);
    } else {
      throw new Error('no tracks to publish');
    }
  };

  const unpublishStream = async () => {
    const NOT_PUBLISHED = 'STREAM_NOT_YET_PUBLISHED';

    if (!store.client) {
      throw new Error('no client while attempting to unpublish');
    }

    if (!store.localCameraUser) {
      throw new Error('no camera while attempting to unpublish');
    }

    if (!store.roomId) {
      throw new Error('did not join room while attempting to unpublish');
    }

    if (store.roomId) {
      await leaveStage({
        roomId: store.roomId,
        userId: store.localCameraUser.uid.toString(),
      });
    }

    try {
      await store.client.unpublish();
    } catch (reason) {
      if (reason !== NOT_PUBLISHED) {
        throw reason;
      } else {
        console.log('Unable to unpublish stream: ', reason);
      }
    }

    await store.client.setClientRole('audience');
  };

  const safePlayAudio = (
    audioTrack: ILocalAudioTrack | IRemoteAudioTrack,
    playbackDeviceId?: string
  ) => {
    if (audioTrack) {
      audioTrack.play();

      if (playbackDeviceId) {
        audioTrack.setPlaybackDevice(playbackDeviceId);
      }
    } else {
      console.log('[audio] No audio track to play.');
    }
  };

  const safePlayVideo = (
    videoTrack: ILocalVideoTrack | IRemoteVideoTrack,
    domId: string,
    streamType: StreamType
  ) => {
    const videoElement = document.getElementById(domId);
    if (videoTrack && videoElement) {
      videoTrack.play(domId, {
        fit: streamType === StreamType.Screen ? 'contain' : 'cover',
      });
    } else {
      console.log('[video] Unable to play video track.', {
        videoTrack,
        domId,
        streamType,
        videoElement,
      });
      throw new Error('[video] Unable to play video track.');
    }
  };

  const playStream = async ({
    streamId,
    domId,
    mediaType,
    playbackDeviceId,
  }: {
    streamId: string;
    domId: string;
    mediaType?: MediaType;
    playbackDeviceId?: string;
  }): Promise<void> => {
    const { user, type } = getAgoraUser(streamId);

    if (user) {
      const isLocalCamera =
        user.uid.toString() === store.localCameraUser?.uid?.toString();
      const isLocalScreen =
        user.uid.toString() === SCREEN_UID && store.localScreenUser;

      if (user.videoTrack) {
        safePlayVideo(user.videoTrack, domId, type);
      } else if (mediaType === 'video') {
        // Last resort hack, if for some reason the audio/video track is not available, try it later.
        console.log(
          '[video] Attempted to play video but track was not available, schedule retry.'
        );
        setTimeout(() => {
          console.log('[video] Playing delayed video track.');
          safePlayVideo(user.videoTrack, domId, type);
          console.log('[video] Playing delayed audio track.');
          safePlayAudio(user.audioTrack, playbackDeviceId);
        }, 1000);
      }

      if (!isLocalCamera && !isLocalScreen) {
        if (user.audioTrack) {
          safePlayAudio(user.audioTrack, playbackDeviceId);
        } else if (mediaType === 'audio') {
          // Last resort hack, if for some reason the audio/video track is not available, try it later.
          console.log(
            '[audio] Attempted to play audio but track was not available, schedule retry.'
          );
          setTimeout(() => {
            console.log('[audio] Playing delayed video track.');
            safePlayVideo(user.videoTrack, domId, type);
            console.log('[audio] Playing delayed audio track.');
            safePlayAudio(user.audioTrack, playbackDeviceId);
          }, 1000);
        }
      }
    }
  };

  const destroy = async () => {
    await Promise.all([destroyStream(), destroyScreen()]);
    await leaveRoom();
    store.client = null;
  };

  const switchCamera = async (deviceLabel: string) => {
    if (store.localCameraUser?.videoTrack) {
      await (store.localCameraUser.videoTrack as ICameraVideoTrack).setDevice(
        await translateDeviceLabelToId(deviceLabel, DeviceTypes.VideoInput)
      );
    }
  };

  const switchMicrophone = async (deviceLabel: string) => {
    if (store.localCameraUser?.audioTrack) {
      await (
        store.localCameraUser.audioTrack as IMicrophoneAudioTrack
      ).setDevice(
        await translateDeviceLabelToId(deviceLabel, DeviceTypes.AudioInput)
      );
    }
  };

  const switchSpeakers = async (deviceLabel: string) => {
    if (store.remoteCameraUsers.length > 0) {
      return Promise.all(
        store.remoteCameraUsers.map(async (remoteCameraUser) =>
          remoteCameraUser.audioTrack?.setPlaybackDevice(
            await translateDeviceLabelToId(deviceLabel, DeviceTypes.AudioOutput)
          )
        )
      )
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
    }
  };

  const removeScreenTracks = async (mediaType?: MediaType) => {
    if (store.remoteScreenUser) {
      if (mediaType === 'audio') {
        callbacks.audioMutedUpdated(SCREEN_UID, true);
      } else if (mediaType === 'video') {
        callbacks.videoMutedUpdated(SCREEN_UID, true);
      }

      if (
        !store.remoteScreenUser?.hasAudio &&
        !store.remoteScreenUser?.hasVideo
      ) {
        store.remoteScreenUser = null;
        callbacks.streamRemoved(SCREEN_UID);
      }
    }
  };

  const removeCameraTracks = async (
    streamId: string,
    mediaType?: MediaType
  ) => {
    const existingRemoteUser = store.remoteCameraUsers.find((user) => {
      return user.uid.toString() === streamId;
    });

    if (existingRemoteUser) {
      if (mediaType === 'audio') {
        callbacks.audioMutedUpdated(streamId, true);
      } else if (mediaType === 'video') {
        callbacks.videoMutedUpdated(streamId, true);
      } else {
        store.disconnectionMap[streamId] = null;
        store.remoteCameraUsers = store.remoteCameraUsers.filter(
          (user) => user.uid.toString() !== streamId
        );
      }
    }
  };

  const removeStream = async (streamId: string, mediaType?: MediaType) => {
    if (streamId === SCREEN_UID) {
      await removeScreenTracks(mediaType);
    } else {
      await removeCameraTracks(streamId, mediaType);
    }
  };

  const setAudioMuted = (muted: boolean) => {
    if (store.localCameraUser?.audioTrack) {
      console.log(`setting local audio mute status to '${muted}'`);
      store.localCameraUser.audioTrack.setMuted(muted);
      return true;
    } else {
      return false;
    }
  };

  const setVideoMuted = (muted: boolean) => {
    if (store.localCameraUser?.videoTrack) {
      console.log(`setting local video mute status to '${muted}'`);
      store.localCameraUser.videoTrack.setMuted(muted);
      return true;
    } else {
      return false;
    }
  };

  const getChannelName = () => {
    return store.client?.channelName;
  };

  const getLocalStreamId = () => {
    return store.localCameraUser?.uid?.toString();
  };

  const getRemoteStreamIds = () => {
    return store.remoteCameraUsers.map((user) => user.uid?.toString());
  };

  const getLocalScreenId = () => {
    return store.localScreenUser?.uid?.toString();
  };

  const getRemoteScreenId = () => {
    return store.remoteScreenUser?.uid?.toString();
  };

  const joinRoom = async (config: RoomConfig): Promise<void> => {
    const tokens = await getAgoraTokens({
      eventId,
      roomId: config.roomId,
      roomType: config.roomType,
    });
    const intStreamId = parseInt(tokens.stream_id, 10) || tokens.stream_id;

    store.tokens = tokens;
    store.roomId = config.roomId;
    store.roomType = config.roomType;
    store.watchStageCleanup = watchStage({
      roomId: config.roomId,
      onLeave: async (streamId) => {
        if (!store.disconnectionMap[streamId]) {
          await removeStream(streamId);
          callbacks.streamRemoved(streamId);
        }
      },
    });

    await store.client.join(
      AGORA_APP_ID,
      tokens.channel_name,
      tokens.camera_token,
      intStreamId
    );

    if (
      [RoomType.PublishTest, RoomType.BackgroundPublishTest].includes(
        config.roomType
      )
    ) {
      await store.client.setClientRole('host');
    }
  };

  const leaveRoom = async (): Promise<void> => {
    store.watchStageCleanup();
    store.watchStageCleanup = () => {};

    await store.client?.leave();
    store.remoteCameraUsers = [];
    store.remoteScreenUser = null;

    store.roomId = null;
    store.roomType = null;
  };

  const getAudioLevel = (streamId: string) => {
    const { user } = getAgoraUser(streamId);
    return user?.audioTrack?.getVolumeLevel();
  };

  const getLocalNetworkQuality = () => {
    return store.localNetworkQuality;
  };

  const getRemoteNetworkQuality = () => {
    let networkQualityPerUser = store.client.getRemoteNetworkQuality();
    const remoteUsersNetworkQuality = Object.keys(networkQualityPerUser).reduce(
      (acc, cur) => {
        const { uplinkNetworkQuality, downlinkNetworkQuality } =
          networkQualityPerUser[cur];
        acc[cur] = {
          downlinkNetworkQuality: NetworkQualityMap[downlinkNetworkQuality],
          uplinkNetworkQuality: NetworkQualityMap[uplinkNetworkQuality],
        };
        return acc;
      },
      {} as StreamsNetworkQuality
    );
    return remoteUsersNetworkQuality;
  };

  const setVideoQuality = (quality: VideoQuality) => {
    if (quality === store.currentVideoQuality) {
      return;
    }

    // Log that shows what would be the next video resolution change if we
    // weren't making it fixed for debugging purposes.
    console.log({
      message: 'Would change video quality to:',
      quality,
    });

    store.currentVideoQuality = quality;
  };

  // To reduce complexity, screen share cannot be created and published separately,
  // And must be created after joining a room
  const createScreen = async () => {
    // This call needs to happen as early as possible in response to a user gesture.
    // Otherwise Safari security mechanism will block it.
    const screenTrack = await AgoraRTC.createScreenVideoTrack(
      {
        // These are the recommended settings from Agora for a screen share
        // https://docs.agora.io/en/video/screensharing_web_ng?platform=Web
        encoderConfig: '1080p_1',
        optimizationMode: 'detail',
      },
      'auto'
    );

    let videoTrack = null;
    let audioTrack = null;

    if (screenTrack instanceof Array) {
      videoTrack = screenTrack[0];
      audioTrack = screenTrack[1];
    } else {
      videoTrack = screenTrack;
    }

    try {
      const client = createClient();
      store.screenClient = client;
      await client.join(
        AGORA_APP_ID,
        store.tokens.channel_name,
        store.tokens.screen_token,
        parseInt(SCREEN_UID, 10)
      );

      store.localScreenUser = {
        uid: SCREEN_UID,
        videoTrack,
        audioTrack,
      };

      videoTrack.on('track-ended', () => {
        trackEvent({
          name: 'SharedContent::Stop',
          attributes: { source: 'browser' },
        });
        destroyScreen();
        callbacks.screenDestroyed();
      });

      if (store.remoteScreenUser) {
        // Another screenshare was published first
        await destroyScreen();
        throw new Error('RemoteScreenActive');
      }

      await publishScreen();
    } catch {
      await destroyScreen();
    }
  };

  const publishScreen = async () => {
    await store.screenClient.setClientRole('host');
    try {
      let tracks: Array<ILocalTrack> = [store.localScreenUser.videoTrack];
      if (store.localScreenUser.audioTrack) {
        tracks.push(store.localScreenUser.audioTrack);
      }
      await store.screenClient.publish(tracks);
    } catch (e: unknown) {
      await store.screenClient.setClientRole('audience');
      throw e;
    }
  };

  const unpublishScreen = async () => {
    const NOT_PUBLISHED = 'STREAM_NOT_YET_PUBLISHED';
    try {
      await store.screenClient.unpublish();
    } catch (reason) {
      if (reason !== NOT_PUBLISHED) {
        throw reason;
      }
    }

    await store.screenClient.setClientRole('audience');
  };

  const destroyScreen = async () => {
    if (store.screenClient && store.localScreenUser) {
      await unpublishScreen();
    }

    if (store.screenClient) {
      await store.screenClient.leave();
    }

    if (store.localScreenUser) {
      store.localScreenUser.videoTrack?.stop();
      store.localScreenUser.videoTrack?.close();
      store.localScreenUser.audioTrack?.stop();
      store.localScreenUser.audioTrack?.close();
    }

    store.localScreenUser = null;
    store.screenClient = null;
  };

  const unblockAudio = () => {
    store.remoteCameraUsers.forEach((user) => {
      user.audioTrack?.play();
    });
  };

  const publishTestStream = async () => {
    await store.client.setClientRole('host');
    const video = document.getElementById(
      'firewall-test-video'
    ) as HTMLVideoElement;

    if (!video) {
      trackEvent({
        name: 'System Check Background Error',
        attributes: {
          error: 'test video not found',
        },
      });
      throw new Error(
        'Cannot publish test stream without test video element present in the DOM'
      );
    }

    const stream = video.captureStream() as MediaStream;
    const mediaStreamTrack = stream.getVideoTracks()[0];
    if (!mediaStreamTrack) {
      trackEvent({
        name: 'System Check Background Error',
        attributes: {
          error: 'mediaStreamTrack not found',
        },
      });
    }
    const videoTrack = AgoraRTC.createCustomVideoTrack({ mediaStreamTrack });
    store.localCameraUser = {
      uid: store.tokens.stream_id,
      audioTrack: null,
      videoTrack,
    };
    video.muted = true;
    video.play();
    await publishStream();
  };

  // Initialize client
  const client = createClient();

  client.on('token-privilege-will-expire', async () => {
    const tokens = await getAgoraTokens({
      eventId,
      roomId: store.roomId,
      roomType: store.roomType,
    });

    await client.renewToken(tokens.camera_token);

    if (store.screenClient) {
      await store.screenClient.renewToken(tokens.screen_token);
    }

    store.tokens = tokens;
  });

  client.on('user-published', async (remoteUser, mediaType) => {
    if (!options.subscribeRemoteStreams) {
      return;
    }

    const streamId = remoteUser.uid.toString();
    if (streamId === SCREEN_UID && store.localScreenUser) {
      return; // Do not subscribe to own screenshare
    }

    try {
      await client.subscribe(remoteUser, mediaType);
    } catch (unknownErr: unknown) {
      const err = toApplicationError(unknownErr);

      const loggingData = {
        userId: streamId,
        name: err.name,
        code: err.code,
        mediaType,
      };
      if (err.name === 'AgoraRTCException') {
        switch (err.code) {
          case 'UNEXPECTED_RESPONSE':
          case 'INVALID_OPERATION':
            // https://rollbar.com/app167139609-heroku.com/venue-react/items/5810/
            // https://rollbar.com/app167139609-heroku.com/venue-react/items/5805/
            // https://rollbar.com/app167139609-heroku.com/venue-react/items/5806/
            // https://rollbar.com/app167139609-heroku.com/venue-react/items/5807/
            console.log(
              'Subscribe error due to disconnection, attempting to recover after reconnection',
              err
            );
            trackEvent({
              name: 'Subscribe Failure Recovery Attempted',
              attributes: { loggingData },
            });
            queueAction({
              action: () => client.subscribe(remoteUser, mediaType),
              onFailure: () => {
                console.warn(
                  'Could not recover stream subscription after reconnection'
                );
                trackEvent({
                  name: 'Subscribe Failure Recovery Failed',
                  attributes: { loggingData },
                });
                throw err;
              },
            });
            break;
          default:
            trackEvent({
              name: 'Unrecoverable Subscribe Failure',
              attributes: { loggingData },
            });
            throw err;
        }
      }
    }

    const existingRemoteUserIndex = store.remoteCameraUsers.findIndex(
      (user) => user.uid.toString() === streamId
    );
    const existingRemoteUser = store.remoteCameraUsers[existingRemoteUserIndex];
    const newRemoteUser = remoteUser || existingRemoteUser;

    if (streamId === SCREEN_UID) {
      store.remoteScreenUser = newRemoteUser;
      store.disconnectionMap[streamId] = false;
      callbacks.streamAdded(streamId);
    } else if (existingRemoteUser) {
      store.disconnectionMap[streamId] = false;
      store.remoteCameraUsers[existingRemoteUserIndex] = remoteUser;
      callbacks.streamReconnected(streamId);
    } else {
      store.remoteCameraUsers = store.remoteCameraUsers.concat([newRemoteUser]);
      store.disconnectionMap[streamId] = false;
      callbacks.streamAdded(streamId);
    }

    callbacks.videoMutedUpdated(streamId, !newRemoteUser.videoTrack);
    callbacks.audioMutedUpdated(streamId, !newRemoteUser.audioTrack);
  });

  client.on('user-unpublished', (remoteUser, mediaType) => {
    const streamId = remoteUser.uid.toString();
    removeStream(streamId, mediaType);
  });

  client.on('user-joined', (user) => {
    const streamId = user.uid.toString();
    callbacks.userJoined(streamId);
  });

  client.on('user-left', async (user, reason) => {
    if (reason === 'BecomeAudience') {
      return;
    }

    const DISCONNECT_TIMEOUT = 3000;
    const streamId = user.uid.toString();

    if (reason === 'Quit' && streamId !== SCREEN_UID) {
      setTimeout(async () => {
        if (store.disconnectionMap[streamId]) {
          await removeStream(streamId);
          callbacks.streamRemoved(streamId);
        }
      }, DISCONNECT_TIMEOUT);

      store.disconnectionMap[streamId] = true;
      callbacks.streamDisconnected(streamId);
    } else {
      await removeStream(streamId);
      callbacks.streamRemoved(streamId);
    }
  });

  client.on('network-quality', (stats) => {
    store.localNetworkQuality = {
      downlinkNetworkQuality: NetworkQualityMap[stats.downlinkNetworkQuality],
      uplinkNetworkQuality: NetworkQualityMap[stats.uplinkNetworkQuality],
    };
  });

  client.on('connection-state-change', (curState, prevState) => {
    const RECONNECTING = 'RECONNECTING';
    const CONNECTED = 'CONNECTED';

    if (curState === RECONNECTING) {
      console.log('Disconnected from Agora');
      callbacks.disconnected();
    } else if (prevState === RECONNECTING && curState === CONNECTED) {
      console.log('Reconnected to Agora');
      onReconnected();
      callbacks.reconnected();
    }
  });

  const getRtcStats: () => RtcStats = () => {
    return {
      localAudio: client.getLocalAudioStats(),
      localVideo: client.getLocalVideoStats(),
      remoteAudio: client.getRemoteAudioStats(),
      remoteVideo: client.getRemoteVideoStats(),
    };
  };

  store.client = client;

  return {
    joinRoom,
    leaveRoom,

    createStream,
    publishStream,
    unpublishStream,
    destroyStream,
    setVideoQuality,
    setAudioMuted,
    setVideoMuted,
    switchCamera,
    switchMicrophone,
    switchSpeakers,

    createScreen,
    destroyScreen,

    playStream,
    destroy,

    getChannelName,

    getLocalStreamId,
    getRemoteStreamIds,

    getLocalScreenId,
    getRemoteScreenId,

    getAudioLevel,

    getLocalNetworkQuality,
    getRemoteNetworkQuality,

    unblockAudio,
    publishTestStream,

    getRtcStats,
  };
};

export interface AgoraStore {
  tokens: AgoraTokensResponse;

  roomId: string;
  roomType: RoomType;
  watchStageCleanup: () => void;
  localNetworkQuality: StreamNetworkQuality;
  disconnectionMap: Record<string, boolean>;
  currentVideoQuality: VideoQuality;

  client: IAgoraRTCClient;
  screenClient: IAgoraRTCClient;
  remoteCameraUsers: Array<IAgoraRTCRemoteUser>;
  remoteScreenUser: IAgoraRTCRemoteUser;
  localCameraUser: LocalCameraUser;
  localScreenUser: LocalScreenUser;
}

const createAgoraStore = (): AgoraStore => {
  return {
    tokens: null,

    roomId: null,
    roomType: null,
    watchStageCleanup: () => {},
    localNetworkQuality: {
      downlinkNetworkQuality: NetworkQualityLevel.Ludicrous,
      uplinkNetworkQuality: NetworkQualityLevel.Ludicrous,
    },
    disconnectionMap: {},
    currentVideoQuality: null,
    client: null,
    screenClient: null,
    remoteCameraUsers: [],
    remoteScreenUser: null,
    localCameraUser: null,
    localScreenUser: null,
  };
};

export const createMicrophoneAndCameraTracksHelper = async (
  microphoneId: string = null,
  cameraId: string = null
) => {
  try {
    return await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        microphoneId,
        // https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/microphoneaudiotrackinitconfig.html
        AEC: true, // acoustic echo cancellation
        AGC: false, // audio gain control
        ANS: true, // automatic noise suppression

        // https://docs.agora.io/en/Voice/API%20Reference/web_ng/globals.html#audioencoderconfigurationpreset
        encoderConfig: 'standard_stereo',
      },
      { cameraId, encoderConfig: VideoProfile['Medium'] }
    );
  } catch (unknownError: unknown) {
    let error = toApplicationError(unknownError);

    if (error.code === 'UNEXPECTED_ERROR' && (microphoneId || cameraId)) {
      throw new Error('DEVICE_NOT_FOUND');
    } else if (error.code) {
      throw new Error(error.code);
    }
  }
};
