import { Tab, WorkspaceSettingsTab } from './types/tab';

/*
 When adding new routes to this file consider:
   * using an absolute path starting with `/`.
   * prefix it with `getRoute`
   * add the Entity/Domain next (Auth, Organization, Event)
   * add CRUD actions next:
      - index (list entities)
      - show (show single entity)
      - new (shows create entity form)
      - edit (shows update entity form)
      - delete
   * some of the routes are not CRUD actions and it's fine to deviate from the
     pattern sometimes
*/

export const getRouteDashboardIndex = () => '/dashboard';

export const getRouteAuthLogin = (includeRedirectPath: boolean = false) => {
  const path = '/dashboard/login';
  return includeRedirectPath
    ? path + `?redirectTo=${window.location.pathname}`
    : path;
};

export const getRouteAuthSignUp = () => '/dashboard/sign_up';

export const getRouteAuthSignUpConfirmation = () =>
  '/dashboard/sign_up/confirmation';

export const getRouteAuthSignUpPassword = () => '/dashboard/sign_up/password';

export const getRouteAuthForgotPassword = () => '/dashboard/forgot_password';

export const getRouteAuthInvalidInvite = () => '/dashboard/invalid_invite';

export const getRouteAccountSetup = () =>
  '/dashboard/setup/contact-information';

export const getRouteSubscriptionSelect = () =>
  '/dashboard/setup/select-subscription-plan';

export const getRouteSubscriptionPayment = () => '/dashboard/setup/payment';

export const getRouteSubscriptionThankYou = () => '/dashboard/setup/thank-you';

export const getRouteOrganizationNew = () => '/dashboard/organizations/new';

export const getRouteOrganizationIndex = () => '/dashboard/organizations';

export const getRouteWorkspaceSettings = (
  organizationSlug: string,
  tab: WorkspaceSettingsTab
) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}/manage/${
    Tab.WorkspaceSettings
  }/${WorkspaceSettingsTab[tab]}`;

export const getRouteOrganizationManage = (organizationSlug: string) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}`;

export const getRouteEventIndex = (organizationSlug: string) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}/manage/events`;

export const getRouteEventShow = (organizationSlug: string, eventId: string) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}/manage/events/${eventId}`;

export const getRouteEventNew = (
  organizationSlug: string,
  type: 'single' | 'recurring'
) =>
  `${_getRouteOrganizationPrefix(
    organizationSlug
  )}/builder/new/details?type=${type}`;

export const getRouteEventEdit = (organizationSlug: string, eventId: string) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}/builder/${eventId}/details`;

export const getRouteEmojiIndex = (organizationSlug: string) =>
  `${_getRouteOrganizationPrefix(organizationSlug)}/manage/emojis`;

const _getRouteOrganizationPrefix = (organizationSlug: string) =>
  `/dashboard/organizations/${organizationSlug}`;
