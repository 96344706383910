import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { VodActions, VodActionTypes, VodState } from './types';

const initialState: VodState = {
  vodOrganizationId: null,
  channels: [],
};

export const vod: Reducer<VodState> = produce(
  (draft: Draft<VodState>, action: VodActions) => {
    switch (action.type) {
      case VodActionTypes.SET_VOD_ORGANIZATION_ID:
        draft.vodOrganizationId = action.vodOrganizationId;
        break;
      case VodActionTypes.SET_CHANNELS:
        draft.channels = action.channels;
        break;
    }
  },
  initialState
);
