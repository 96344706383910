import { ReactNode } from 'react';

export interface CardProps {
  headerImage?: string;
  icon?: JSX.Element;
  title?: string;
  subtitle?: string;
  className?: string;
  children?: ReactNode;
}

export const Card = ({
  headerImage,
  icon,
  title,
  subtitle,
  children,
  className,
}: CardProps) => {
  const hasText: boolean = !!(title || subtitle);

  return (
    <div
      className={`tw-flex tw-h-auto tw-w-full tw-flex-col tw-items-center tw-justify-center tw-p-0 md:tw-min-h-full md:tw-p-8 ${
        className ? className : ''
      }`}
    >
      <div className='tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-rounded-none tw-bg-white dark:tw-bg-neutral-800 tw-drop-shadow-sm md:tw-rounded-xl'>
        {headerImage && (
          <img
            alt='event header'
            className={`tw-block tw-aspect-video tw-max-h-[30rem] tw-w-full tw-rounded-none tw-object-cover md:tw-rounded-t-xl ${
              hasText ? '' : 'md:tw-rounded-b-xl'
            }`}
            src={headerImage}
          />
        )}
        {icon && <TextWrapper>{icon}</TextWrapper>}
        {title && (
          <TextWrapper>
            <div className='md:tracking-normal tracking-tighter tw-text-3xl tw-font-medium'>
              {title}
            </div>
          </TextWrapper>
        )}
        {subtitle && (
          <TextWrapper>
            <div className='tw-text-xl tw-font-normal tw-leading-5 tw-tracking-tight md:tw-tracking-normal'>
              {subtitle}
            </div>
          </TextWrapper>
        )}
        {children}
      </div>
    </div>
  );
};

export const TextWrapper = ({ children }: { children: ReactNode }) => (
  <div className='tw-my-3 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-px-10 tw-text-center'>
    {children}
  </div>
);
