import { RtcStats } from '@venue/types/rtc_stats';
import { MediaType } from '@venue/types/stream';
import { createAgoraNgAdapter } from './agoraNg.adapter';
import {
  Adapter,
  RoomType,
  StreamNetworkQuality,
  StreamsNetworkQuality,
  VideoQuality,
} from './types';

export const DEFAULT_ADAPTER = Adapter.AgoraNg;

export interface CreateStreamArgs {
  streamId?: string;
  cameraLabel?: string;
  microphoneLabel?: string;
}

export interface RtcService {
  joinRoom: (config: RoomConfig) => Promise<void>;
  leaveRoom: () => Promise<void>;

  createStream: (args?: CreateStreamArgs) => Promise<void>;
  destroyStream: () => Promise<void>;
  publishStream: () => Promise<void>;
  unpublishStream: () => Promise<void>;
  setVideoQuality: (quality: VideoQuality) => void;
  setAudioMuted: (muted: boolean) => boolean;
  setVideoMuted: (muted: boolean) => boolean;
  switchCamera: (deviceLabel: string) => Promise<void>;
  switchMicrophone: (deviceLabel: string) => Promise<void>;
  switchSpeakers: (deviceLabel: string) => Promise<void>;

  createScreen: () => Promise<void>;
  destroyScreen: () => Promise<void>;

  playStream: (_: {
    streamId: string;
    domId: string;
    mediaType?: MediaType;
    playbackDeviceId?: string;
  }) => Promise<void>;
  destroy: () => Promise<void>;

  getChannelName: () => string;

  getLocalStreamId: () => string;
  getRemoteStreamIds: () => Array<string>;

  // TODO: Deprecate these in favor of StreamInfo with StreamType
  // It will be needed for multiple screenshare to be possible
  getLocalScreenId: () => string;
  getRemoteScreenId: () => string;

  getAudioLevel: (streamId: string) => number;

  getLocalNetworkQuality: () => StreamNetworkQuality;
  getRemoteNetworkQuality: () => StreamsNetworkQuality;

  unblockAudio: () => void;
  publishTestStream: () => Promise<void>;

  getRtcStats: () => RtcStats;
}

export interface RoomConfig {
  roomId: string;
  roomType: RoomType;
}

export interface RtcServiceOptions {
  subscribeRemoteStreams: boolean;
}

export interface RtcServiceCallbacks {
  streamAdded?: (streamId: string) => void;
  streamRemoved?: (streamId: string) => void;
  streamReconnected?: (streamId: string) => void;
  streamDisconnected?: (streamId: string) => void;
  streamPublished?: () => void;
  audioMutedUpdated?: (streamId: string, isMuted: boolean) => void;
  videoMutedUpdated?: (streamId: string, isMuted: boolean) => void;
  networkQualityUpdated?: () => void;
  screenDestroyed?: () => void;
  userJoined?: (streamId: string) => void;
  autoplayBlocked?: () => void;
  disconnected?: () => void;
  reconnected?: () => void;
}

export interface OptionalRtcServiceOptions extends Partial<RtcServiceOptions> {}

export interface OptionalRtcServiceCallbacks
  extends Partial<RtcServiceCallbacks> {}

export interface CreateRtcAdapterArgs {
  eventId: string;
  options: RtcServiceOptions;
  callbacks: RtcServiceCallbacks;
}

export interface CreateRtcServiceArgs {
  adapter?: Adapter;
  eventId: string;
  options?: OptionalRtcServiceOptions;
  callbacks?: OptionalRtcServiceCallbacks;
}

export const createRtcService = ({
  adapter = Adapter.AgoraNg,
  eventId,
  options: { subscribeRemoteStreams = true } = {},
  callbacks: {
    streamAdded = () => {},
    streamRemoved = () => {},
    streamReconnected = () => {},
    streamDisconnected = () => {},
    streamPublished = () => {},
    audioMutedUpdated = () => {},
    videoMutedUpdated = () => {},
    networkQualityUpdated = () => {},
    screenDestroyed = () => {},
    userJoined = () => {},
    autoplayBlocked = () => {},
    disconnected = () => {},
    reconnected = () => {},
  } = {},
}: CreateRtcServiceArgs): Promise<RtcService> => {
  const options = {
    subscribeRemoteStreams,
  };
  const callbacks = {
    streamAdded,
    streamRemoved,
    streamReconnected,
    streamDisconnected,
    streamPublished,
    audioMutedUpdated,
    videoMutedUpdated,
    networkQualityUpdated,
    screenDestroyed,
    userJoined,
    autoplayBlocked,
    disconnected,
    reconnected,
  };

  switch (adapter) {
    case Adapter.AgoraNg:
      return createAgoraNgAdapter({ eventId, options, callbacks });
    default:
      throw new Error(`Invalid adapter: ${adapter}`);
  }
};
