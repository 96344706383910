import { DEVICE_PREFERENCES_KEY } from '@venue/store/device/contants';
import { loadLocalStorage } from '@venue/store/localStorage';
import { DeviceTypes } from '@venue/types/device';

const OPERATING_SYSTEM_DEFAULT_AUDIO_VIDEO_DEVICE_ID = 'default';

const DeviceLabelsForLocalStorage = {
  audioinput: 'microphoneLabel',
  audiooutput: 'speakersLabel',
  videoinput: 'cameraLabel',
};

interface GetLabelOfPreferredAudioVideoDeviceProps {
  devices: Array<MediaDeviceInfo>;
  type: MediaDeviceKind;
}

type LocalStorageDeviceResults =
  | Record<string, string | boolean | number>
  | undefined;

export const getLabelOfPreferredAudioVideoDeviceFetchingLocalStorage = (
  args: GetLabelOfPreferredAudioVideoDeviceProps
) => {
  // This function is split into two to facilitate testing
  const localStorageSavedDeviceLabels = loadLocalStorage(
    DEVICE_PREFERENCES_KEY
  ) as LocalStorageDeviceResults;
  const localStorageSavedLabel =
    localStorageSavedDeviceLabels[DeviceLabelsForLocalStorage[args.type]];
  const devicesOfType = getDevicesOfType(args.devices, args.type);
  const localStorageSavedDevice = devicesOfType.find(
    (device) => device.label === localStorageSavedLabel
  );
  return getLabelOfPreferredAudioVideoDevice({
    ...args,
    localStorageSavedDevice,
  });
};

export const getLabelOfPreferredAudioVideoDevice = ({
  devices = [],
  type,
  localStorageSavedDevice,
}: GetLabelOfPreferredAudioVideoDeviceProps & {
  localStorageSavedDevice?: MediaDeviceInfo;
}) => {
  // This function is split into two to facilitate testing
  if (localStorageSavedDevice) {
    return localStorageSavedDevice.label;
  }
  const devicesOfType = getDevicesOfType(devices, type);

  if (type === DeviceTypes.VideoInput) {
    return getLabelOfPreferredCameraDevice(devicesOfType);
  }
  // return the operating system assigned default, or the first device in the list
  const preferredDevice =
    devicesOfType.find(
      (dvc) => dvc.deviceId === OPERATING_SYSTEM_DEFAULT_AUDIO_VIDEO_DEVICE_ID
    ) || devicesOfType[0];
  // can be null if no devices present or permission is blocked
  return preferredDevice?.label;
};

const getDevicesOfType = (devices, type) => {
  return devices.filter(({ kind, label }) => kind === type && !!label);
};

const VIRTUAL_CAMERA_REGEX = /(virtual|mmhmm|snap)/im;

const getLabelOfPreferredCameraDevice = (
  devicesOfType: Array<MediaDeviceInfo>
) => {
  const deviceIsVirtualAndNotDefault = (deviceObject: MediaDeviceInfo) => {
    return (
      deviceObject.label?.match(VIRTUAL_CAMERA_REGEX) &&
      deviceObject.deviceId !== 'default'
    );
  };
  const virtualAndNotDefaultDevices = [...devicesOfType].filter((e) =>
    deviceIsVirtualAndNotDefault(e)
  );
  const nonVirtualOrDefaultDevices = [...devicesOfType].filter(
    (e) => !deviceIsVirtualAndNotDefault(e)
  );
  // deprioritize virtual devices that are not the system default
  const preferredDefaultDevice =
    nonVirtualOrDefaultDevices[0] || virtualAndNotDefaultDevices[0];
  // can be null if no devices present or permission is blocked
  return preferredDefaultDevice?.label;
};

export const areDeviceListsDifferent = (
  arrayOne: Array<MediaDeviceInfo>,
  arrayTwo: Array<MediaDeviceInfo>
) => {
  const fingerprintsOne = arrayOne.map((device) => [device.label]);
  const fingerprintsTwo = arrayTwo.map((device) => [device.label]);
  return JSON.stringify(fingerprintsOne) !== JSON.stringify(fingerprintsTwo);
};

export const translateDeviceLabelToId = async (
  deviceLabel: string,
  type: MediaDeviceKind
) => {
  if (!deviceLabel || deviceLabel === '') {
    return null;
  }
  const devices = await navigator.mediaDevices.enumerateDevices();
  const matchingDevice = devices.find(
    (device) => device.label === deviceLabel && device.kind === type
  );
  if (matchingDevice) {
    return matchingDevice.deviceId;
  }
  return null;
};
