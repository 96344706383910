import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActivityActions, ActivityActionTypes, ActivityState } from './types';

export const initialState: ActivityState = {
  activities: [],
};

export const activityReducer: Reducer<ActivityState> = produce(
  (draft: Draft<ActivityState>, action: ActivityActions) => {
    switch (action.type) {
      case ActivityActionTypes.ADD_ACTIVITIES:
        draft.activities.push(...action.activities);
        break;
    }
  },
  initialState
);
