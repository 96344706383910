import { FirestoreRoom } from '@venue/types/firestore';
import { StageState } from '@venue/types/stage';
import { Icon, Tab, TabValue } from '@venue/types/tab';

export interface NavState {
  readonly currentTab: TabValue | null;
  readonly attendeeTabs: Array<TabValue>;
  readonly organizerTabs: Array<TabValue>;
  readonly tabsByValue: Partial<{ [value in TabValue]: Tab }>;
  readonly nextTabs: Array<TabValue>;
}

export enum NavActionTypes {
  SET_CURRENT_TAB = '[Nav] Set Current Tab',
  REFRESH_TABS = '[Nav] Refresh Tabs',
  PREVIOUS_TAB = '[Nav] Previous Tab',
  NEXT_TAB = '[Nav] Next Tab',
  REPLACE_ICON = '[Nav] Replace Icon',
  RESTORE_TAB = '[Nav] Restore Tab',
}

export interface SetCurrentTabAction {
  readonly type: typeof NavActionTypes.SET_CURRENT_TAB;
  readonly tab: TabValue;
}

export interface RefreshTabsAction {
  readonly type: typeof NavActionTypes.REFRESH_TABS;
  readonly state: StageState;
  readonly substate: string | null;
  readonly isAdmin: boolean;
  readonly isMobile: boolean;
  readonly isOrganizer: boolean;
  readonly isRtcEnabled: boolean;
  readonly isSpeaker: boolean;
  readonly currentRoom: FirestoreRoom;
}

export interface PreviousTabAction {
  readonly type: typeof NavActionTypes.PREVIOUS_TAB;
}

export interface NextTabAction {
  readonly type: typeof NavActionTypes.NEXT_TAB;
}

export interface ReplaceIconAction {
  readonly type: typeof NavActionTypes.REPLACE_ICON;
  readonly tab: TabValue;
  readonly icon: Icon;
}

export interface RestoreTabAction {
  readonly type: typeof NavActionTypes.RESTORE_TAB;
}

export type NavActions =
  | SetCurrentTabAction
  | RefreshTabsAction
  | PreviousTabAction
  | NextTabAction
  | ReplaceIconAction
  | RestoreTabAction;
