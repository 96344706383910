import { translateDeviceLabelToId } from '@venue/features/audioVideoDevices/audioVideoDevices';
import {
  createRtcService,
  CreateRtcServiceArgs,
} from '@venue/services/rtc/rtc.service';
import { RoomType, StreamNetworkQuality } from '@venue/services/rtc/types';
import { DeviceTypes } from '@venue/types/device';
import { MediaType } from '@venue/types/stream';
import { ApplicationThunkAction } from '@venue/types/thunk';
import { formatFirestoreError } from '@venue/utils/firestoreError';
import { updateEventNetworkingDuration } from '../../services/event.service';
import { speakersLabelSelector } from '../device/selectors';
import {
  MuteLocalAudioAction,
  MuteLocalVideoAction,
  MuteRemoteAudioAction,
  MuteRemoteVideoAction,
  NetworkingActionTypes,
  ResetNetworkingForNextMatchAction,
  ResetRemoteNetworkingAction,
  SetLocalStreamAction,
  SetRemoteStreamAction,
  UnmuteLocalAudioAction,
  UnmuteLocalVideoAction,
  UnmuteRemoteAudioAction,
  UnmuteRemoteVideoAction,
} from './types';

const setLocalStreamId = (streamId: string): SetLocalStreamAction => ({
  type: NetworkingActionTypes.SET_LOCAL_STREAM,
  streamId,
});

export const playLocalStream = (domId: string): ApplicationThunkAction => {
  return (_, getState, { getRtc }) => {
    const streamId = getState().networking.localStreamId;
    if (streamId) {
      return getRtc().networkingRtcService.playStream({ streamId, domId });
    }
  };
};

export const updateRemoteStreamId =
  (): ApplicationThunkAction =>
  (dispatch, _, { getRtc }) => {
    const { networkingRtcService } = getRtc();
    const remoteStreamIds = networkingRtcService?.getRemoteStreamIds() || [];
    const remoteStreamId = remoteStreamIds[0] || null;

    return dispatch(setRemoteStreamId(remoteStreamId));
  };

const setRemoteStreamId = (streamId: string | null): SetRemoteStreamAction => ({
  type: NetworkingActionTypes.SET_REMOTE_STREAM,
  streamId,
});

export const playRemoteStream = (
  domId: string,
  mediaType?: MediaType
): ApplicationThunkAction => {
  return async (_, getState, { getRtc }) => {
    const state = getState();
    const streamId = state.networking.remoteStreamId;
    const speakersLabel = speakersLabelSelector(state);
    const playbackDeviceId = await translateDeviceLabelToId(
      speakersLabel,
      DeviceTypes.AudioOutput
    );
    if (streamId) {
      return getRtc().networkingRtcService.playStream({
        streamId,
        domId,
        mediaType,
        playbackDeviceId,
      });
    }
  };
};

export const resetNetworkingForNextMatch =
  (): ApplicationThunkAction => (dispatch) => {
    dispatch(resetRtcService());
    return dispatch(resetNetworkingForNextMatchAction());
  };

const resetNetworkingForNextMatchAction =
  (): ResetNetworkingForNextMatchAction => ({
    type: NetworkingActionTypes.RESET_NETWORKING_FOR_NEXT_MATCH,
  });

const resetRemoteNetworkingAction = (): ResetRemoteNetworkingAction => ({
  type: NetworkingActionTypes.RESET_REMOTE_NETWORKING,
});

export const muteLocalAudio = (): ApplicationThunkAction => {
  return (dispatch, _, { getRtc }) => {
    const isSuccess = getRtc().networkingRtcService.setAudioMuted(true);
    if (isSuccess) {
      return dispatch(muteLocalAudioAction());
    }
  };
};

const muteLocalAudioAction = (): MuteLocalAudioAction => ({
  type: NetworkingActionTypes.MUTE_LOCAL_AUDIO,
});

export const unmuteLocalAudio = (): ApplicationThunkAction => {
  return (dispatch, _, { getRtc }) => {
    const isSuccess = getRtc().networkingRtcService.setAudioMuted(false);
    if (isSuccess) {
      return dispatch(unmuteLocalAudioAction());
    }
  };
};

const unmuteLocalAudioAction = (): UnmuteLocalAudioAction => ({
  type: NetworkingActionTypes.UNMUTE_LOCAL_AUDIO,
});

export const muteLocalVideo = (): ApplicationThunkAction => {
  return (dispatch, _, { getRtc }) => {
    const isSuccess = getRtc().networkingRtcService.setVideoMuted(true);
    if (isSuccess) {
      return dispatch(muteLocalVideoAction());
    }
  };
};

const muteLocalVideoAction = (): MuteLocalVideoAction => ({
  type: NetworkingActionTypes.MUTE_LOCAL_VIDEO,
});

export const unmuteLocalVideo = (): ApplicationThunkAction => {
  return (dispatch, _, { getRtc }) => {
    const isSuccess = getRtc().networkingRtcService.setVideoMuted(false);
    if (isSuccess) {
      return dispatch(unmuteLocalVideoAction());
    }
  };
};

const unmuteLocalVideoAction = (): UnmuteLocalVideoAction => ({
  type: NetworkingActionTypes.UNMUTE_LOCAL_VIDEO,
});

export const muteRemoteAudio = (): MuteRemoteAudioAction => ({
  type: NetworkingActionTypes.MUTE_REMOTE_AUDIO,
});

export const unmuteRemoteAudio = (): UnmuteRemoteAudioAction => ({
  type: NetworkingActionTypes.UNMUTE_REMOTE_AUDIO,
});

export const muteRemoteVideo = (): MuteRemoteVideoAction => ({
  type: NetworkingActionTypes.MUTE_REMOTE_VIDEO,
});

export const unmuteRemoteVideo = (): UnmuteRemoteVideoAction => ({
  type: NetworkingActionTypes.UNMUTE_REMOTE_VIDEO,
});

export const switchCamera =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchCamera(currentDeviceId);
  };

export const switchMicrophone =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchMicrophone(currentDeviceId);
  };

export const switchSpeakers =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchSpeakers(currentDeviceId);
  };

export const initializeRtcService =
  (args: CreateRtcServiceArgs): ApplicationThunkAction<Promise<void>> =>
  async (_1, _2, { getRtc }) => {
    const rtc = getRtc();
    const rtcService = await createRtcService(args);
    rtc.networkingRtcService = rtcService;
  };

export const resetRtcService =
  (): ApplicationThunkAction<Promise<void>> =>
  async (_1, _2, { getRtc }) => {
    const rtc = getRtc();
    rtc.networkingRtcService?.destroy();
    rtc.networkingRtcService = null;
  };

export const createStream =
  (streamId: string): ApplicationThunkAction =>
  async (dispatch, getState, { getRtc }): Promise<void> => {
    const { cameraLabel, microphoneLabel } = getState().device;
    await getRtc().networkingRtcService.createStream({
      streamId,
      cameraLabel,
      microphoneLabel,
    });
    dispatch(setLocalStreamId(streamId));
  };

export const joinRoom =
  (roomId: string): ApplicationThunkAction =>
  async (_1, _2, { getRtc }): Promise<void> => {
    const config = {
      roomId,
      roomType: RoomType.Networking,
    };
    await getRtc().networkingRtcService.joinRoom(config);
  };

export const leaveRoom =
  (): ApplicationThunkAction =>
  async (dispatch, _, { getRtc }): Promise<void> => {
    await getRtc().networkingRtcService?.leaveRoom();

    dispatch(resetRemoteNetworkingAction());
    dispatch(updateRemoteStreamId());
  };

export const detectNoShow =
  (): ApplicationThunkAction<boolean> =>
  (_, getState): boolean => {
    const remoteStreamId = getState().networking.remoteStreamId;
    return !remoteStreamId;
  };

export const publishStream =
  (): ApplicationThunkAction =>
  async (_1, _2, { getRtc }): Promise<void> => {
    await getRtc().networkingRtcService.publishStream();
  };

export const setNetworkingDuration = ({
  eventId,
  eventShareableId,
  networkingDuration,
}: {
  eventId: string;
  eventShareableId: string;
  networkingDuration: number;
}): ApplicationThunkAction => {
  return async (_1, _2, { getFirestore }) => {
    try {
      await updateEventNetworkingDuration(eventShareableId, networkingDuration);
      return await getFirestore().collection('events').doc(eventId).set(
        {
          networkingDuration,
        },
        { merge: true }
      );
    } catch (err) {
      const errorMessage = formatFirestoreError(
        'actions',
        'setNetworkingDuration',
        err
      );
      console.log(errorMessage);
      throw Error(errorMessage);
    }
  };
};

export const getNetworkQuality =
  (): ApplicationThunkAction<{ [x: string]: StreamNetworkQuality }> =>
  (_1, _2, { getRtc }) => {
    const { networkingRtcService } = getRtc();

    if (!networkingRtcService) {
      return {};
    }

    const uid = networkingRtcService.getLocalStreamId();

    return {
      ...networkingRtcService.getRemoteNetworkQuality(),
      [uid]: networkingRtcService.getLocalNetworkQuality(),
    };
  };
