import { SEASONAL_REACTION_PRESETS } from '@venue/features/seasonalPacks/reactionPreset';
import { currentUserIdSelector } from '@venue/store/auth/selectors';
import { RootState } from '@venue/store/types';
import { BreakoutRoom } from '@venue/types/breakout_rooms';
import { StageState } from '@venue/types/stage';
import { UserRole } from '@venue/types/user';
import { createSelector } from 'reselect';
import {
  currentEventSelector,
  currentEventStateSelector,
} from '../firestore/selectors';

export const eventSelector = (state: RootState) => state.event.event;

export const eventIdSelector = (state: RootState) => state.event.event.id;

export const eventShareableIdSelector = (state: RootState) =>
  state.event.event.shareable_id;

export const roomIdSelector = (state: RootState) =>
  state.event.event.chat_room_id;

export const backstageRoomIdSelector = (state: RootState) =>
  state.event.event.backstage_chat_room_id;

export const canAdministrateSelector = (state: RootState) =>
  state.event.permissions.can_administrate;

export const canOrganizeSelector = (state: RootState) =>
  state.event.permissions.can_organize;

export const canSpeakSelector = (state: RootState) =>
  state.event.permissions.can_speak;

export const canRecordSelector = (state: RootState) =>
  state.event.permissions.can_record;

export const isProducerSelector = createSelector(
  canAdministrateSelector,
  canOrganizeSelector,
  (canAdministrate, canOrganize) => canAdministrate || canOrganize
);

export const roleSelector: (state: RootState) => UserRole = createSelector(
  isProducerSelector,
  canSpeakSelector,
  (isProducer, canSpeak) => {
    if (isProducer) {
      return 'producer';
    }

    if (canSpeak) {
      return 'speaker';
    }

    return 'attendee';
  }
);

export const hasBackstageReadAccessSelector = createSelector(
  isProducerSelector,
  canSpeakSelector,
  (isProducer, canSpeak) => isProducer || canSpeak
);

export const hasBackstageWriteAccessSelector = isProducerSelector;

export const firewallTestRoomIdSelector = createSelector(
  eventIdSelector,
  currentUserIdSelector,
  (eventId, currentUserId) => `test-${eventId}-${currentUserId}`
);

export const recordingSpaceSelector = (state: RootState) =>
  state.event.permissions.recording_space;

const roomGroupIdSelector = (state: RootState) =>
  state.firestore.data.currentEvent?.roomGroupId;

export const organizerRoomGroupIdSelector = (state: RootState) =>
  state.event.organizerRoomGroupId;

const localRoomGroupIdSelector = createSelector(
  organizerRoomGroupIdSelector,
  roomGroupIdSelector,
  (organizerRoomGroupId, eventRoomGroupId) =>
    organizerRoomGroupId || eventRoomGroupId
);

export const roomGroupsSelector = (state: RootState) =>
  state.event.event.room_groups;

const selectedRoomGroupSelector = createSelector(
  roomGroupsSelector,
  localRoomGroupIdSelector,
  (roomGroups, localRoomGroupId) =>
    roomGroups.find((roomGroup) => roomGroup.id === localRoomGroupId)
);

const emptyBreakoutRooms: Array<BreakoutRoom> = [];
export const breakoutRoomsSelector = createSelector(
  selectedRoomGroupSelector,
  (selectedRoomGroup) =>
    (selectedRoomGroup && selectedRoomGroup.breakout_rooms) ||
    emptyBreakoutRooms
);

export const pageStateSelector = (state: RootState) => state.event.pageState;

export const substateSelector = (state: RootState) =>
  state.event.permissions.recording_space || state.event.pageState;

export const eventBreakoutRoomsSelector = (state: RootState) =>
  state.event.event.breakout_rooms;

export const headerImageSelector = (state: RootState) =>
  state.event.event.header_image;

export const searchIndexSelector = (state: RootState) =>
  state.event.permissions.search_index;

export const eventPermissionsSelector = (state: RootState) =>
  state.event.permissions;

export const userRoleSelector = (state: RootState): UserRole => {
  if (!state.event.permissions) {
    return 'anonymous';
  }

  if (state.event.permissions.can_record) {
    return 'recorder';
  } else if (state.event.permissions.can_organize) {
    return 'producer';
  } else if (state.event.permissions.can_speak) {
    return 'speaker';
  } else {
    return 'attendee';
  }
};

export const canJoinNonLiveEventSelector = (state: RootState) => {
  const userRole = userRoleSelector(state);

  return ['producer', 'presenter'].includes(userRole);
};

export const forceSpeakerChecksSelector = (state: RootState) =>
  state.event.event.force_speaker_checks;

export const organizerEventStateSelector = (state: RootState) =>
  state.event.organizerEventState;

export const systemCheckPassedSelector = (state: RootState) =>
  state.event.systemCheckPassed;

export const reactionPresetsSelector = createSelector(
  eventSelector,
  currentEventSelector,
  (event, currentEvent) => {
    return currentEvent?.reactionPresets
      ? [
          ...SEASONAL_REACTION_PRESETS,
          ...currentEvent.reactionPresets.map((item) => item.split(' ')),
        ]
      : [...SEASONAL_REACTION_PRESETS, event.organization.reactions];
  }
);

export const currentStageSelector = createSelector(
  organizerEventStateSelector,
  currentEventStateSelector,
  substateSelector,
  (organizerState, eventState, substate) =>
    [StageState.Breakout, StageState.RoomGroup].includes(
      organizerState || eventState
    )
      ? substate
      : StageState.MainStage
);

export const organizationSelector = (state: RootState) =>
  state.event.event.organization;

export const adminTokenSelector = (state: RootState): string =>
  state.firestore.data.currentVirtualBrowser &&
  state.firestore.data.currentVirtualBrowser.adminToken;
