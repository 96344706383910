import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RoomFeatureKey } from '@venue/store/feature/types';
import { defineMessage } from 'react-intl';

// The order they are defined here is the order they show up.
// TODO: fix that by declaring the order in the redux state.
export enum TabValue {
  // Attendee tabs.
  Profile = 'profile',

  // Grouped in the same tab.
  Chat = 'messages',
  Backstage = 'backstage_messages',

  Questions = 'questions',
  Polls = 'polls',
  Agenda = 'agenda',
  Help = 'help',
  People = 'people',

  // *Organizer* tabs from here on.
  Spaces = 'spaces',

  // Grouped in the same tab.
  SpeakerQueue = 'speaker_queue',
  SpeakerGroups = 'speaker_groups',

  Share = 'share',
  StageLayout = 'stage_layout',
  Actions = 'actions',
  Reactions = 'reactions',

  // Grouped in the same tab.
  Music = 'music',
  SoundEffects = 'sound_effects',

  CustomTheme = 'change_background',
  QualitativeFeedback = 'qualitative_feedback',
  Activities = 'activities',
  EventSettings = 'organizer_tools',
}

export const TabLabelsByValue = {
  [TabValue.EventSettings]: defineMessage({
    defaultMessage: 'Event Settings',
    description: 'tab label',
  }),
  [TabValue.Backstage]: defineMessage({
    defaultMessage: 'Backstage',
    description: 'tab label',
  }),
  [TabValue.Chat]: defineMessage({
    defaultMessage: 'Chat',
    description: 'tab label',
  }),
  [TabValue.Profile]: defineMessage({
    defaultMessage: 'Profile',
    description: 'tab label',
  }),
  [TabValue.Questions]: defineMessage({
    defaultMessage: 'Questions',
    description: 'tab label',
  }),
  [TabValue.SpeakerQueue]: defineMessage({
    defaultMessage: 'Speaker Queue',
    description: 'tab label',
  }),
  [TabValue.SpeakerGroups]: defineMessage({
    defaultMessage: 'Speaker Groups',
    description: 'tab label',
  }),
  [TabValue.Help]: defineMessage({
    defaultMessage: 'Help',
    description: 'tab label',
  }),
  [TabValue.Activities]: defineMessage({
    defaultMessage: 'Activities',
    description: 'tab label',
  }),
  [TabValue.Reactions]: defineMessage({
    defaultMessage: 'Reactions',
    description: 'tab label',
  }),
  [TabValue.Agenda]: defineMessage({
    defaultMessage: 'Agenda',
    description: 'tab label',
  }),
  [TabValue.CustomTheme]: defineMessage({
    defaultMessage: 'Custom Theme',
    description: 'tab label',
  }),
  [TabValue.Share]: defineMessage({
    defaultMessage: 'Share',
    description: 'tab label',
  }),
  [TabValue.People]: defineMessage({
    defaultMessage: 'People',
    description: 'tab label',
  }),
  [TabValue.StageLayout]: defineMessage({
    defaultMessage: 'Stage Layout',
    description: 'tab label',
  }),
  [TabValue.Music]: defineMessage({
    defaultMessage: 'Music',
    description: 'tab label',
  }),
  [TabValue.SoundEffects]: defineMessage({
    defaultMessage: 'Sound effects',
    description: 'tab label',
  }),
  [TabValue.QualitativeFeedback]: defineMessage({
    defaultMessage: 'Feedback editor',
    description: 'tab label',
  }),
  [TabValue.Polls]: defineMessage({
    defaultMessage: 'Polls',
    description: 'tab label',
  }),
  [TabValue.Actions]: defineMessage({
    defaultMessage: 'Actions',
    description: 'tab label',
  }),
  [TabValue.Spaces]: defineMessage({
    defaultMessage: 'Spaces',
    description: 'tab label',
  }),
};

export type Icon = IconDefinition | string;

export interface Tab {
  icon: Icon;
  value: TabValue;
  isOrganizer: boolean;
  roomFeature?: RoomFeatureKey;
}

export interface TabContainerProps {
  tab: TabValue;
  roomId: string;
}
