import { RootState } from '@venue/store/types';
import { createSelector } from 'reselect';

export const currentUserIdSelector = (state: RootState): string =>
  state.auth.currentUser.attributes.id;

export const integerUserIdSelector = createSelector(
  currentUserIdSelector,
  (userId) => parseInt(userId, 10)
);

export const currentUserSelector = (state: RootState) =>
  state.auth.currentUser.attributes;

export const firstNameSelector = (state: RootState) =>
  state.auth.currentUser.attributes.firstName;

export const lastNameSelector = (state: RootState) =>
  state.auth.currentUser.attributes.lastName;

export const headlineSelector = (state: RootState) =>
  state.auth.currentUser.attributes.headline;

export const pronounsSelector = (state: RootState) =>
  state.auth.currentUser.attributes.pronouns;

export const githubSelector = (state: RootState) =>
  state.auth.currentUser.attributes.github;

export const instagramSelector = (state: RootState) =>
  state.auth.currentUser.attributes.instagram;

export const twitterSelector = (state: RootState) =>
  state.auth.currentUser.attributes.twitter;

export const linkedinSelector = (state: RootState) =>
  state.auth.currentUser.attributes.linkedin;

export const discordSelector = (state: RootState) =>
  state.auth.currentUser.attributes.discord;

export const tiktokSelector = (state: RootState) =>
  state.auth.currentUser.attributes.tiktok ?? null;

export const profileImageSelector = (state: RootState) =>
  state.auth.currentUser.attributes.profileImage ?? null;
