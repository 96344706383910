import {
  IS_POSTHOG_ENABLED,
  POSTHOG_API_HOST,
  POSTHOG_TOKEN,
} from '@common/constants';
import posthog, { Properties } from 'posthog-js';
import { TrackEventParams } from './posthog.types';

interface EventProperties {
  [key: string]: string | number | boolean;
}

export const initProductAnalytics = () => {
  if (IS_POSTHOG_ENABLED) {
    try {
      posthog.init(POSTHOG_TOKEN, {
        api_host: POSTHOG_API_HOST,
        autocapture: false, // It's hard to take advantage of autocaptured events - https://posthog.com/blog/is-autocapture-still-bad
        enable_recording_console_log: true,
      });
    } catch (e) {
      console.log('PostHog init error', e);
    }
  } else {
    console.log('[fake: posthog] initializing product analytics');
  }
};

export const identifyPersonProperties = (
  userId: string,
  userPropertiesToSet?: Properties
) => {
  if (IS_POSTHOG_ENABLED) {
    try {
      posthog.identify(userId, userPropertiesToSet);
    } catch (e) {
      console.log('PostHog identify error', e);
    }
  } else {
    console.log('[fake: posthog] identifying person properties', {
      userId,
      userPropertiesToSet,
    });
  }
};

export const registerEventProperties = (
  eventProperties: EventProperties = {}
) => {
  if (IS_POSTHOG_ENABLED) {
    try {
      posthog.register(eventProperties);
    } catch (e) {
      console.log('PostHog register error', e);
    }
  } else {
    console.log(
      '[fake: posthog] registering event properties',
      eventProperties
    );
  }
};

export const trackEvent = ({ name, attributes }: TrackEventParams) => {
  if (IS_POSTHOG_ENABLED) {
    try {
      posthog.capture(name, attributes);
    } catch (e) {
      console.log('PostHog capture error', e);
    }
  } else {
    console.debug({ name, attributes });
  }
};

export const trackBringOnStageEvent = (
  action:
    | 'created'
    | 'received'
    | 'waiting for consent'
    | 'accepted'
    | 'declined'
    | 'executing'
    | 'completed'
    | 'failed'
    | 'aborted',
  userId: string
) => {
  trackEvent({ name: 'Bring On Stage Event', attributes: { action, userId } });
};
