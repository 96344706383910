interface Button {
  boxShadow?: string;
  color: string;
  bg: string;
  disabledBg?: string;
  inactiveBg?: string;
  disabledColor?: string;
  disabledBoxShadow?: string;
  fontWeight: number;
}

export type ColorScheme = 'light' | 'dark';

export interface Theme {
  venue: {
    colorScheme: ColorScheme;
    organization: {
      primary: string;
      secondary: string;
    };
    colors: {
      title: string;
      light: string;
      gray: string;
      text: string;
      background: string;
      primary: string;
      positive: string;
      positive50: string;
      negative: string;
      warning: string;
      highlight: string;
      info: string;
      dark: string;
      //   secondary: string;
      //   accent: string;
      muted: string;
      'venue-primary': string;
      'venue-secondary': string;

      p100: string;
      p200: string;
      p300: string;
      p400: string;
      p500: string;
      p600: string;
      p700: string;
      p800: string;
      p900: string;

      // Neutrals
      n100: string;
      n200: string;
      n300: string;
      n500: string;
      n700: string;
      n800: string;
      n900: string;

      space: {
        background: string;
      };

      loaderBackground: string;
      loaderForeground: string;
    };
    transparents: {
      muted: string;
      faded: string;
      light: string;
      danger: string;
      dark: string;
      softDark: string;
      lightGray: string;
      highlight: string;
    };
    // fonts: {
    //   body: string;
    //   heading: string;
    //   monospace: string;
    // };
    padding: {
      p0: string;
      p0_5: string;
      p1: string;
      p2: string;
      p3: string;
      p4: string;
      p5: string;
      p6: string;
      p7: string;
      p8: string;
    };
    margin: {
      m0: string;
      m0_5: string;
      m1: string;
      m2: string;
      m3: string;
      m4: string;
      m5: string;
      m6: string;
      m7: string;
      m8: string;
    };
    fontSize: {
      xs: string;
      sm: string;
      base: string;
      lg: string;
      xl: string;
      xl2: string;
      xl3: string;
      xl4: string;
      xl5: string;
      xl6: string;
      xl7: string;
      xl8: string;
      xl9: string;
    };
    fontFamily: {
      sans: string;
      serif: string;
      mono: string;
    };
    fontWeights: {
      body: number;
      heading: number;
      bold: number;
    };
    // lineHeights: {
    //   body: string;
    //   heading: string;
    // };
    backgrounds: {
      darkBlue: string;
    };
    links: {
      primary: {
        color: string;
        disabledColor: string;
        visited: string;
      };
    };
    buttons: {
      primary: Button;
      secondary: Button;
      tertiary: Button;
      negative: Button;
      negativeSecondary: Button;
      transparent: Button;
      stream: {
        bg: string;
      };
    };
    borders: {
      muted: string;
    };
    input: {
      background: string;
    };
    boxShadow: {
      base: string;
    };
  };
}

export const lightTheme: Theme = {
  venue: {
    colorScheme: 'light',
    organization: {
      primary: '#ffffff',
      secondary: '#6c60ed',
    },
    colors: {
      title: '#ffffff',
      background: '#ffffff', // tw-bg-white dark:tw-bg-neutral-900
      text: '#000000',
      light: '#ffffff',
      gray: '#999999',
      dark: '#000000',
      primary: '#2f2d60',
      positive: '#70ac00',
      negative: '#ff5757',
      warning: '#ffc107',
      highlight: '#fbf4dd',
      info: '#20b5e4',
      muted: '#ebebeb', // tw-border-neutral-200 dark:tw-border-gray-700
      'venue-primary': '#2f2d60',
      'venue-secondary': '#fed363',
      positive50: 'rgba(112,172,0,0.5)',

      p100: 'hsl(231, 80%, 90%)',
      p200: 'hsl(234, 76%, 80%)',
      p300: 'hsl(238, 72%, 67%)',
      p400: 'hsl(240, 64%, 52%)',
      p500: 'hsl(242, 84%, 39%)',
      p600: 'hsl(243, 94%, 30%)',
      p700: 'hsl(245, 94%, 24%)',
      p800: 'hsl(245, 88%, 20%)',
      p900: 'hsl(246, 86%, 16%)',

      // Neutrals
      n100: '#F6F6F6',
      n200: '#E9E9E9',
      n300: '#CDCDD9',
      n500: '#999999',
      n700: '#606060',
      n800: '#333333',
      n900: '#121212',

      space: {
        background: '#ffffff',
      },

      loaderBackground: '#f3f3f3',
      loaderForeground: '#ecebeb',
    },
    transparents: {
      muted: 'rgba(200, 200, 200, 0.2)',
      faded: 'rgba(255, 255, 255, 0.4)',
      light: 'rgba(255, 255, 255, 0.65)',
      danger: 'rgba(255, 87, 87, 0.1)',
      dark: 'rgba(0, 0, 0, 0.65)',
      softDark: 'rgba(0, 0, 0, 0.4)',
      lightGray: 'rgba(0, 0, 0, 0.1)',
      highlight: 'rgba(47, 45, 96, 0.05)',
    },
    buttons: {
      primary: {
        color: '#fff',
        bg: '#2f2d60',
        disabledBg: 'rgba(47, 45, 96, 0.5)',
        inactiveBg: 'rgba(47, 45, 96, 0.35)',
        fontWeight: 600,
      },
      secondary: {
        boxShadow: '#2f2d60 0 0 0 0.125rem inset',
        color: '#2f2d60',
        bg: '#fff',
        disabledColor: '#2f2d6033',
        disabledBoxShadow: '#2f2d6033 0 0 0 0.125rem inset',
        fontWeight: 600,
      },
      tertiary: {
        color: '#2f2d60',
        bg: '#f6f6f6',
        disabledColor: '#2f2d6033',
        fontWeight: 400,
      },
      negative: {
        color: '#ff5757',
        bg: 'transparent',
        disabledColor: '#ff5757',
        fontWeight: 400,
      },
      negativeSecondary: {
        color: '#FCFCFC',
        bg: '#ff5757',
        disabledColor: '#2f2d6033',
        fontWeight: 400,
      },
      transparent: {
        color: '#2f2d60',
        bg: 'transparent',
        disabledColor: 'transparent',
        fontWeight: 400,
      },
      stream: {
        bg: 'rgba(0, 0, 0, 0.1)',
      },
    },
    backgrounds: {
      darkBlue: '#1c1d2e',
    },
    links: {
      primary: {
        color: '#2f2d60',
        disabledColor: '#2f2d6080',
        visited: '#2f2d60',
      },
    },
    fontSize: {
      xs: '0.46875rem',
      sm: '0.546875rem',
      base: '0.625rem',
      lg: '0.6875rem',
      xl: '0.75rem',
      xl2: '0.9375rem',
      xl3: '1.125rem',
      xl4: '1.3125rem',
      xl5: '1.875rem',
      xl6: '2.34375rem',
      xl7: '2.8125rem',
      xl8: '3.75rem',
      xl9: '5.0rem',
    },
    fontWeights: {
      body: 400,
      heading: 500,
      bold: 600,
    },
    fontFamily: {
      sans: '"Phantom Sans 0.6", Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
      serif: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;',
      mono: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;',
    },
    borders: {
      muted: '0.0625rem solid rgba(0, 0, 0, 0.1)',
    },
    input: {
      background: '#fafafa',
    },
    padding: {
      p0: '0',
      p0_5: '0.125rem',
      p1: '0.25rem',
      p2: '0.5rem',
      p3: '0.75rem',
      p4: '1.0rem',
      p5: '1.25rem',
      p6: '1.5rem',
      p7: '1.75rem',
      p8: '2.0rem',
    },
    margin: {
      m0: '0',
      m0_5: '0.125rem',
      m1: '0.25rem',
      m2: '0.5rem',
      m3: '0.75rem',
      m4: '1.0rem',
      m5: '1.25rem',
      m6: '1.5rem',
      m7: '1.75rem',
      m8: '2.0rem',
    },
    boxShadow: {
      base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.06)',
    },
  },
};

export const darkTheme: Theme = {
  venue: {
    ...lightTheme.venue,
    colorScheme: 'dark',
    organization: {
      primary: '#efefef',
      secondary: '#eeeeee',
    },
    colors: {
      ...lightTheme.venue.colors,
      title: '#1a1d21',
      background: '#171717', // dark:tw-bg-neutral-900
      text: '#c5c6c7',
      light: '#1b1d21',
      dark: '#dfdfdf',
      primary: '#c5c6c7',
      muted: '#35373b',
      highlight: '#666666',

      // Neutrals
      n100: '#121212',
      n200: '#333333',
      n300: '#606060',
      n500: '#999999',
      n700: '#CDCDD9',
      n800: '#E9E9ED',
      n900: '#F6F6F6',

      space: {
        background: '#1a1d21',
      },
    },
    transparents: {
      ...lightTheme.venue.transparents,
      light: 'rgba(0, 0, 0, 0.65)',
      faded: 'rgba(26, 29, 33, 0.4)',
      muted: 'rgba(55, 55, 55, 0.2)',
      dark: 'rgba(255, 255, 255, 0.65)',
      softDark: 'rgba(255, 255, 255, 0.7)',
      lightGray: 'rgba(255, 255, 255, 0.4)',
      highlight: 'rgba(200,200,200,0.05)',
    },
    buttons: {
      ...lightTheme.venue.buttons,
      primary: {
        color: '#222',
        bg: '#dddddd',
        disabledBg: '#878a92',
        inactiveBg: '#3e4044',
        fontWeight: 600,
      },
      secondary: {
        boxShadow: '#dddddd 0 0 0 0.125rem inset',
        color: '#dddddd',
        bg: '#333',
        disabledColor: '#dddddd33',
        disabledBoxShadow: '#dddddd33 0 0 0 0.125rem inset',
        fontWeight: 600,
      },
      tertiary: {
        color: '#dddddd',
        bg: '#333',
        disabledColor: '#dddddd33',
        fontWeight: 400,
      },
      negative: {
        color: '#ff5757',
        bg: 'transparent',
        disabledColor: 'transparent',
        fontWeight: 400,
      },
      negativeSecondary: {
        color: '#FCFCFC',
        bg: '#ff5757',
        disabledColor: '#2f2d6033',
        fontWeight: 400,
      },
      transparent: {
        color: '#dddddd',
        bg: '#333',
        disabledColor: '#dddddd33',
        fontWeight: 400,
      },
    },
    links: {
      primary: {
        color: '#dddddd',
        disabledColor: '#dddddd33',
        visited: '#dddddd',
      },
    },
    borders: {
      muted: '0.0625rem solid rgba(255, 255, 255, 0.1)',
    },
    input: {
      background: '#232529',
    },
    boxShadow: {
      base: '0 1px 3px 0 rgba(255, 255, 255, 0.1), 0 0 2px 0 rgba(255, 255, 255, 0.06)',
    },
  },
};

export const backgroundImageCss = (backgroundImage?: string) => `
  html {
    background-image: url(${
      backgroundImage || '/backgrounds/static/transparent.png'
    });
  }
`;

export const globalStyles = (theme: Theme) => `
  ::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.venue.colors.n300};
    border-radius: 0.75rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.venue.colors.n500};
  }

  html {
    height: 100%;
    background-color: ${theme.venue.colors.space.background};

    background-attachment: fixed;
    background-size: cover;
    -webkit-transition: background-image 0.5s;
    transition: background-image 0.5s;
  }

  body {
    color: ${theme.venue.colors.text};
    min-height: 100vh; /* Fallback to vh if dvh is missing */
    min-height: 100dvh; /* dvh fills the inner window excluding browser UI elements */
    display: flex;
  }

  #root {
    flex: 1;
  }

  .App {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  input[type=radio] {
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }

  button {
    cursor: pointer;
    background-color: ${theme.venue.colors.background};
    color: ${theme.venue.colors.text};
    user-select: none;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:focus {
    box-shadow: inset 0 0 0.5rem 0.05rem ${theme.venue.colors.n200};
    outline: none;
  }

  input,
  textarea {
    border: none;
    background-color: ${theme.venue.input.background};
    color: ${theme.venue.colors.text};
    caret-color: ${theme.venue.colors.text};
  }

  .Toastify__toast-container--top-left {
    width: calc(100vw - 24.375rem);
  }

  .Toastify__toast {
    background-color: ${theme.venue.colors.background};
  }

  .Toastify__progress-bar--default {
    background: ${theme.venue.colors.primary};
  }

  .toast-container.notification-progress-bar {
    background: ${theme.venue.colors.dark};
  }

  .toast-container.danger-progress-bar {
    background: ${theme.venue.colors.negative};
  }

  .linkified {
    color: var(--theme-primary);
  }

  .custom-toggle {
    transform: scale(0.5);
  }

  /* Hide arrows from input type=number */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  img {
    user-select: none;
  }
`;
