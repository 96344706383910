type Action = () => Promise<unknown>;

export interface QueuedAction {
  action: Action;
  onFailure: () => void;
}

interface DisconnectionServiceStore {
  queuedActions: Array<QueuedAction>;
  isDisconnected: boolean;
}

const store: DisconnectionServiceStore = {
  queuedActions: [],
  isDisconnected: false,
};

export const queueAction = ({ action, onFailure = () => {} }: QueuedAction) => {
  if (store.isDisconnected) {
    store.queuedActions.push({ action, onFailure });
  } else {
    // In case race conditions cause the state to already be reconnected
    // by the time we queue an action that previously failed
    action().catch(() => onFailure());
  }
};

export const onReconnected = () => {
  store.queuedActions.forEach((queuedAction) =>
    queuedAction.action().catch(() => queuedAction.onFailure())
  );
  store.queuedActions = [];
};
