import GlobalRouter from '@venue/pages/GlobalRouter';
import { store } from '@venue/store';
import firebase from 'firebase/compat/app';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { TranslatedApp } from './TranslatedApp';

const helmetContext = {};

const rrfConfig = {};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export const App = () => (
  <Provider store={store}>
    <HelmetProvider context={helmetContext}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <TranslatedApp>
          <GlobalRouter />
        </TranslatedApp>
      </ReactReduxFirebaseProvider>
    </HelmetProvider>
  </Provider>
);
