import { setLocale } from '@venue/store/device/actions';
import {
  hasDetectedLocaleSelector,
  localeSelector,
} from '@venue/store/device/selectors';
import {
  AvailableLocales,
  DEFAULT_LANGUAGE,
  ENGLISH,
  FRENCH,
  LocaleCode,
  PORTUGUESE,
} from '@venue/store/device/types';
import { ReactNode, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export const TranslatedApp = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);
  const hasDetectedLocale = useSelector(hasDetectedLocaleSelector);
  const messages = useMemo(() => {
    // I tried to put these file paths in AvailableLocales data but Webpack does
    // not like that, resulting in: "Critical dependency: the request of a
    // dependency is an expression"
    switch (locale) {
      case FRENCH:
        return require('@venue/compiled-lang/fr-CA.json');
      case PORTUGUESE:
        return require('@venue/compiled-lang/pt-BR.json');
      case ENGLISH:
      default:
        return require('@venue/compiled-lang/en-CA.json');
    }
  }, [locale]);

  useEffect(() => {
    if (!hasDetectedLocale || !AvailableLocales.allIds.includes(locale)) {
      dispatch(setLocale(detectLocale()));
    }
  }, [locale]);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

const detectLocale = (): LocaleCode => {
  for (let i = 0; i < navigator.languages.length; i++) {
    const match = supportedLocale(navigator.languages[i]);
    if (match != null) {
      return match;
    }
  }

  return DEFAULT_LANGUAGE;
};

const supportedLocale = (language: string): LocaleCode => {
  if (AvailableLocales.allIds.includes(language)) {
    return language as LocaleCode;
  }

  const id = AvailableLocales.allIds.find((id) =>
    language.startsWith(AvailableLocales.byId[id].prefix)
  );

  if (id) {
    return AvailableLocales.byId[id].localeCode;
  }

  return DEFAULT_LANGUAGE;
};
