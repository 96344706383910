import styled from '@venue/common/styled';
import { NormalizedObjects } from '@venue/types/redux';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Card, TextWrapper } from './atoms/Card';

export interface UnsupportedBrowserProps {}

interface BrowserData {
  icon: string;
  name: string;
  description: {
    defaultMessage: string;
    description: string;
  };
}

const browserData: NormalizedObjects<BrowserData> = {
  allIds: ['chrome', 'safari', 'firefox', 'edge'],
  byId: {
    chrome: {
      icon: '/images/browsers/chrome.png',
      name: 'Chrome',
      description: defineMessage({
        defaultMessage: 'Latest version (includes Android)',
        description: 'chrome support',
      }),
    },
    safari: {
      icon: '/images/browsers/safari.png',
      name: 'Safari',
      description: defineMessage({
        defaultMessage: 'Latest version (includes iOS)',
        description: 'safari support',
      }),
    },
    firefox: {
      icon: '/images/browsers/firefox.png',
      name: 'Firefox',
      description: defineMessage({
        defaultMessage: 'Latest version',
        description: 'firefox support',
      }),
    },
    edge: {
      icon: '/images/browsers/edge.png',
      name: 'Edge',
      description: defineMessage({
        defaultMessage: 'Latest version',
        description: 'edge support',
      }),
    },
  },
};

export const UnsupportedBrowser = () => {
  const intl = useIntl();

  return (
    <Card title='Upgrade your browser'>
      <TextWrapper>
        <Description>
          <FormattedMessage defaultMessage='Venue is built with modern web technologies to make it faster, more secure, and easier to use. For the best experience, we recommend you use one of the following browsers to join this event.' />
        </Description>
        <Grid>
          {browserData.allIds
            .map((id) => browserData.byId[id])
            .map((browser) => (
              <Box key={browser.name}>
                <img
                  alt={browser.name}
                  src={browser.icon}
                  width={55}
                  height={55}
                />
                <BrowserName>{browser.name}</BrowserName>
                <BrowserDescription>
                  {intl.formatMessage(browser.description)}
                </BrowserDescription>
              </Box>
            ))}
        </Grid>
        <Instructions>
          <FormattedMessage defaultMessage='Please download one of the suggested browsers before joining the event.' />
        </Instructions>
      </TextWrapper>
    </Card>
  );
};

const Description = styled.div`
  margin-bottom: ${(props) => props.theme.venue.margin.m2};
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 35%;
  justify-content: center;
  align-items: center;
  border: ${(props) => `0.0625rem solid ${props.theme.venue.colors.muted}`};
  border-radius: 0.625rem;
  height: 8.125rem;
  padding: ${(props) => props.theme.venue.padding.p2};
`;

const BrowserName = styled.div`
  font-weight: ${(props) => props.theme.venue.fontWeights.bold};
`;

const BrowserDescription = styled.div`
  color: ${(props) => props.theme.venue.colors.gray};
`;

const Instructions = styled.div`
  margin-top: ${(props) => props.theme.venue.margin.m4};
  font-weight: ${(props) => props.theme.venue.fontWeights.bold};
  font-size: ${(props) => props.theme.venue.fontSize.xl3};
`;
