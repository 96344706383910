export interface VodState {
  readonly vodOrganizationId: string;
  readonly channels: Array<Channel>;
}

export enum VodActionTypes {
  SET_VOD_ORGANIZATION_ID = '[Dashboard VOD] Set VOD Organization Id',
  SET_CHANNELS = '[Dashboard VOD] Set Channels',
}

export interface SetVodOrganizationIdAction {
  readonly type: typeof VodActionTypes.SET_VOD_ORGANIZATION_ID;
  readonly vodOrganizationId: string;
}

export interface SetChannelsAction {
  readonly type: typeof VodActionTypes.SET_CHANNELS;
  readonly channels: Array<Channel>;
}

export type VodActions = SetVodOrganizationIdAction | SetChannelsAction;

export interface Channel {
  id: string;
  name: string;
}
