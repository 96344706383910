import { RtcService } from '@venue/services/rtc/rtc.service';

export interface RtcContextValue {
  audienceGalleryRtcService: RtcService | null;
  networkingRtcService: RtcService | null;
  previewRtcService: RtcService | null;
  stageRtcService: RtcService | null;
}

const rtcContextValue: RtcContextValue = {
  audienceGalleryRtcService: null,
  networkingRtcService: null,
  previewRtcService: null,
  stageRtcService: null,
};

export const getRtc = () => rtcContextValue;
