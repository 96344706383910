import {
  createDefaultRoomFeatures,
  RoomFeatures,
} from '@venue/store/feature/types';
import { FirestoreRoom } from '@venue/types/firestore';
import { StageState } from '@venue/types/stage';
import { Tab, TabValue } from '@venue/types/tab';
import produce from 'immer';
import { Reducer } from 'redux';
import { AuthActions, AuthActionTypes } from '../auth/types';
import { UsersActions, UsersActionTypes } from '../users/types';
import { mapTabValueToTab } from './data';
import { NavActions, NavActionTypes, NavState } from './types';

export const initialState: NavState = {
  currentTab: null,
  nextTabs: [],
  attendeeTabs: [],
  organizerTabs: [],
  tabsByValue: mapTabValueToTab,
};

export const nav: Reducer<NavState> = (
  state: NavState = initialState,
  action: NavActions | UsersActions | AuthActions
) => {
  switch (action.type) {
    case NavActionTypes.SET_CURRENT_TAB:
      if (state.currentTab === action.tab) {
        return {
          ...state,
          currentTab: null,
        };
      }
      return {
        ...state,
        currentTab: action.tab,
      };
    case NavActionTypes.REFRESH_TABS:
      const visibleTabs = Object.values(TabValue).filter((tabValue) =>
        isTabVisible({
          tab: state.tabsByValue[tabValue] || null,
          state: action.state,
          substate: action.substate,
          isAdmin: action.isAdmin,
          isOrganizer: action.isOrganizer,
          isRtcEnabled: action.isRtcEnabled,
          isSpeaker: action.isSpeaker,
          isMobile: action.isMobile,
          currentRoom: action.currentRoom,
        })
      );

      /*
       * Some tabs are grouped in a single tab. Examples: Chat & Backstage,
       * Speaker Queue & Speaker Groups, Music & Sound Effects.
       * Deduplicate tabs using the reference value and not the key. e.g.
       *  ```
       *  [TabValue.Backstage]: {
       *    value: TabValue.Chat, <-- refers to parent tab value
       *  }
       *  ```
       *  If one of the tabs are shown, the parent tab should be shown.
       **/
      const groupedTabs = visibleTabs
        .map((tabValue) => state.tabsByValue[tabValue]?.value)
        .filter((item): item is TabValue => !!item)
        .filter((value, index, self) => self.indexOf(value) === index);

      const organizerTabs = groupedTabs.filter(
        (tab) => state.tabsByValue[tab]?.isOrganizer
      );

      const attendeeTabs = groupedTabs.filter(
        (tab) => !state.tabsByValue[tab]?.isOrganizer
      );

      const defaultTab = (() => {
        return action.isOrganizer || action.isSpeaker
          ? TabValue.Backstage
          : TabValue.Chat;
      })();
      const currentTab = (() => {
        if (action.isMobile) {
          return null;
        } else if (
          state.currentTab === null ||
          organizerTabs.includes(state.currentTab) ||
          attendeeTabs.includes(state.currentTab)
        ) {
          return state.currentTab;
        } else if (
          organizerTabs.includes(defaultTab) ||
          attendeeTabs.includes(defaultTab)
        ) {
          return defaultTab;
        } else {
          return null;
        }
      })();

      return {
        ...state,
        attendeeTabs,
        organizerTabs,
        currentTab,
      };
    case NavActionTypes.PREVIOUS_TAB:
      return produce(state, (draftState) => {
        if (!state.currentTab) {
          return;
        }

        const allTabs = state.attendeeTabs.concat(state.organizerTabs);
        const indexOfCurrentTab = allTabs.indexOf(state.currentTab);

        const previousIndex =
          indexOfCurrentTab > 0 ? indexOfCurrentTab : allTabs.length;
        draftState.currentTab = allTabs[previousIndex - 1];
      });
    case NavActionTypes.NEXT_TAB:
      return produce(state, (draftState) => {
        if (!state.currentTab) {
          return;
        }

        const allTabs = state.attendeeTabs.concat(state.organizerTabs);
        const indexOfCurrentTab = allTabs.indexOf(state.currentTab);

        const nextIndex =
          indexOfCurrentTab === allTabs.length - 1 ? -1 : indexOfCurrentTab;
        draftState.currentTab = allTabs[nextIndex + 1];
      });
    case NavActionTypes.REPLACE_ICON:
      return produce(state, (draftState) => {
        const targetTab = draftState.tabsByValue[action.tab];
        if (targetTab) {
          targetTab.icon = action.icon;
        }
      });
    case NavActionTypes.RESTORE_TAB:
      return produce(state, (draftState) => {
        draftState.currentTab = draftState.nextTabs.pop() || null;
      });
    case UsersActionTypes.SELECT_USER:
      return {
        ...state,
        currentTab: TabValue.People,
      };
    case AuthActionTypes.SET_CURRENT_USER:
      if (!action.user.profileImage) {
        return {
          ...state,
          currentTab: TabValue.Profile,
        };
      }

      return state;
    default:
      return state;
  }
};

const isTabVisible = ({
  tab,
  state,
  substate,
  isAdmin,
  isOrganizer,
  isRtcEnabled,
  isSpeaker,
  isMobile,
  currentRoom,
}: {
  tab: Tab | null;
  state: StageState;
  substate: string | null;
  isAdmin: boolean;
  isOrganizer: boolean;
  isRtcEnabled: boolean;
  isSpeaker: boolean;
  isMobile: boolean;
  currentRoom: FirestoreRoom;
}) => {
  if (!tab) {
    return false;
  }

  const isPreEvent = state === StageState.PreEvent;
  const isMainStage = state === StageState.MainStage;
  const isBackstage = state === StageState.Backstage;
  const isEnded = state === StageState.Ended;
  const isBreakoutRoom =
    [StageState.Breakout, StageState.RoomGroup].includes(state) && !!substate;

  const roomFeatures: RoomFeatures =
    currentRoom === undefined ? createDefaultRoomFeatures() : currentRoom;
  const isRoomFeatureEnabled =
    tab.roomFeature !== undefined ? roomFeatures[tab.roomFeature] : true;

  return (
    isRoomFeatureEnabled &&
    canAccessTab({
      isPreEvent,
      isMainStage,
      isBackstage,
      isEnded,
      isBreakoutRoom,
      isAdmin,
      isOrganizer,
      isRtcEnabled,
      isSpeaker,
      isMobile,
      tab,
    })
  );
};

const canAccessTab = ({
  isPreEvent,
  isMainStage,
  isBackstage,
  isEnded,
  isBreakoutRoom,
  isAdmin,
  isOrganizer,
  isRtcEnabled,
  isSpeaker,
  isMobile,
  tab,
}: {
  isPreEvent: boolean;
  isMainStage: boolean;
  isBackstage: boolean;
  isEnded: boolean;
  isBreakoutRoom: boolean;
  isAdmin: boolean;
  isOrganizer: boolean;
  isRtcEnabled: boolean;
  isSpeaker: boolean;
  isMobile: boolean;
  tab: Tab;
}) => {
  switch (tab.value) {
    case TabValue.EventSettings:
      return isOrganizer && !isMobile;
    case TabValue.Agenda:
      return !isEnded;
    case TabValue.Chat:
      return (
        (!tab.isOrganizer && !isEnded) ||
        (tab.isOrganizer && (isOrganizer || isSpeaker))
      );
    case TabValue.Help:
      return !(isPreEvent || isEnded || isMobile);
    case TabValue.Questions:
      return !isEnded;
    case TabValue.Polls:
      return !(isPreEvent || isEnded);
    case TabValue.QualitativeFeedback:
      return isOrganizer && isEnded;
    case TabValue.SpeakerQueue:
      return (
        isOrganizer &&
        (isMainStage || isBreakoutRoom || isBackstage) &&
        isRtcEnabled &&
        !isMobile
      );
    case TabValue.Profile:
      return !isEnded;
    case TabValue.Activities:
      return isAdmin && !isMobile;
    case TabValue.Reactions:
      return isOrganizer && (isMainStage || isBreakoutRoom) && !isMobile;
    case TabValue.CustomTheme:
      return isOrganizer && !isMobile;
    case TabValue.Share:
      return (
        (isOrganizer || isSpeaker) &&
        (isMainStage || isBreakoutRoom || isBackstage) &&
        !isMobile
      );
    case TabValue.StageLayout:
      return (
        (isOrganizer || isSpeaker) &&
        (isMainStage || isBreakoutRoom || isBackstage) &&
        isRtcEnabled &&
        !isMobile
      );
    case TabValue.Music:
      return isOrganizer && !isBackstage && !isMobile;
    case TabValue.Actions:
      return isOrganizer && !isBackstage && !isMobile;
    case TabValue.Spaces:
      return isOrganizer && !isMobile;
    default:
      return false;
  }
};
