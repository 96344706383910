import { LocalStreamState } from '@venue/types/agora';
import { RecordingStatus } from '@venue/types/recording';
import { ScreenShareStatus } from '@venue/types/stage';

export enum StreamType {
  LocalCamera = 'Local Camera Stream',
  RemoteCamera = 'Remote Camera Stream',
  LocalScreen = 'Local Screen Stream',
  RemoteScreen = 'Remote Screen Stream',
}

export interface StreamsState {
  // for now, we are explicitly storing different types of streams as separate attributes
  // in the future, they will be in a generic "streams" collection
  // this is because cleanup will be easier if we restrict
  // the number of local and screenshare streams to 1
  readonly clientId: string;
  readonly localStreamId: string;
  readonly remoteStreamIds: ReadonlyArray<string>;

  readonly screenShareClientId: string;
  readonly localScreenStreamId: string;
  readonly remoteScreenStreamId: string;

  readonly localStreamState: LocalStreamState;
  readonly screenShareStatus: ScreenShareStatus;
  readonly audioMutedMap: Record<string, boolean>;
  readonly videoMutedMap: Record<string, boolean>;
  readonly disconnectedMap: Record<string, boolean>;
  readonly recordingStatuses: Array<RecordingStatus>;
  readonly channelName: string;
}

export enum StreamsActionTypes {
  CLEAR_STREAMS = '[Streams] Clear Streams',
  DESTROY_LOCAL_STREAM = '[Streams] Destroy Local Stream',
  REMOVE_STREAM = '[Streams] Remove Stream',
  RESET_RTC = '[Streams] Reset RTC',
  SET_AUDIO_MUTED_MAP = '[Streams] Set Audio Muted Map',
  SET_CHANNEL_NAME = '[Streams] Set Channel Name',
  SET_DISCONNECTED_MAP = '[Streams] Set Disconnected Map',
  SET_LOCAL_STREAM_STATE = '[Stage] Set Local Stream State',
  SET_RECORDING_STATUSES = '[Streams] Set Recording Statuses',
  SET_SCREEN_SHARE_AGORA_CLIENT = '[Streams] Set Screen Share Agora Client',
  SET_SCREEN_SHARE_STATUS = '[Stage] Set Screen Share Status',
  SET_STREAM_IDS = '[Streams] Set Streams',
  SET_VIDEO_MUTED_MAP = '[Streams] Set Video Muted Map',
}

export interface ClearStreamsAction {
  readonly type: typeof StreamsActionTypes.CLEAR_STREAMS;
}

export interface DestroyLocalStreamAction {
  readonly type: typeof StreamsActionTypes.DESTROY_LOCAL_STREAM;
  readonly streamId: string;
}

export interface RemoveStreamAction {
  readonly type: typeof StreamsActionTypes.REMOVE_STREAM;
  readonly streamId: string;
}

export interface ResetRtcAction {
  readonly type: typeof StreamsActionTypes.RESET_RTC;
}

interface SetScreenShareAgoraClientAction {
  readonly type: typeof StreamsActionTypes.SET_SCREEN_SHARE_AGORA_CLIENT;
  readonly clientId: string;
}

export interface SetLocalStreamStateAction {
  readonly type: typeof StreamsActionTypes.SET_LOCAL_STREAM_STATE;
  readonly state: LocalStreamState;
}

export interface SetAudioMutedMapAction {
  readonly type: typeof StreamsActionTypes.SET_AUDIO_MUTED_MAP;
  readonly streamId: string;
  readonly isMuted: boolean;
}

export interface SetVideoMutedMapAction {
  readonly type: typeof StreamsActionTypes.SET_VIDEO_MUTED_MAP;
  readonly streamId: string;
  readonly isMuted: boolean;
}

export interface SetDisconnectedMapAction {
  readonly type: typeof StreamsActionTypes.SET_DISCONNECTED_MAP;
  readonly disconnectedMap: Record<string, boolean>;
}

export interface SetScreenShareStatusAction {
  readonly type: typeof StreamsActionTypes.SET_SCREEN_SHARE_STATUS;
  readonly status: ScreenShareStatus;
}

export interface SetStreamIdsAction {
  readonly type: typeof StreamsActionTypes.SET_STREAM_IDS;
  readonly localStreamId: string;
  readonly remoteStreamIds: Array<string>;
  readonly localScreenStreamId: string;
  readonly remoteScreenStreamId: string;
}

export interface SetRecordingStatusesAction {
  readonly type: typeof StreamsActionTypes.SET_RECORDING_STATUSES;
  readonly statuses: Array<RecordingStatus>;
}

export interface SetChannelName {
  readonly type: typeof StreamsActionTypes.SET_CHANNEL_NAME;
  readonly channelName: string;
}

export type StreamsActions =
  | ClearStreamsAction
  | DestroyLocalStreamAction
  | RemoveStreamAction
  | ResetRtcAction
  | SetAudioMutedMapAction
  | SetChannelName
  | SetDisconnectedMapAction
  | SetLocalStreamStateAction
  | SetRecordingStatusesAction
  | SetScreenShareAgoraClientAction
  | SetScreenShareStatusAction
  | SetScreenShareStatusAction
  | SetStreamIdsAction
  | SetVideoMutedMapAction;
