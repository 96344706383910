export enum RequestStatus {
  Unknown = 'UNKNOWN',
  Requested = 'REQUESTED',
  Allowed = 'ALLOWED',
  Blocked = 'BLOCKED',
}

export enum DeviceTypes {
  AudioInput = 'audioinput',
  VideoInput = 'videoinput',
  AudioOutput = 'audiooutput',
}
