import { trackEvent } from '@common/services/posthog.service';
import toast from 'react-hot-toast';
import { fetchExistenceOfPriorQuantitativeFeedback } from './api';
import { PresenterFeedbackToast } from './PresenterFeedbackToast';

export async function maybeRequestPresenterFeedback({
  isFeatureEnabled,
  isDuringEventHours,
}: {
  isFeatureEnabled: boolean;
  isDuringEventHours: boolean;
}) {
  if (!isFeatureEnabled) {
    // Clients who don't want the presenter feedback form can opt-out.
    return;
  }

  if (!isDuringEventHours) {
    // Clients who conduct dry-runs or enter events after they've concluded, will not see the presenter feedback form.
    return;
  }

  const canGiveFeedback = await fetchExistenceOfPriorQuantitativeFeedback();
  if (!canGiveFeedback) {
    // Clients who already submitted feedback will not see the form again in the same event.
    return;
  }

  trackEvent({
    name: 'Presenter Quantitative Feedback::Show Venue Experience',
    attributes: {},
  });
  toast.custom(PresenterFeedbackToast, {
    id: 'presenter-feedback',
    duration: Infinity,
    position: 'bottom-left',
  });
}
