import { AuthUser, User } from '@venue/types/user';

export interface AuthState {
  readonly currentUser: { attributes: AuthUser };
}

export enum AuthActionTypes {
  SET_CURRENT_USER = '[User] Set Current User',
}

export interface SetCurrentUserAction {
  readonly type: typeof AuthActionTypes.SET_CURRENT_USER;
  readonly user: User;
}

export type AuthActions = SetCurrentUserAction;
