export enum Tab {
  WorkspaceSettings = 'workspace-settings',
  Events = 'events',
  Users = 'users',
  Recordings = 'recordings',
  Analytics = 'analytics',
  Emojis = 'emojis',
}

export enum BuilderTab {
  Details = 'details',
  Guests = 'guests',
}

export enum WorkspaceSettingsTab {
  OrganizationSettings = 'settings',
  Team = 'team',
}
