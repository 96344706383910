import { UploadSnapshot } from '@common/types/upload_snapshot';

export interface UploadsState {
  readonly sharedContent: {
    readonly snapshot: UploadSnapshot | null;
    readonly progress: number;
  };
}

export enum UploadsActionTypes {
  SET_SHARED_CONTENT_SNAPSHOT = '[Uploads] Add New Shared Content Upload Snapshot',
  UPDATE_SHARED_CONTENT_PROGRESS = '[Uploads] Update Shared Content Upload Progress',
  RESET_SHARED_CONTENT_UPLOAD = '[Uploads] Reset Shared Content Upload',
}

export interface SetSharedContentSnapshotAction {
  readonly type: typeof UploadsActionTypes.SET_SHARED_CONTENT_SNAPSHOT;
  readonly payload: UploadSnapshot;
}

export interface UpdateSharedContentProgressAction {
  readonly type: typeof UploadsActionTypes.UPDATE_SHARED_CONTENT_PROGRESS;
  readonly payload: number;
}

export interface ResetSharedContentUploadAction {
  readonly type: typeof UploadsActionTypes.RESET_SHARED_CONTENT_UPLOAD;
}

export type UploadsActions =
  | SetSharedContentSnapshotAction
  | UpdateSharedContentProgressAction
  | ResetSharedContentUploadAction;
