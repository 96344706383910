import { auth as dashboardAuth } from '@dashboard/store/auth/reducer';
import { vod } from '@dashboard/store/vod/reducer';
import { shoutOutReducer } from '@venue/features/shoutOut/store/reducer';
import { firebaseReducer } from 'react-redux-firebase';
import { AnyAction, Reducer, ReducersMapObject } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { RouterState } from 'redux-first-history';
import { activityReducer } from './activity/reducer';
import { auth } from './auth/reducer';
import { autoplayReducer } from './autoplay/reducer';
import { device } from './device/reducer';
import { event } from './event/reducer';
import { informationModal } from './informationModal/reducer';
import { nav } from './nav/reducer';
import { networking } from './networking/reducer';
import { streams } from './streams/reducer';
import systemCheck from './systemCheck/reducer';
import { theme } from './theme/reducer';
import { uploads } from './uploads/reducer';
import { users } from './users/reducer';

const createRootState = (
  routerReducer: Reducer<RouterState, AnyAction>
): ReducersMapObject => ({
  activity: activityReducer,
  auth,
  autoplay: autoplayReducer,
  dashboardAuth,
  device,
  event,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  informationModal,
  nav,
  networking,
  router: routerReducer,
  shoutOut: shoutOutReducer,
  streams,
  systemCheck,
  theme,
  users,
  uploads,
  vod,
});

export default createRootState;
