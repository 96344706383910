export interface NetworkingState {
  readonly clientId: string;
  readonly localStreamId: string;
  readonly remoteStreamId: string;
  readonly isLocalAudioMuted: boolean;
  readonly isLocalVideoMuted: boolean;
  readonly isRemoteAudioMuted: boolean;
  readonly isRemoteVideoMuted: boolean;
}

export enum NetworkingActionTypes {
  SET_AGORA_CLIENT = '[Networking] Set Agora Client',
  SET_LOCAL_STREAM = '[Networking] Set Local Stream',
  PLAY_LOCAL_STREAM = '[Networking] Play Local Stream',
  SET_REMOTE_STREAM = '[Networking] Set Remote Stream',
  PLAY_REMOTE_STREAM = '[Networking] Play Remote Stream',
  SUBSCRIBE_REMOTE_STREAM = '[Networking] Subscribe Remote Stream',
  RESET_NETWORKING_FOR_NEXT_MATCH = '[Networking] Reset Networking For Next Match',
  RESET_LOCAL_NETWORKING = '[Networking] Reset Local Networking',
  RESET_REMOTE_NETWORKING = '[Networking] Reset Remote Networking',
  DESTROY_LOCAL_STREAM = '[Networking] Destroy Local Stream',

  MUTE_LOCAL_AUDIO = '[Networking] Mute Local Audio',
  UNMUTE_LOCAL_AUDIO = '[Networking] Unmute Local Audio',

  MUTE_LOCAL_VIDEO = '[Networking] Mute Local Video',
  UNMUTE_LOCAL_VIDEO = '[Networking] Unmute Local Video',

  MUTE_REMOTE_AUDIO = '[Networking] Mute Remote Audio',
  UNMUTE_REMOTE_AUDIO = '[Networking] Unmute Remote Audio',

  MUTE_REMOTE_VIDEO = '[Networking] Mute Remote Video',
  UNMUTE_REMOTE_VIDEO = '[Networking] Unmute Remote Video',

  SET_REMOTE_STREAM_PAUSED = '[Networking] Set Remote Stream Paused',
}

export interface SetAgoraClientAction {
  readonly type: typeof NetworkingActionTypes.SET_AGORA_CLIENT;
  readonly clientId: string;
}

export interface SetLocalStreamAction {
  readonly type: typeof NetworkingActionTypes.SET_LOCAL_STREAM;
  readonly streamId: string;
}

export interface PlayLocalStreamAction {
  readonly type: typeof NetworkingActionTypes.PLAY_LOCAL_STREAM;
  readonly domId: string;
}

export interface SetRemoteStreamAction {
  readonly type: typeof NetworkingActionTypes.SET_REMOTE_STREAM;
  readonly streamId: string | null;
}

export interface PlayRemoteStreamAction {
  readonly type: typeof NetworkingActionTypes.PLAY_REMOTE_STREAM;
  readonly domId: string;
}

export interface ResetNetworkingForNextMatchAction {
  readonly type: typeof NetworkingActionTypes.RESET_NETWORKING_FOR_NEXT_MATCH;
}

export interface ResetLocalNetworkingAction {
  readonly type: typeof NetworkingActionTypes.RESET_LOCAL_NETWORKING;
}

export interface ResetRemoteNetworkingAction {
  readonly type: typeof NetworkingActionTypes.RESET_REMOTE_NETWORKING;
}

export interface MuteLocalAudioAction {
  readonly type: typeof NetworkingActionTypes.MUTE_LOCAL_AUDIO;
}

export interface UnmuteLocalAudioAction {
  readonly type: typeof NetworkingActionTypes.UNMUTE_LOCAL_AUDIO;
}

export interface MuteLocalVideoAction {
  readonly type: typeof NetworkingActionTypes.MUTE_LOCAL_VIDEO;
}

export interface UnmuteLocalVideoAction {
  readonly type: typeof NetworkingActionTypes.UNMUTE_LOCAL_VIDEO;
}

export interface MuteRemoteAudioAction {
  readonly type: typeof NetworkingActionTypes.MUTE_REMOTE_AUDIO;
}

export interface UnmuteRemoteAudioAction {
  readonly type: typeof NetworkingActionTypes.UNMUTE_REMOTE_AUDIO;
}

export interface MuteRemoteVideoAction {
  readonly type: typeof NetworkingActionTypes.MUTE_REMOTE_VIDEO;
}

export interface UnmuteRemoteVideoAction {
  readonly type: typeof NetworkingActionTypes.UNMUTE_REMOTE_VIDEO;
}

export type NetworkingActions =
  | SetAgoraClientAction
  | SetLocalStreamAction
  | PlayLocalStreamAction
  | SetRemoteStreamAction
  | PlayRemoteStreamAction
  | ResetNetworkingForNextMatchAction
  | ResetLocalNetworkingAction
  | ResetRemoteNetworkingAction
  | MuteLocalAudioAction
  | UnmuteLocalAudioAction
  | MuteLocalVideoAction
  | UnmuteLocalVideoAction
  | MuteRemoteAudioAction
  | UnmuteRemoteAudioAction
  | MuteRemoteVideoAction
  | UnmuteRemoteVideoAction;
