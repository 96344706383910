import { Draft, produce } from 'immer';
import { Reducer } from 'redux';
import { AutoplayActions, AutoplayActionTypes, AutoplayState } from './types';

export const initialState: AutoplayState = {
  hasInteracted: false,
  blocked: false,
};

export const autoplayReducer: Reducer<AutoplayState> = produce(
  (draft: Draft<AutoplayState>, action: AutoplayActions) => {
    switch (action.type) {
      case AutoplayActionTypes.SET_BLOCKED:
        draft.blocked = action.blocked;
        break;
      case AutoplayActionTypes.INTERACT:
        draft.hasInteracted = true;
        break;
    }
  },
  initialState
);
