export type EventFeatureKey =
  | 'allowAnonymousQuestions'
  | 'showProfileHeadline'
  | 'showProfilePronouns'
  | 'showProfileTwitter'
  | 'showProfileLinkedin'
  | 'showProfileInstagram'
  | 'showProfileGithub'
  | 'showProfileDiscord'
  | 'showProfileTiktok'
  | 'showAttendeeBar'
  | 'showAudienceGallery'
  | 'showStageControlsOffStage'
  | 'showAgenda'
  | 'enableAllFullstory'
  | 'enableAgoraLogs'
  | 'enableRtc';

export type RoomFeatureKey =
  | 'showMessages'
  | 'showQuestions'
  | 'showPolls'
  | 'showHelp'
  | 'showAgenda'
  | 'showBackstage'
  | 'showMusic'
  | 'showJoinStage'
  | 'showAttendance'
  | 'useGifs';

export type EventFeatures = { [k in EventFeatureKey]?: boolean };

export type RoomFeatures = { [k in RoomFeatureKey]?: boolean };

export const createDefaultEventFeatures = (): EventFeatures => ({
  allowAnonymousQuestions: false,
  showAudienceGallery: true,
  showProfileHeadline: true,
  showProfilePronouns: true,
  showProfileTwitter: true,
  showProfileLinkedin: true,
  showProfileInstagram: true,
  showProfileGithub: false,
  showProfileDiscord: false,
  showProfileTiktok: false,
  showAttendeeBar: true,
  showStageControlsOffStage: true,
  enableAllFullstory: false,
  enableAgoraLogs: false,
  enableRtc: true,
});

export const createDefaultRoomFeatures = (): RoomFeatures => ({
  showMessages: true,
  showQuestions: true,
  showPolls: false,
  showHelp: false,
  showAgenda: false,
  showBackstage: true,
  showMusic: true,
  showJoinStage: false,
  showAttendance: true,
  useGifs: true,
});
