export type SystemCheckKind = 'audience-gallery' | 'stage' | 'speaker-queue';

export interface State {
  systemCheckKind: SystemCheckKind | null;
}

export enum ActionTypes {
  SET_SYSTEM_CHECK_KIND = '[System Check] Set System Check Kind',
}

export interface SetSystemCheckKindAction {
  readonly type: typeof ActionTypes.SET_SYSTEM_CHECK_KIND;
  readonly systemCheckKind: SystemCheckKind;
}
export type Actions = SetSystemCheckKindAction;
