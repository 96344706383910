import { getRtc } from '@venue/common/rtc';
import { getUserLocations } from '@venue/common/userLocations';
import { getUserProfiles } from '@venue/common/userProfiles';
import firebase from 'firebase/compat/app';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';
import createRootState from './reducers';
import { RootState } from './types';

export const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const middlewares = [
  thunk.withExtraArgument({
    getFirestore,
    getRtc,
    getUserProfiles,
    getUserLocations,
  }),
  routerMiddleware,
];

const configureStore = (initialState = {}) =>
  createStore(
    combineReducers<RootState>(createRootState(routerReducer)),
    initialState,
    composeWithDevTools(
      applyMiddleware(...middlewares),
      reduxFirestore(firebase)
    )
  );

export default configureStore;
