export enum LocalStreamState {
  Idle = 'idle',
  Creating = 'creating',
  Publishing = 'publishing',
  Republishing = 'republishing',
  Published = 'published',
}

export interface AgoraStateChange {
  current_state: LocalStreamState;
  new_state: LocalStreamState;
  type: 'AgoraStateChange';
  time?: number;
}

export interface AgoraStateError {
  current_state: LocalStreamState;
  action: string;
  type: 'AgoraStateError';
  time?: number;
}

export interface AgoraSdkError {
  code: string;
  type: 'AgoraSdkError';
  time?: number;
}

export interface RtcDisconnectedError {
  type: 'RtcDisconnectedError';
  time?: number;
}

export enum AgoraLogType {
  AgoraStateChange = 'AgoraStateChange',
  AgoraStateError = 'AgoraStateError',
  AgoraSdkError = 'AgoraSdkError',
  RtcDisconnectedError = 'RtcDisconnectedError',
}

type AgoraError = AgoraSdkError | AgoraStateError | RtcDisconnectedError;

export type AgoraLog = AgoraError | AgoraStateChange;

export const SCREEN_UID = '336699';
