import { RootState } from '../types';

// We want null values to default to true.
export const showEmojiCannonSelector = (state: RootState) =>
  state.device.showEmojiCannon !== false;

// We want null values to default to true.
export const showAudienceGallerySelector = (state: RootState) =>
  state.device.showAudienceGallery !== false;

export const deviceSelector = (state: RootState) => state.device;

export const localeSelector = (state: RootState) => state.device.locale;

export const requestStatusSelector = (state: RootState) =>
  state.device.requestStatus;

export const cameraLabelSelector = (state: RootState) =>
  state.device.cameraLabel;
export const microphoneLabelSelector = (state: RootState) =>
  state.device.microphoneLabel;
export const speakersLabelSelector = (state: RootState) =>
  state.device.speakersLabel;

export const devicesSelector = (state: RootState) => state.device.devices;
export const cameraDevicesSelector = (state: RootState) =>
  state.device.devices?.filter((device) => device.kind === 'videoinput') ?? [];
export const microphoneDevicesSelector = (state: RootState) =>
  state.device.devices?.filter((device) => device.kind === 'audioinput') ?? [];
export const speakersDevicesSelector = (state: RootState) =>
  state.device.devices?.filter((device) => device.kind === 'audiooutput') ?? [];

export const hasDetectedLocaleSelector = (state: RootState) =>
  state.device.hasDetectedLocale;

export const breakpointSelector = (state: RootState) => state.device.breakpoint;

export const isDesktopSelector = (state: RootState) => state.device.isDesktop;

export const isMobileSelector = (state: RootState) => state.device.isMobile;

export const isMobilePortraitSelector = (state: RootState) =>
  state.device.isMobilePortrait;

export const isMobileLandscapeSelector = (state: RootState) =>
  state.device.isMobileLandscape;
