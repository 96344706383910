import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { UploadsActions, UploadsActionTypes, UploadsState } from './types';

const initialState: UploadsState = {
  sharedContent: {
    snapshot: null,
    progress: 0,
  },
};

export const uploads: Reducer<UploadsState> = produce(
  (draft: Draft<UploadsState>, action: UploadsActions) => {
    switch (action.type) {
      case UploadsActionTypes.SET_SHARED_CONTENT_SNAPSHOT:
        draft.sharedContent.snapshot = {
          ...draft.sharedContent.snapshot,
          ...action.payload,
        };
        break;
      case UploadsActionTypes.UPDATE_SHARED_CONTENT_PROGRESS:
        draft.sharedContent = {
          ...draft.sharedContent,
          progress: action.payload,
        };
        break;
      case UploadsActionTypes.RESET_SHARED_CONTENT_UPLOAD:
        draft.sharedContent = {
          ...draft.sharedContent,
          ...initialState.sharedContent,
        };
        break;
    }
  },
  initialState
);
