import { toApplicationError } from '@common/errorTypePredicates';
import { trackEvent } from '@common/services/posthog.service';
import { StageRtcServiceMethods } from '@common/services/posthog.types';
import { translateDeviceLabelToId } from '@venue/features/audioVideoDevices/audioVideoDevices';
import { maybeRequestPresenterFeedback } from '@venue/features/presenterQuantitativeFeedback/actions';
import { SHOW_PRESENTER_FEEDBACK_DURING_EVENT_OVERTIME_IN_MINUTES } from '@venue/features/presenterQuantitativeFeedback/constants';
import { isCurrentDateWithinDateRange } from '@venue/lib/time';
import { startDebugging, stopDebugging } from '@venue/services/agora.service';
import { createCommand } from '@venue/services/command.service';
import {
  RecordingResponse,
  startRecording as postStartRecording,
} from '@venue/services/recording.service';
import { createRtcService, RtcService } from '@venue/services/rtc/rtc.service';
import { RoomType, StreamNetworkQuality } from '@venue/services/rtc/types';
import { canRecordSelector } from '@venue/store/event/selectors';
import {
  AgoraLog,
  AgoraLogType,
  AgoraSdkError,
  AgoraStateChange,
  AgoraStateError,
  LocalStreamState,
  RtcDisconnectedError,
  SCREEN_UID,
} from '@venue/types/agora';
import { Command } from '@venue/types/commands';
import { DeviceTypes } from '@venue/types/device';
import { RtcStats } from '@venue/types/rtc_stats';
import { ScreenShareStatus, StageState } from '@venue/types/stage';
import { MediaType } from '@venue/types/stream';
import { ApplicationThunkAction } from '@venue/types/thunk';
import { formatFirestoreError } from '@venue/utils/firestoreError';
import parseISO from 'date-fns/parseISO';
import { trackError } from '../../../@common/services/rollbar.service';
import { speakersLabelSelector } from '../device/selectors';
import { featureFlagSelectorBoolean } from '../firestore/selectors';
import {
  ClearStreamsAction,
  DestroyLocalStreamAction,
  RemoveStreamAction,
  ResetRtcAction,
  SetAudioMutedMapAction,
  SetChannelName,
  SetDisconnectedMapAction,
  SetLocalStreamStateAction,
  SetRecordingStatusesAction,
  SetScreenShareStatusAction,
  SetStreamIdsAction,
  SetVideoMutedMapAction,
  StreamsActionTypes,
} from './types';

const AGORA_LOGGING_INTERVAL = 10000;

const trackIfStageServiceExists = (
  method: StageRtcServiceMethods,
  stageRtcService: RtcService | null
) => {
  if (!stageRtcService) {
    trackEvent({
      name: 'Empty Stage Rtc Service',
      attributes: { method },
    });
  }
};

const removeStream = (streamId: string): RemoveStreamAction => ({
  type: StreamsActionTypes.REMOVE_STREAM,
  streamId,
});

export const initializeClient =
  (eventId: string, roomId: string): ApplicationThunkAction =>
  async (dispatch, _, { getRtc }) => {
    const rtc = getRtc();
    const rtcService = await createRtcService({
      eventId,
      callbacks: {
        audioMutedUpdated: (streamId, isMuted) =>
          dispatch(setAudioMuted({ streamId, isMuted })),
        videoMutedUpdated: (streamId, isMuted) =>
          dispatch(setVideoMuted({ streamId, isMuted })),
        streamAdded: (streamId) => {
          dispatch(
            setDisconnected({
              streamId,
              isDisconnected: false,
            })
          );
          dispatch(updateStreamStates());
        },
        streamRemoved: (streamId) => {
          dispatch(removeStream(streamId));
          dispatch(updateStreamStates());
        },
        streamReconnected: (streamId) => {
          dispatch(
            setDisconnected({
              streamId,
              isDisconnected: false,
            })
          );
          dispatch(updateStreamStates());
        },
        streamDisconnected: (streamId) =>
          dispatch(
            setDisconnected({
              streamId,
              isDisconnected: true,
            })
          ),
        screenDestroyed: () => dispatch(updateStreamStates()),
        disconnected: () => dispatch(logDisconnection()),
      },
    });
    await rtcService.joinRoom({
      roomId,
      roomType: RoomType.Stage,
    });

    rtc.stageRtcService = rtcService;

    dispatch(setChannelName(rtcService.getChannelName()));
  };

const setChannelName = (channelName: string): SetChannelName => ({
  type: StreamsActionTypes.SET_CHANNEL_NAME,
  channelName,
});

const clearStreams = (): ClearStreamsAction => ({
  type: StreamsActionTypes.CLEAR_STREAMS,
});

const setStreamIds = (
  localStreamId: string,
  remoteStreamIds: Array<string>,
  localScreenStreamId: string | null,
  remoteScreenStreamId: string | null
): SetStreamIdsAction => ({
  type: StreamsActionTypes.SET_STREAM_IDS,
  localStreamId,
  remoteStreamIds,
  localScreenStreamId,
  remoteScreenStreamId,
});

const updateStreamStates =
  (): ApplicationThunkAction =>
  (dispatch, _, { getRtc }): void => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('updateStreamStates', stageRtcService);

    if (!stageRtcService) {
      dispatch(clearStreams());
      return;
    }

    dispatch(
      setStreamIds(
        stageRtcService.getLocalStreamId(),
        stageRtcService.getRemoteStreamIds(),
        stageRtcService.getLocalScreenId(),
        stageRtcService.getRemoteScreenId()
      )
    );
  };

export const createLocalStream =
  ({
    uid,
    cameraLabel,
    microphoneLabel,
  }: {
    uid: string;
    cameraLabel?: string;
    microphoneLabel?: string;
  }): ApplicationThunkAction<Promise<void>> =>
  async (dispatch, getState, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();
    const { localStreamState } = getState().streams;
    const agoraLogsEnabled =
      getState().firestore.data.currentEvent.enableAgoraLogs;
    const isRecordingUser = canRecordSelector(getState());

    trackIfStageServiceExists('createLocalStream', stageRtcService);

    if (localStreamState !== LocalStreamState.Idle) {
      const err: AgoraStateError = {
        type: 'AgoraStateError',
        current_state: localStreamState,
        action: 'createLocalStream',
      };
      dispatch(writeAgoraLog(err));
      throw err;
    }

    if (agoraLogsEnabled && !isRecordingUser) {
      startDebugging();
      setTimeout(() => stopDebugging(), AGORA_LOGGING_INTERVAL);
    }

    dispatch(transitionLocalStream(LocalStreamState.Creating));
    try {
      await stageRtcService.createStream({
        streamId: uid,
        cameraLabel,
        microphoneLabel,
      });
      trackEvent({
        name: 'Create Stream',
        attributes: {
          action_str: 'create',
          streamId_str: uid,
          cameraLabel_str: cameraLabel,
          microphoneLabel_str: microphoneLabel,
        },
      });
    } catch (unknownErr: unknown) {
      let err = toApplicationError(unknownErr);

      const sdkError: AgoraSdkError = {
        type: 'AgoraSdkError',
        code: err.message || null,
      };
      dispatch(transitionLocalStream(LocalStreamState.Idle));
      dispatch(writeAgoraLog(sdkError));
      throw sdkError;
    }
    dispatch(updateStreamStates());
    await dispatch(publishLocalStream());
    trackEvent({
      name: 'Create Stream',
      attributes: {
        action_str: 'publish',
        streamId_str: uid,
        cameraLabel_str: cameraLabel,
        microphoneLabel_str: microphoneLabel,
      },
    });
  };

const publishLocalStream =
  (): ApplicationThunkAction<Promise<void>> =>
  async (dispatch, getState, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();
    const { localStreamState } = getState().streams;

    trackIfStageServiceExists('publishLocalStream', stageRtcService);

    if (localStreamState !== LocalStreamState.Creating) {
      const err: AgoraStateError = {
        type: 'AgoraStateError',
        current_state: localStreamState,
        action: 'publishLocalStream',
      };
      dispatch(writeAgoraLog(err));
      throw err;
    }

    dispatch(transitionLocalStream(LocalStreamState.Publishing));

    try {
      await stageRtcService.publishStream();
      dispatch(streamPublished());
    } catch (err: unknown) {
      if (err instanceof Error) {
        const sdkError: AgoraSdkError = {
          type: 'AgoraSdkError',
          code: err.message || null,
        };
        dispatch(writeAgoraLog(sdkError));
        await dispatch(republishLocalStream());
      }
    }
  };

const republishLocalStream =
  (): ApplicationThunkAction<Promise<void>> =>
  async (dispatch, getState, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();
    const { localStreamState } = getState().streams;

    trackIfStageServiceExists('republishLocalStream', stageRtcService);

    if (localStreamState !== LocalStreamState.Publishing) {
      const err: AgoraStateError = {
        type: 'AgoraStateError',
        current_state: localStreamState,
        action: 'republishLocalStream',
      };
      dispatch(writeAgoraLog(err));
      throw err;
    }

    dispatch(transitionLocalStream(LocalStreamState.Republishing));

    try {
      await stageRtcService.publishStream();
      dispatch(streamPublished());
    } catch (err: unknown) {
      if (err instanceof Error) {
        const sdkError: AgoraSdkError = {
          type: 'AgoraSdkError',
          code: err.message || null,
        };

        dispatch(writeAgoraLog(sdkError));
        await dispatch(destroyLocalStreamThunk());
        throw sdkError;
      }
    }
  };

const destroyLocalStream = (streamId: string): DestroyLocalStreamAction => ({
  type: StreamsActionTypes.DESTROY_LOCAL_STREAM,
  streamId,
});

export const destroyLocalStreamThunk =
  (overrideStageState?: StageState): ApplicationThunkAction =>
  async (dispatch, getState, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('destroyLocalStream', stageRtcService);

    if (stageRtcService == null) {
      return;
    }

    const state = getState();
    const streamId = stageRtcService.getLocalStreamId();
    const eventState =
      overrideStageState || state.firestore.data.currentEvent?.state;
    await stageRtcService.destroyStream();
    trackEvent({
      name: 'Destroy Stream',
      attributes: {
        action_str: 'destroy',
        streamId_str: streamId,
      },
    });
    dispatch(updateStreamStates());
    dispatch(destroyLocalStream(streamId));

    if (state.event.systemCheckPassed && eventState === StageState.MainStage) {
      const eventStartDate = parseISO(state.event.event.starts_at_iso8601);
      const eventEndDate = parseISO(state.event.event.ends_at_iso8601);
      /**
       * We support placing users on the stage who may disconnect if they fail system checks,
       * or if they decline to give us hardware access. Skip asking for feedback if they never gave us access.
       **/
      maybeRequestPresenterFeedback({
        isFeatureEnabled: featureFlagSelectorBoolean('enablePresenterFeedback')(
          getState()
        ),
        isDuringEventHours: isCurrentDateWithinDateRange(
          eventStartDate,
          eventEndDate,
          {
            endDateDelayInMinutes:
              SHOW_PRESENTER_FEEDBACK_DURING_EVENT_OVERTIME_IN_MINUTES,
          }
        ),
      });
    }
  };

const streamPublished =
  (): ApplicationThunkAction =>
  (dispatch, getState): void => {
    const { localStreamState } = getState().streams;

    if (
      ![LocalStreamState.Publishing, LocalStreamState.Republishing].includes(
        localStreamState
      )
    ) {
      const err: AgoraStateError = {
        type: 'AgoraStateError',
        current_state: localStreamState,
        action: 'streamPublished',
      };
      dispatch(writeAgoraLog(err));
      throw err;
    }

    dispatch(transitionLocalStream(LocalStreamState.Published));
  };

const writeAgoraLog = (log: AgoraLog): ApplicationThunkAction => {
  return async (_1, getState, { getFirestore }) => {
    const { event } = getState().event;
    const userId = getState().auth.currentUser.attributes.id;
    try {
      return await getFirestore()
        .collection('events')
        .doc(event.id)
        .collection('agora_logs')
        .doc()
        .set({
          ...log,
          userId,
          time: Date.now(),
        });
    } catch (err) {
      const errorMessage = formatFirestoreError(
        'actions',
        'writeAgoraLog',
        err
      );
      console.log(errorMessage);
      throw Error(errorMessage);
    }
  };
};

const transitionLocalStream =
  (state: LocalStreamState): ApplicationThunkAction =>
  (dispatch, getState): void => {
    if (state === LocalStreamState.Published) {
      const { localStreamState } = getState().streams;
      const log: AgoraStateChange = {
        current_state: localStreamState,
        new_state: state,
        type: 'AgoraStateChange',
      };
      dispatch(writeAgoraLog(log));
    }

    dispatch(setLocalStreamState(state));
  };

const setLocalStreamState: (
  state: LocalStreamState
) => SetLocalStreamStateAction = (state) => ({
  type: StreamsActionTypes.SET_LOCAL_STREAM_STATE,
  state,
});

export const switchCamera =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('switchCamera', stageRtcService);

    if (!stageRtcService) {
      return Promise.resolve();
    }

    return stageRtcService.switchCamera(currentDeviceId);
  };

export const switchMicrophone =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('switchMicrophone', stageRtcService);

    if (!stageRtcService) {
      return Promise.resolve();
    }

    return stageRtcService.switchMicrophone(currentDeviceId);
  };

export const switchSpeakers =
  (currentDeviceId: string): ApplicationThunkAction =>
  (_1, _2, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('switchSpeakers', stageRtcService);

    if (!stageRtcService) {
      return Promise.resolve();
    }

    return stageRtcService.switchSpeakers(currentDeviceId);
  };

export const playStream =
  ({
    streamId,
    domId,
    mediaType,
  }: {
    streamId: string;
    domId: string;
    mediaType?: MediaType;
  }): ApplicationThunkAction<Promise<void>> =>
  async (_1, getState, { getRtc }) => {
    const { stageRtcService } = getRtc();
    const state = getState();
    const speakersLabel = speakersLabelSelector(state);

    trackIfStageServiceExists('playStream', stageRtcService);

    const playbackDeviceId = await translateDeviceLabelToId(
      speakersLabel,
      DeviceTypes.AudioOutput
    );
    await stageRtcService.playStream({
      streamId,
      domId,
      mediaType,
      playbackDeviceId,
    });
  };

export const destroyStream =
  (streamId: string): ApplicationThunkAction =>
  (dispatch, _, { getRtc }): void => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('destroyStream', stageRtcService);

    if (!stageRtcService) {
      return;
    }

    const localStreamId = stageRtcService.getLocalStreamId();
    const remoteStreamIds = stageRtcService.getRemoteStreamIds();
    const localScreenId = stageRtcService.getLocalScreenId();
    const remoteScreenId = stageRtcService.getRemoteScreenId();

    if (localStreamId === streamId) {
      dispatch(destroyLocalStreamThunk());
    } else if (localScreenId === streamId) {
      dispatch(destroyLocalScreenStream());
    } else if (remoteScreenId === streamId) {
      dispatch(
        sendCommand({ type: Command.KickSpeaker, streamId: SCREEN_UID })
      );
    } else if (remoteStreamIds.includes(streamId)) {
      dispatch(sendCommand({ type: Command.KickSpeaker, streamId }));
    }
  };

export const muteAudio =
  (streamId: string): ApplicationThunkAction =>
  (dispatch, getState, { getRtc }): void => {
    const { stageRtcService } = getRtc();
    const state = getState();

    trackIfStageServiceExists('muteAudio', stageRtcService);

    if (!stageRtcService) {
      return;
    }

    const localStreamId = stageRtcService.getLocalStreamId();
    const remoteStreamIds = stageRtcService.getRemoteStreamIds();

    if (localStreamId === streamId) {
      const isSuccess = stageRtcService.setAudioMuted(true);
      const isMuted = isSuccess ? true : state.streams.audioMutedMap[streamId];
      dispatch(setAudioMuted({ streamId, isMuted }));
    } else if (remoteStreamIds.includes(streamId)) {
      dispatch(sendCommand({ type: Command.MuteAudio, streamId }));
    }
  };

export const unmuteAudio =
  (streamId: string): ApplicationThunkAction =>
  (dispatch, getState, { getRtc }): void => {
    const { stageRtcService } = getRtc();
    const state = getState();

    trackIfStageServiceExists('unmuteAudio', stageRtcService);

    if (!stageRtcService) {
      return;
    }

    const localStreamId = stageRtcService.getLocalStreamId();
    const remoteStreamIds = stageRtcService.getRemoteStreamIds();

    if (localStreamId === streamId) {
      const isSuccess = stageRtcService.setAudioMuted(false);
      const isMuted = isSuccess ? false : state.streams.audioMutedMap[streamId];
      dispatch(setAudioMuted({ streamId, isMuted }));
    } else if (remoteStreamIds.includes(streamId)) {
      dispatch(sendCommand({ type: Command.UnmuteAudio, streamId }));
    }
  };

export const muteVideo =
  (streamId: string): ApplicationThunkAction =>
  (dispatch, getState, { getRtc }): void => {
    const { stageRtcService } = getRtc();
    const state = getState();

    trackIfStageServiceExists('muteVideo', stageRtcService);

    if (!stageRtcService) {
      return;
    }

    const localStreamId = stageRtcService.getLocalStreamId();
    const remoteStreamIds = stageRtcService.getRemoteStreamIds();

    if (localStreamId === streamId) {
      const isSuccess = stageRtcService.setVideoMuted(true);
      const isMuted = isSuccess ? true : state.streams.videoMutedMap[streamId];
      dispatch(setVideoMuted({ streamId, isMuted }));
    } else if (remoteStreamIds.includes(streamId)) {
      dispatch(sendCommand({ type: Command.MuteVideo, streamId }));
    }
  };

export const unmuteVideo =
  (streamId: string): ApplicationThunkAction =>
  (dispatch, getState, { getRtc }): void => {
    const { stageRtcService } = getRtc();
    const state = getState();

    trackIfStageServiceExists('unmuteVideo', stageRtcService);

    if (!stageRtcService) {
      return;
    }

    const localStreamId = stageRtcService.getLocalStreamId();
    const remoteStreamIds = stageRtcService.getRemoteStreamIds();

    if (localStreamId === streamId) {
      const isSuccess = stageRtcService.setVideoMuted(false);
      const isMuted = isSuccess ? false : state.streams.videoMutedMap[streamId];
      dispatch(setVideoMuted({ streamId, isMuted }));
    } else if (remoteStreamIds.includes(streamId)) {
      dispatch(sendCommand({ type: Command.UnmuteVideo, streamId }));
    }
  };

const setDisconnectedMap = (
  disconnectedMap: Record<number, boolean>
): SetDisconnectedMapAction => ({
  type: StreamsActionTypes.SET_DISCONNECTED_MAP,
  disconnectedMap,
});

const setAudioMuted = ({
  streamId,
  isMuted,
}: {
  streamId: string;
  isMuted: boolean;
}): SetAudioMutedMapAction => ({
  type: StreamsActionTypes.SET_AUDIO_MUTED_MAP,
  streamId,
  isMuted,
});

const setVideoMuted = ({
  streamId,
  isMuted,
}: {
  streamId: string;
  isMuted: boolean;
}): SetVideoMutedMapAction => ({
  type: StreamsActionTypes.SET_VIDEO_MUTED_MAP,
  streamId,
  isMuted,
});

const setDisconnected =
  ({
    streamId,
    isDisconnected,
  }: {
    streamId: string;
    isDisconnected: boolean;
  }): ApplicationThunkAction =>
  (dispatch, getState): void => {
    dispatch(
      setDisconnectedMap({
        ...getState().streams.disconnectedMap,
        [streamId]: isDisconnected,
      })
    );
  };

export const sendCommand =
  ({
    type,
    streamId,
  }: {
    type: Command;
    streamId: string;
  }): ApplicationThunkAction =>
  (_, getState): void => {
    const state = getState();
    const { id: eventId } = state.event.event;
    const eventState =
      state.event.organizerEventState ||
      state.firestore.data.currentEvent.state;
    const breakoutId = state.event.pageState;
    const stage =
      eventState === StageState.MainStage ? StageState.MainStage : breakoutId;

    createCommand({
      user: streamId.toString(),
      stage,
      eventId,
      type,
    });
  };

const setScreenShareStatus = (
  status: ScreenShareStatus
): SetScreenShareStatusAction => ({
  type: StreamsActionTypes.SET_SCREEN_SHARE_STATUS,
  status,
});

export const createScreenStream =
  (): ApplicationThunkAction<Promise<void>> =>
  async (dispatch, _, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('createScreenStream', stageRtcService);

    dispatch(setScreenShareStatus(ScreenShareStatus.LocallyBusy));
    try {
      await stageRtcService.createScreen();
    } catch (err: unknown) {
      await dispatch(destroyLocalScreenStream());
      trackError(new Error('Failed to start screen sharing stream.'), err);
      throw err;
    }

    dispatch(updateStreamStates());
    dispatch(startRecording());
  };

const startRecording =
  (): ApplicationThunkAction<Promise<SetRecordingStatusesAction>> =>
  async (dispatch, getState): Promise<SetRecordingStatusesAction> => {
    const eventId = getState().event.event.id;
    const breakoutRoomId = getState().event.pageState;

    const statuses = await postStartRecording({
      eventId,
      breakoutRoomId,
    });
    return dispatch(setRecordingStatuses(statuses));
  };

export const setRecordingStatuses = (
  statuses: RecordingResponse
): SetRecordingStatusesAction => ({
  type: StreamsActionTypes.SET_RECORDING_STATUSES,
  statuses,
});

export const destroyLocalScreenStream =
  (): ApplicationThunkAction =>
  async (dispatch, _, { getRtc }): Promise<void> => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('destroyLocalScreenStream', stageRtcService);

    await stageRtcService.destroyScreen();
    dispatch(updateStreamStates());
  };

export const getRtcStats =
  (): ApplicationThunkAction<RtcStats> =>
  (_1, _2, { getRtc }) => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('getRtcStats', stageRtcService);

    if (!stageRtcService) {
      return null;
    }

    return stageRtcService.getRtcStats();
  };

export const resetRtc = (): ResetRtcAction => ({
  type: StreamsActionTypes.RESET_RTC,
});

export const resetRtcThunk =
  (): ApplicationThunkAction =>
  async (dispatch, _, { getRtc }): Promise<void> => {
    const rtc = getRtc();
    await rtc.stageRtcService?.destroy();

    dispatch(updateStreamStates());
    rtc.stageRtcService = null;

    dispatch(resetRtc());
  };

export const getAudioLevel =
  (streamId: string): ApplicationThunkAction<number> =>
  (_1, _2, { getRtc }) => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('getAudioLevel', stageRtcService);

    if (!stageRtcService) {
      return 0;
    }

    return stageRtcService.getAudioLevel(streamId);
  };

export const getLocalNetworkQuality =
  (): ApplicationThunkAction<StreamNetworkQuality> =>
  (_1, _2, { getRtc }) => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('getLocalNetworkQuality', stageRtcService);

    if (!stageRtcService) {
      return null;
    }

    return stageRtcService.getLocalNetworkQuality();
  };

export const getRemoteNetworkQuality =
  (): ApplicationThunkAction =>
  (_1, _2, { getRtc }) => {
    const { stageRtcService } = getRtc();

    trackIfStageServiceExists('getRemoteNetworkQuality', stageRtcService);

    if (!stageRtcService) {
      return {};
    }

    return stageRtcService.getRemoteNetworkQuality();
  };

export const getNetworkQuality =
  (): ApplicationThunkAction<{ [x: string]: StreamNetworkQuality }> =>
  (_1, _2, { getRtc }) => {
    const { stageRtcService } = getRtc();

    if (!stageRtcService) {
      return {};
    }

    const uid = stageRtcService.getLocalStreamId();

    return {
      ...stageRtcService.getRemoteNetworkQuality(),
      [uid]: stageRtcService.getLocalNetworkQuality(),
    };
  };

const logDisconnection =
  (): ApplicationThunkAction =>
  (dispatch, _, { getRtc }): void => {
    const { stageRtcService } = getRtc();
    const isOnStage = !!stageRtcService?.getLocalStreamId();

    if (isOnStage) {
      window.Rollbar.warning('User disconnected from stage');
      dispatch(
        writeAgoraLog({
          type: AgoraLogType.RtcDisconnectedError,
        } as RtcDisconnectedError)
      );
    }
  };
