import { DeviceState } from './types';

export const DEVICE_PREFERENCES_KEY = 'device';

type ValidPreferences = Array<keyof DeviceState>;

export const PREFERENCES_TO_PERSIST: ValidPreferences = [
  'cameraLabel',
  'microphoneLabel',
  'speakersLabel',
  'locale',
  'showEmojiCannon',
  'showAudienceGallery',
];
