import { EventPermissions } from '@venue/store/event/types';
import { Event } from '@venue/types/event';
import { StageState } from '@venue/types/stage';
import axios from 'axios';
import { API_URL } from './venue';

interface EventResponse {
  event: Event;
  meta: { permissions: EventPermissions };
}

export const getEvent = (eventId: string) => {
  return axios.get<EventResponse>(`${API_URL}/events/${eventId}`);
};

export const getOrganizationEventUrl = (
  organizationSlug: string,
  eventSlug: string
) => `${API_URL}/organizations/${organizationSlug}/events/${eventSlug}`;

export const updateEventState = async ({
  eventShareableId,
  state,
}: {
  eventShareableId: string;
  state: StageState;
}) => {
  axios.put(`${API_URL}/events/${eventShareableId}`, { state });
};

export const updateEventNetworkingDuration = async (
  eventShareableId: string,
  networkingDuration: number
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    networking_duration: networkingDuration,
  });

export const updateEventThemeColors = async (
  eventShareableId: string,
  titleColor: string,
  themeColor: string
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    title_color: titleColor,
    theme_color: themeColor,
  });

export const updateEventHeader = async (
  eventShareableId: string,
  headerImage: string
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    header_image: headerImage,
  });

export const createSignInLink = async ({
  eventShareableId,
  email,
  name,
}: {
  eventShareableId: string;
  email: string;
  name: string;
}) =>
  axios.post(`${API_URL}/events/${eventShareableId}/sign_in_link`, {
    email,
    name,
  });

export const federatedGoogleLogin = async ({
  eventShareableId,
  idToken,
}: {
  eventShareableId: string;
  idToken: string;
}) =>
  axios.post<{ link: string }>(
    `${API_URL}/events/${eventShareableId}/federated_login`,
    {
      provider: 'google',
      id_token: idToken,
    }
  );

export const federatedOktaLogin = async ({
  eventShareableId,
  accessCode,
  codeVerifier,
  redirectUri,
}: {
  eventShareableId: string;
  accessCode: string;
  codeVerifier: string;
  redirectUri: string;
}) =>
  axios.post<{ link: string }>(
    `${API_URL}/events/${eventShareableId}/federated_login`,
    {
      provider: 'okta',
      access_code: accessCode,
      code_verifier: codeVerifier,
      redirect_uri: redirectUri,
    }
  );
