import { RequestStatus } from '@venue/types/device';
import { NormalizedObjects } from '@venue/types/redux';

export const ENGLISH = 'en-CA';
export const FRENCH = 'fr-CA';
export const PORTUGUESE = 'pt-BR';

export const DEFAULT_LANGUAGE = ENGLISH;

export type LocaleCode = typeof ENGLISH | typeof FRENCH | typeof PORTUGUESE;
interface AvailableLocale {
  localeCode: LocaleCode;
  prefix: string;
  label: string;
}

export const AvailableLocales: NormalizedObjects<AvailableLocale> = {
  allIds: [ENGLISH, FRENCH, PORTUGUESE],
  byId: {
    [ENGLISH]: {
      localeCode: ENGLISH,
      prefix: 'en',
      label: 'English',
    },
    [FRENCH]: {
      localeCode: FRENCH,
      prefix: 'fr',
      label: 'Française',
    },
    [PORTUGUESE]: {
      localeCode: PORTUGUESE,
      prefix: 'pt',
      label: 'Português do Brasil',
    },
  },
};

export type AvailableBreakpoint =
  | 'Desktop'
  | 'MobilePortrait'
  | 'MobileLandscape';

export interface DeviceState {
  readonly cameraLabel: string;
  readonly microphoneLabel: string;
  readonly speakersLabel: string;
  readonly devices: Array<MediaDeviceInfo>;
  readonly requestStatus: RequestStatus;
  readonly locale: LocaleCode;
  readonly hasDetectedLocale: boolean;
  readonly showEmojiCannon: boolean;
  readonly showAudienceGallery: boolean;
  readonly breakpoint: AvailableBreakpoint;
  readonly isDesktop: boolean;
  readonly isMobile: boolean;
  readonly isMobilePortrait: boolean;
  readonly isMobileLandscape: boolean;
}

export enum DeviceActionTypes {
  SET_BREAKPOINT = '[Device] Set Breakpoint',
  SET_CAMERA = '[Device] Set Camera',
  SET_DEVICES = '[Device] Set Devices',
  SET_LOCALE = '[Device] Set Locale',
  SET_MICROPHONE = '[Device] Set Microphone',
  SET_REQUEST_STATUS = '[Device] Set Request Status',
  SET_SPEAKERS = '[Device] Set Speakers',
  SHOW_AUDIENCE_GALLERY = '[Device] Show Audience Gallery',
  SHOW_EMOJI_CANNON = '[Device] Show Emoji Cannon',
}

export interface SetBreakpointAction {
  readonly type: typeof DeviceActionTypes.SET_BREAKPOINT;
  readonly breakpoint: AvailableBreakpoint;
}

export interface SetCameraAction {
  readonly type: typeof DeviceActionTypes.SET_CAMERA;
  readonly deviceLabel: string;
}

export interface SetMicrophoneAction {
  readonly type: typeof DeviceActionTypes.SET_MICROPHONE;
  readonly deviceLabel: string;
}

export interface SetSpeakersAction {
  readonly type: typeof DeviceActionTypes.SET_SPEAKERS;
  readonly deviceLabel: string;
}

export interface SetDevicesAction {
  readonly type: typeof DeviceActionTypes.SET_DEVICES;
  readonly devices: Array<MediaDeviceInfo>;
}

export interface SetRequestStatusAction {
  readonly type: typeof DeviceActionTypes.SET_REQUEST_STATUS;
  readonly requestStatus: RequestStatus;
}

export interface SetLocaleAction {
  readonly type: typeof DeviceActionTypes.SET_LOCALE;
  readonly locale: LocaleCode;
}

export interface ShowEmojiCannonAction {
  readonly type: typeof DeviceActionTypes.SHOW_EMOJI_CANNON;
  readonly show: boolean;
}

export interface ShowAudienceGalleryAction {
  readonly type: typeof DeviceActionTypes.SHOW_AUDIENCE_GALLERY;
  readonly show: boolean;
}

export type DeviceActions =
  | SetBreakpointAction
  | SetCameraAction
  | SetDevicesAction
  | SetLocaleAction
  | SetMicrophoneAction
  | SetRequestStatusAction
  | SetSpeakersAction
  | ShowAudienceGalleryAction
  | ShowEmojiCannonAction;
