import axios from 'axios';
import { detect } from 'detect-browser';
import DeviceDetector from 'device-detector-js';

// https://ip-api.com/docs/api:json
const GEOLOCATION_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://ip-api.com/json/?fields=57855'
    : 'https://pro.ip-api.com/json/?fields=57855&key=HcYzdO6siU6xkbQ';

interface LocationResponse {
  query: string;
  country: string;
  countryCode: string;
  regionName: string;
  region: string;
  city: string;
}

interface Location {
  ip: string;
  country: string;
  country_code: string;
  region_name: string;
  region: string;
  city: string;
}

export const browser = detect();

export const commandKey = browser?.os === 'Mac OS' ? '⌘' : 'Ctrl';

export const commandShortcut = (key: string) => `${commandKey} + ${key}`;

export const detectDevice = (): DeviceDetector.DeviceDetectorResult => {
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  return deviceDetector.parse(userAgent);
};

export const detectLocation = async (): Promise<Location> => {
  const response = await axios.get<LocationResponse>(GEOLOCATION_API_URL);
  return {
    ip: response.data.query,
    country: response.data.country,
    country_code: response.data.countryCode,
    region_name: response.data.regionName,
    region: response.data.region,
    city: response.data.city,
  };
};

export const isChrome = browser?.name === 'chrome'; // can be null within a test environment

export const isIe11 = (): boolean => {
  const device = detectDevice();

  if (device.client != null && device.client.name && device.client.version) {
    return (
      device.client.name === 'Internet Explorer' &&
      device.client.version === '11.0'
    );
  }

  return false;
};

/*
 * Platform is deprecated but replacement is not available in most browsers:
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
 */
export const isMacintosh = navigator.platform.indexOf('Mac') > -1;

export const supportedBrowserNames = ['chrome', 'safari', 'edge-chromium'];
