import { Reducer } from 'redux';
import { Actions, ActionTypes, State } from './types';

const initialState: State = {
  systemCheckKind: null,
};

const reducer: Reducer<State> = (
  state: State = initialState,
  action: Actions
) => {
  switch (action.type) {
    case ActionTypes.SET_SYSTEM_CHECK_KIND:
      return {
        ...state,
        systemCheckKind: action.systemCheckKind,
      };
    default:
      return state;
  }
};

export default reducer;
