export type LogLevel = 'debug' | 'warning' | 'error';

let currentLogLevel: LogLevel = 'debug';

export const setLogLevel = (logLevel: LogLevel) => {
  currentLogLevel = logLevel;
};

export const logDebug = (body: string, ...args: Array<unknown>) => {
  log('debug', body, ...args);
};

export const logWarning = (body: string, ...args: Array<unknown>) => {
  log('warning', body, ...args);
};

export const logError = (body: string, ...args: Array<unknown>) => {
  log('error', body, ...args);
};

const log = (logLevel: LogLevel, body: string, ...args: Array<unknown>) => {
  if (isLogLevelActive(logLevel)) {
    console.log(body, ...args);
  }
};

const isLogLevelActive = (logLevel: LogLevel) => {
  switch (logLevel) {
    case 'error':
      return true;
    case 'warning':
      return currentLogLevel !== 'debug';
    case 'debug':
      return currentLogLevel === 'debug';
    default:
      return false;
  }
};
