import { Subscription } from 'rxjs';

export interface UserLocationsContextValue {
  byListenerId: Map<string, { userId: string; subscription: Subscription }>;
  byUserId: Map<
    string,
    { listenerIds: Array<string>; unsubscriber: () => void }
  >;
}

export const userLocationsContextValue: UserLocationsContextValue = {
  byListenerId: new Map<
    string,
    { userId: string; subscription: Subscription }
  >(),
  byUserId: new Map<
    string,
    { listenerIds: Array<string>; unsubscriber: () => void }
  >(),
};

export const getUserLocations = () => userLocationsContextValue;
