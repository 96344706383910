import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { AuthActions, AuthActionTypes, AuthState } from './types';

const initialState: AuthState = {
  accountId: null,
  availableOrganizationMembers: null,
  currentOrganizationMember: null,
};

export const auth: Reducer<AuthState> = produce(
  (draft: Draft<AuthState>, action: AuthActions) => {
    switch (action.type) {
      case AuthActionTypes.LOGIN:
        draft.accountId = action.accountId;
        draft.availableOrganizationMembers = action.organizationMembers;
        break;

      case AuthActionTypes.LOGOUT:
        draft.availableOrganizationMembers = null;
        break;

      case AuthActionTypes.SET_CURRENT_ORGANIZATION_MEMBER:
        draft.currentOrganizationMember = action.currentOrganizationMember;
        break;
    }
  },
  initialState
);
