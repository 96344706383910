import { RoomType } from '@venue/services/rtc/types';
import axios from 'axios';
import { API_URL } from './venue';

export interface AgoraTokensResponse {
  camera_token: string;
  screen_token: string;
  channel_name: string;
  stream_id: string;
}

export const getAgoraTokens = ({
  eventId,
  roomId,
  roomType,
}: {
  eventId: string;
  roomId: string;
  roomType: RoomType;
}): Promise<AgoraTokensResponse> => {
  return axios
    .get(
      API_URL +
        `/events/${eventId}/rooms/${roomId}/agora_token?room_type=${roomType}`
    )
    .then((response) => response.data);
};
