export enum Adapter {
  AgoraNg = 'agora_ng',
}

export enum PlayFailureReason {
  Autoplay = 'autoplay',
  Other = 'other',
}

export enum VideoQuality {
  Potato = 'potato',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Ludicrous = 'ludicrous',
}

export enum NetworkQualityLevel {
  Disconnected = 'disconnected',
  Potato = 'potato',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Ludicrous = 'ludicrous',
}

export interface StreamNetworkQuality {
  downlinkNetworkQuality: NetworkQualityLevel;
  uplinkNetworkQuality: NetworkQualityLevel;
}

export type StreamsNetworkQuality = Record<string, StreamNetworkQuality>;

export enum RoomType {
  AudienceGallery = 'audience_gallery',
  Stage = 'stage',
  Preview = 'preview',
  Networking = 'networking',
  PublishTest = 'publish_test',
  SubscribeTest = 'subscribe_test',
  BackgroundPublishTest = 'background_publish_test',
  BackgroundSubscribeTest = 'background_subscribe_test',
}

export enum StreamType {
  Camera = 'Camera Stream',
  Screen = 'Screen Stream',
}
