const DEFAULT_COLOR = {
  red: 255,
  green: 255,
  blue: 255,
};

const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (_, red, green, blue) {
    return red + red + green + green + blue + blue;
  });

  var match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return match
    ? {
        red: parseInt(match[1], 16),
        green: parseInt(match[2], 16),
        blue: parseInt(match[3], 16),
      }
    : DEFAULT_COLOR;
};

const rgbToHsl = ({
  red,
  green,
  blue,
}: {
  red: number;
  green: number;
  blue: number;
}) => {
  const cmin = Math.min(red, green, blue);
  const cmax = Math.max(red, green, blue);
  const delta = cmax - cmin;
  let hue = 0;
  let saturation = 0;
  let lightness = 0;

  if (delta === 0) {
    hue = 0;
  } else if (cmax === red) {
    hue = ((green - blue) / delta) % 6;
  } else if (cmax === green) {
    hue = (blue - red) / delta + 2;
  } else {
    hue = (red - green) / delta + 4;
  }

  hue = Math.round(hue * 60);

  if (hue < 0) {
    hue += 360;
  }

  // Calculate lightness
  lightness = (cmax + cmin) / 2;

  // Calculate saturation
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // Multiply l and saturation by 100
  saturation = +(saturation * 100).toFixed(1);
  lightness = +(lightness * 100).toFixed(1);

  // hsl(' + hue + ',' + saturation + '%,' + lightness + '%)
  return {
    hue,
    saturation,
    lightness,
  };
};

export const hexToHsl = (hex: string) => rgbToHsl(hexToRgb(hex));
