import {
  browser,
  isIe11,
  supportedBrowserNames,
} from '@venue/services/device.service';
import { useMemo } from 'react';

interface BrowserSupportReturns {
  isBrowserSupported: boolean;
  isBrowserRecommended: boolean;
}

export const useBrowserSupport = (): BrowserSupportReturns => {
  const isBrowserSupported = useMemo(() => !isIe11(), []);
  const isBrowserRecommended = useMemo(
    () => browser && supportedBrowserNames.includes(browser.name),
    [browser, supportedBrowserNames]
  );

  return {
    isBrowserSupported,
    isBrowserRecommended,
  };
};
