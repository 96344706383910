import { Reducer } from 'redux';
import {
  InformationModalActions,
  InformationModalActionTypes,
  InformationModalState,
} from './types';

const initialState: InformationModalState = {
  modalType: null,
  title: null,
  description: null,
  image: null,
  buttonText: null,
};

export const informationModal: Reducer<InformationModalState> = (
  state: InformationModalState = initialState,
  action: InformationModalActions
) => {
  switch (action.type) {
    case InformationModalActionTypes.OPEN_INFORMATION_MODAL:
      return { ...state, ...action.informationModal };

    case InformationModalActionTypes.CLOSE_INFORMATION_MODAL:
      return { ...state, ...initialState };

    default:
      return state;
  }
};
