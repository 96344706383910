import { ColorScheme, darkTheme, lightTheme } from '@venue/common/theme';
import { hexToHsl } from '@venue/lib/color';
import { EventActions } from '@venue/store/event/types';
import produce, { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { Reducer } from 'redux';
import { ThemeActions, ThemeActionTypes, ThemeState } from './types';

const initialState: ThemeState = {
  theme: lightTheme,
  colorScheme: 'light',
  titleColor: null,
  themeColor: null,
  backgroundColor: null,
  registrationThemeColor: null,
  registrationAccentColor: null,
  registrationBackgroundColor: null,
};

export const theme: Reducer<ThemeState> = produce(
  (draft: Draft<ThemeState>, action: ThemeActions | EventActions) => {
    switch (action.type) {
      case ThemeActionTypes.SET_THEME:
        customTheme(
          draft,
          action.titleColor,
          action.themeColor,
          action.backgroundColor,
          action.registrationThemeColor,
          action.registrationAccentColor,
          action.registrationBackgroundColor,
          draft.colorScheme
        );
        break;
      case ThemeActionTypes.SET_COLOR_SCHEME:
        if (action.colorScheme === 'dark') {
          document.documentElement.classList.add('tw-dark');
        } else {
          document.documentElement.classList.remove('tw-dark');
        }
        customTheme(
          draft,
          draft.titleColor,
          draft.themeColor,
          draft.backgroundColor,
          draft.registrationThemeColor,
          draft.registrationAccentColor,
          draft.registrationBackgroundColor,
          action.colorScheme
        );
        break;
    }
  },
  initialState
);

// Hexadecimal colors allow for extra 2 digits expressing opacity.
// 5A hex = 90 decimal => 90 / 255 = ~35% opacity
const inactiveTransparency = '5A';
// 7f hex = 127 decimal => 127 / 255 = ~50% opacity
const disabledTransparency = '7f';

const customTheme = (
  draftState: WritableDraft<ThemeState>,
  titleColor: string,
  themeColor: string,
  backgroundColor: string,
  registrationThemeColor: string,
  registrationAccentColor: string,
  registrationBackgroundColor: string,
  colorScheme: ColorScheme
) => {
  draftState.colorScheme = colorScheme;
  switch (colorScheme) {
    case 'light':
      draftState.theme = JSON.parse(JSON.stringify(lightTheme));
      break;
    case 'dark':
      draftState.theme = JSON.parse(JSON.stringify(darkTheme));
      break;
  }

  if (titleColor != null) {
    draftState.titleColor = titleColor;
  }

  if (themeColor != null) {
    const colors = primaryColors(themeColor);

    // use lighter color for dark theme
    const primaryColor =
      colorScheme === 'light' ? themeColor : draftState.theme.venue.colors.n700;

    draftState.themeColor = themeColor;
    draftState.theme.venue.colors.primary = primaryColor;
    draftState.theme.venue.colors.p100 = colors.p100;
    draftState.theme.venue.colors.p200 = colors.p200;
    draftState.theme.venue.colors.p300 = colors.p300;
    draftState.theme.venue.colors.p400 = colors.p400;
    draftState.theme.venue.colors.p500 = colors.p500;
    draftState.theme.venue.colors.p600 = colors.p600;
    draftState.theme.venue.colors.p700 = colors.p700;
    draftState.theme.venue.colors.p800 = colors.p800;
    draftState.theme.venue.colors.p900 = colors.p900;

    // TODO: Extract hardcoded box shadow values.
    draftState.theme.venue.links.primary.color = primaryColor;
    draftState.theme.venue.buttons.primary.bg = primaryColor;
    draftState.theme.venue.buttons.primary.disabledBg = `${primaryColor}${disabledTransparency}`;
    draftState.theme.venue.buttons.primary.inactiveBg = `${primaryColor}${inactiveTransparency}`;
    draftState.theme.venue.buttons.secondary.color = primaryColor;
    draftState.theme.venue.buttons.secondary.disabledColor = `${primaryColor}${disabledTransparency}`;
    draftState.theme.venue.buttons.secondary.boxShadow = `${primaryColor} 0 0 0 0.125rem inset`;
    draftState.theme.venue.buttons.secondary.disabledBoxShadow = `${primaryColor}${disabledTransparency} 0 0 0 0.125rem inset`;
    draftState.theme.venue.buttons.tertiary.color = primaryColor;
  }

  if (backgroundColor != null) {
    draftState.backgroundColor = backgroundColor;
    draftState.theme.venue.colors.space.background = backgroundColor;
  }

  if (registrationThemeColor != null) {
    draftState.registrationThemeColor = registrationThemeColor;
  }

  if (registrationAccentColor != null) {
    draftState.registrationAccentColor = registrationAccentColor;
  }

  if (registrationBackgroundColor != null) {
    draftState.registrationBackgroundColor = registrationBackgroundColor;
  }
};

const primaryColors = (hex: string) => {
  const BASE_COLOR = hexToHsl(hex).hue;

  return {
    p100: `hsl(${BASE_COLOR - 9}, 80%, 90%)`,
    p200: `hsl(${BASE_COLOR - 8}, 76%, 80%)`,
    p300: `hsl(${BASE_COLOR - 4}, 72%, 67%)`,
    p400: `hsl(${BASE_COLOR - 2}, 64%, 52%)`,
    p500: `hsl(${BASE_COLOR + 0}, 84%, 39%)`,
    p600: `hsl(${BASE_COLOR + 1}, 94%, 30%)`,
    p700: `hsl(${BASE_COLOR + 3}, 94%, 24%)`,
    p800: `hsl(${BASE_COLOR + 3}, 88%, 20%)`,
    p900: `hsl(${BASE_COLOR + 4}, 86%, 16%)`,
  };
};
