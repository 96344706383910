export const ENV_NODE_ENV = process.env.NODE_ENV;
export const ENV_AGORA_APP_ID =
  process.env.REACT_APP_AGORA_APP_ID || '69056d2a4f3a43e48ac62d1409e49167';
export const ENV_API_URL = process.env.REACT_APP_API_URL || '';
export const ENV_APP_ENV = process.env.REACT_APP_APP_ENV || '';
export const ENV_CSVBOX_LICENSE_KEY =
  process.env.REACT_APP_CSVBOX_LICENSE_KEY || '';
export const ENV_DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL || 'http://localhost:5000/v2';
export const ENV_FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  'AIzaSyDxzD6B0A54SMhK80EgQIM4Z5U9XVV0238';
export const ENV_FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID ||
  '1:769946475531:web:0696be30a2fba42a8fbb0e';
export const ENV_FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  'tryvenue-staging.firebaseapp.com';
export const ENV_FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL ||
  'https://tryvenue-staging.firebaseio.com';
export const ENV_FIREBASE_EMULATOR =
  process.env.REACT_APP_FIREBASE_EMULATOR || '';
export const ENV_FIREBASE_EMULATOR_AUTH_PORT =
  process.env.REACT_APP_FIREBASE_EMULATOR_AUTH_PORT || '';
export const ENV_FIREBASE_EMULATOR_DATABASE_PORT =
  process.env.REACT_APP_FIREBASE_EMULATOR_DATABASE_PORT || '';
export const ENV_FIREBASE_EMULATOR_FIRESTORE_PORT =
  process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT || '';
export const ENV_FIREBASE_EMULATOR_FUNCTIONS_PORT =
  process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT || '';
export const ENV_FIREBASE_EMULATOR_STORAGE_PORT =
  process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT || '';
export const ENV_FIREBASE_FUNCTIONS =
  process.env.REACT_APP_FIREBASE_FUNCTIONS || '';
export const ENV_FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-723N6696WB';
export const ENV_FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '769946475531';
export const ENV_FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || 'tryvenue-staging';
export const ENV_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  'tryvenue-staging.appspot.com';
export const ENV_GOOGLE_OAUTH2_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID || '';
export const ENV_MOCK_SERVER = process.env.REACT_APP_MOCK_SERVER || '';
export const ENV_POSTHOG_API_HOST =
  process.env.REACT_APP_POSTHOG_API_HOST || '';
export const ENV_POSTHOG_TOKEN = process.env.REACT_APP_POSTHOG_TOKEN || '';
export const ENV_PUBNUB_PUBLISH_KEY =
  process.env.REACT_APP_PUBNUB_PUBLISH_KEY || '';
export const ENV_PUBNUB_SUBSCRIBE_KEY =
  process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || '';
export const ENV_SIGN_UP_TYPE = process.env.REACT_APP_SIGN_UP_TYPE || '';
export const ENV_STRIPE_CLIENT_KEY =
  process.env.REACT_APP_STRIPE_CLIENT_KEY || '';
export const ENV_STRIPE_LARGE_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LARGE_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_LARGE_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LARGE_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_LARGE_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LARGE_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_LARGE_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LARGE_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_MEDIUM_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_MEDIUM_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_MEDIUM_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_MEDIUM_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_MEDIUM_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_MEDIUM_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_MEDIUM_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_MEDIUM_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_SMALL_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_SMALL_PLUS_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_SMALL_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_SMALL_PLUS_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_SMALL_TEAM_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_SMALL_TEAM_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_SMALL_TEAM_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_SMALL_TEAM_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_STRIPE_STARTER_PLAN_ANNUALLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_STARTER_PLAN_ANNUALLY_PRICE_ID || '';
export const ENV_STRIPE_STARTER_PLAN_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_STARTER_PLAN_MONTHLY_PRICE_ID || '';
export const ENV_TENOR_API_KEY = process.env.REACT_APP_TENOR_API_KEY || '';
export const ENV_WEBSITE_URL =
  process.env.REACT_APP_WEBSITE_URL || 'https://app.venue.live';
export const ENV_VOD_API_URL =
  process.env.REACT_APP_VOD_API_URL || 'http://localhost:3001';
export const ENV_IS_STORYBOOK = process.env.STORYBOOK || false;
