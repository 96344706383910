import { Reducer } from 'redux';
import { ShoutOutActions, ShoutOutActionTypes, ShoutOutState } from './types';

export const initialState: ShoutOutState = {
  userId: null,
  state: 'hidden',
  delayedAuthor: null,
  delayedContent: null,
};

export const shoutOutReducer: Reducer<ShoutOutState> = (
  state: ShoutOutState = initialState,
  action: ShoutOutActions
) => {
  switch (action.type) {
    case ShoutOutActionTypes.SHOW_CONTENT:
      return {
        ...state,
        state: 'slide_in',
        delayedAuthor: action.delayedAuthor,
      };
    case ShoutOutActionTypes.LOAD_CONTENT:
      return {
        ...state,
        state: state.state === 'slide_in' ? 'slide_out' : state.state,
        userId:
          action.content != null && 'userId' in action.content
            ? action.content.userId
            : null,
      };
    case ShoutOutActionTypes.ANIMATION_ENDED:
      if (action.content != null) {
        return {
          ...state,
          state: 'hidden',
          delayedContent: action.content,
        };
      } else if (state.state !== 'hidden') {
        return {
          ...state,
          state: 'hidden',
          delayedAuthor: null,
          delayedContent: null,
          userId: null,
        };
      }

      return state;
    default:
      return state;
  }
};
