// organize-imports-ignore
import 'tailwind.css';
import './wdyr';

import { ENV_MOCK_SERVER } from '@common/env';
import { initProductAnalytics } from '@common/services/posthog.service';
import { App } from '@venue/common/App';
import { enableAllPlugins } from 'immer';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

enableAllPlugins();

if (process.env.NODE_ENV !== 'production' && ENV_MOCK_SERVER) {
  import('@venue/test/server').then(({ setupDevelopmentServer }) => {
    const server = setupDevelopmentServer();
    server.start();
  });
}

initProductAnalytics();

async function bootstrapApplication() {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  );
}

bootstrapApplication();
