import { Command } from '@venue/types/commands';
import axios from 'axios';
import { API_URL } from './venue';

interface CreateCommandArgs {
  eventId: string;
  type: Command;
  user?: string;
  stage?: string;
  state?: string;
  substate?: string;
  value?: string;
}

export const createCommand = ({
  eventId,
  type,
  user,
  stage,
  state,
  substate,
  value,
}: CreateCommandArgs) => {
  return axios.post(API_URL + `/events/${eventId}/commands`, {
    type,
    user,
    stage,
    state,
    substate,
    value,
  });
};
