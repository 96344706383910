import axios from 'axios';
import { API_URL } from './venue';

interface StartRecordingArgs {
  eventId: string;
  breakoutRoomId?: string;
}

type RecordingMode = 'mix' | 'individual' | 'web' | 'hyperbeam';

export type RecordingResponse = Array<{
  id: string;
  mode: RecordingMode;
}>;

export const startRecording = async ({
  eventId,
  breakoutRoomId,
}: StartRecordingArgs) => {
  const response = await axios.post<RecordingResponse>(
    getUrl({ eventId, breakoutRoomId })
  );
  return response.data;
};

export const getRecordingStatuses = async ({
  eventId,
  breakoutRoomId,
}: StartRecordingArgs) => {
  const response = await axios.get<RecordingResponse>(
    getUrl({ eventId, breakoutRoomId })
  );
  return response.data;
};

const getUrl = ({ eventId, breakoutRoomId }: StartRecordingArgs) => {
  return breakoutRoomId
    ? `${API_URL}/events/${eventId}/breakout_rooms/${breakoutRoomId}/recordings`
    : `${API_URL}/events/${eventId}/recordings`;
};
