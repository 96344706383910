// Heavily inspired by:
// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
class ApplicationError extends Error {
  code: string;

  constructor(message: string, code: string = 'UNKNOWN_ERROR') {
    super(message);
    this.code = code;
  }
}

function isApplicationError(error: unknown): error is ApplicationError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'code' in error &&
    typeof (error as Record<string, unknown>).message === 'string' &&
    typeof (error as Record<string, unknown>).code === 'string'
  );
}

export function toApplicationError(maybeError: unknown): ApplicationError {
  if (isApplicationError(maybeError)) {
    return maybeError;
  }

  try {
    return new ApplicationError(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new ApplicationError(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toApplicationError(error).message;
}

export function getErrorCode(error: unknown) {
  return toApplicationError(error).code;
}
