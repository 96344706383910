import { formatFirestoreError } from '@venue/utils/firestoreError';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
} from 'firebase/firestore';

let totalActiveListeners: { [listener: string]: number } = {};

export const retrieveListenersStats = () => totalActiveListeners;

export const incrementListener = (listenerName: string) => {
  totalActiveListeners[listenerName] =
    (totalActiveListeners[listenerName] || 0) + 1;
};

export const decrementListener = (listenerName: string) => {
  totalActiveListeners[listenerName] =
    (totalActiveListeners[listenerName] || 0) - 1;
};

// This is incorrect, ideally the overload definition would be Query,
// QuerySnapshot but Typescript for some unknown reason does not allow us to
// define that overload.
export function createFirestoreListener<T = DocumentData>({
  file,
  method,
  ref,
  onComplete,
}: {
  file: string;
  method: string;
  ref: Query<T> | DocumentReference<T>;
  onComplete?: (snapshot: QuerySnapshot<T> | DocumentSnapshot<T>) => void;
}): () => void;

export function createFirestoreListener<T = DocumentData>({
  file,
  method,
  ref,
  onComplete,
}: {
  file: string;
  method: string;
  ref: DocumentReference<T>;
  onComplete?: (snapshot: DocumentSnapshot<T>) => void;
}): () => void {
  const cleaner = onSnapshot<T>(ref, onComplete, (err: FirestoreError) => {
    const errorMessage = formatFirestoreError(file, method, err);
    throw Error(errorMessage);
  });
  incrementListener(`${file}-${method}`);
  return () => {
    cleaner();
    decrementListener(`${file}-${method}`);
  };
}
