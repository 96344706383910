export interface AutoplayState {
  readonly blocked: boolean;
  readonly hasInteracted: boolean;
}

export enum AutoplayActionTypes {
  SET_BLOCKED = '[Autoplay] Set Blocked',
  INTERACT = '[Autoplay] Interact',
}

export interface SetBlockedAction {
  readonly type: typeof AutoplayActionTypes.SET_BLOCKED;
  readonly blocked: boolean;
}

export interface InteractAction {
  readonly type: typeof AutoplayActionTypes.INTERACT;
}

export type AutoplayActions = SetBlockedAction | InteractAction;
