import {
  faBars,
  faCog,
  faCommentAltDots,
  faComments,
  faImages,
  faLifeRing,
  faListAlt,
  faListMusic,
  faMagicWandSparkles,
  faMessageQuestion,
  faMicrophoneStand,
  faPaintRoller,
  faPodium,
  faPoll,
  faSmile,
  faSortSizeUp,
  faThermometerHalf,
  faThLarge,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { Tab, TabValue } from '@venue/types/tab';

export const mapTabValueToTab: { [key in TabValue]: Tab } = {
  [TabValue.Profile]: {
    icon: faUser,
    value: TabValue.Profile,
    isOrganizer: false,
  },
  [TabValue.EventSettings]: {
    icon: faCog,
    value: TabValue.EventSettings,
    isOrganizer: true,
  },
  [TabValue.Backstage]: {
    icon: faComments,
    value: TabValue.Chat,
    isOrganizer: true,
    roomFeature: 'showBackstage',
  },
  [TabValue.Chat]: {
    icon: faCommentAltDots,
    value: TabValue.Chat,
    isOrganizer: false,
    roomFeature: 'showMessages',
  },
  [TabValue.Questions]: {
    icon: faMessageQuestion,
    value: TabValue.Questions,
    isOrganizer: false,
    roomFeature: 'showQuestions',
  },
  [TabValue.Polls]: {
    icon: faPoll,
    value: TabValue.Polls,
    isOrganizer: false,
    roomFeature: 'showPolls',
  },
  [TabValue.Help]: {
    icon: faLifeRing,
    value: TabValue.Help,
    isOrganizer: false,
    roomFeature: 'showHelp',
  },
  [TabValue.SpeakerQueue]: {
    icon: faMicrophoneStand,
    value: TabValue.SpeakerQueue,
    isOrganizer: true,
  },
  [TabValue.Activities]: {
    icon: faBars,
    value: TabValue.Activities,
    isOrganizer: true,
  },
  [TabValue.Reactions]: {
    icon: faSmile,
    value: TabValue.Reactions,
    isOrganizer: true,
  },
  [TabValue.Agenda]: {
    icon: faListAlt,
    value: TabValue.Agenda,
    isOrganizer: false,
    roomFeature: 'showAgenda',
  },
  [TabValue.CustomTheme]: {
    icon: faPaintRoller,
    value: TabValue.CustomTheme,
    isOrganizer: true,
  },
  [TabValue.Share]: {
    icon: faImages,
    value: TabValue.Share,
    isOrganizer: true,
  },
  [TabValue.People]: {
    icon: faUsers,
    value: TabValue.People,
    isOrganizer: false,
  },
  [TabValue.StageLayout]: {
    icon: faSortSizeUp,
    value: TabValue.StageLayout,
    isOrganizer: true,
  },
  [TabValue.Music]: {
    icon: faListMusic,
    value: TabValue.Music,
    isOrganizer: true,
    roomFeature: 'showMusic',
  },
  [TabValue.Actions]: {
    icon: faMagicWandSparkles,
    value: TabValue.Actions,
    isOrganizer: true,
  },
  [TabValue.Spaces]: {
    icon: faThLarge,
    value: TabValue.Spaces,
    isOrganizer: true,
  },
  [TabValue.SpeakerGroups]: {
    icon: faPodium,
    value: TabValue.SpeakerQueue,
    isOrganizer: true,
  },
  [TabValue.SoundEffects]: {
    icon: faListMusic,
    value: TabValue.Music,
    isOrganizer: true,
  },
  [TabValue.QualitativeFeedback]: {
    icon: faThermometerHalf,
    value: TabValue.QualitativeFeedback,
    isOrganizer: true,
  },
};
