import {
  DEVICE_PREFERENCES_KEY,
  PREFERENCES_TO_PERSIST,
} from '@venue/store/device/contants';
import { deviceSelector } from '@venue/store/device/selectors';
import { saveLocalStorage } from '@venue/store/localStorage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const MemorizeDevice = () => {
  const device = useSelector(deviceSelector);

  useEffect(() => {
    if (device != null) {
      saveLocalStorage(
        DEVICE_PREFERENCES_KEY,
        PREFERENCES_TO_PERSIST.reduce((acc, key) => {
          acc[key] = device[key];
          return acc;
        }, {})
      );
    }
  }, [device]);

  return <></>;
};
