import { User } from '@venue/types/user';

export interface UsersState {
  readonly onlineUsers: Array<User>;
  readonly shadowUserIds: Array<string>;
  readonly selectedUser: string | null;
  readonly onlineElsewhere: boolean;
}

export enum UsersActionTypes {
  SELECT_USER = '[Users] Select User',
  ONLINE_ELSEWHERE = '[Users] Online Elsewhere',
}

export interface SelectUserAction {
  readonly type: typeof UsersActionTypes.SELECT_USER;
  readonly userId: string;
}

export interface OnlineElsewhereAction {
  readonly type: typeof UsersActionTypes.ONLINE_ELSEWHERE;
  readonly status: boolean;
}

export type UsersActions = SelectUserAction | OnlineElsewhereAction;
