import { Reducer } from 'redux';
import { UsersActions, UsersActionTypes, UsersState } from './types';

const initialState: UsersState = {
  onlineUsers: [],
  shadowUserIds: [],
  selectedUser: null,
  onlineElsewhere: false,
};

export const users: Reducer<UsersState> = (
  state: UsersState = initialState,
  action: UsersActions
): UsersState => {
  switch (action.type) {
    case UsersActionTypes.SELECT_USER: {
      return {
        ...state,
        selectedUser: action.userId,
      };
    }
    case UsersActionTypes.ONLINE_ELSEWHERE: {
      return {
        ...state,
        onlineElsewhere: action.status,
      };
    }
    default:
      return state;
  }
};
