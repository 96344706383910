import { trackEvent } from '@common/services/posthog.service';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { Tooltip } from '@venue/components/atoms/Tooltip';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { StarRatingRadio } from './StarRatingRadio';
import { isStarRating, StarRating } from './types';

type onAction = ({
  venueExperienceRating,
  audienceEngagementRating,
}: {
  venueExperienceRating: StarRating;
  audienceEngagementRating: StarRating;
}) => void;

export interface PresenterQuantitativeFeedbackFormProps {
  onComplete: onAction;
  onDismiss: onAction;
}

export const PresenterQuantitativeFeedbackForm = ({
  onComplete,
  onDismiss,
}: PresenterQuantitativeFeedbackFormProps) => {
  const [question, setQuestion] = useState<
    'venueExperience' | 'audienceEngagement'
  >('venueExperience');
  const [venueExperienceRating, setVenueExperienceRating] =
    useState<StarRating>(null);

  const [audienceEngagementRating, setAudienceEngagementRating] =
    useState<StarRating>(null);

  const _setVenueExperienceRating: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (!isStarRating(event.target.value)) {
        return;
      }

      trackEvent({
        name: 'Presenter Quantitative Feedback::Rate Venue Experience',
        attributes: {
          venueExperienceRating: event.target.value,
        },
      });
      setVenueExperienceRating(event.target.value);
      setQuestion('audienceEngagement');
    }, []);

  const _setAudienceEngagementRating: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        if (!isStarRating(event.target.value)) {
          return;
        }

        setAudienceEngagementRating(event.target.value);
        onComplete({
          venueExperienceRating,
          audienceEngagementRating: event.target.value,
        });
      },
      [onComplete, venueExperienceRating]
    );

  const _onDismiss = useCallback(() => {
    onDismiss({ venueExperienceRating, audienceEngagementRating });
  }, [audienceEngagementRating, onDismiss, venueExperienceRating]);

  return (
    <div className='tw-relative tw-p-4 tw-flex tw-flex-col tw-flex-1 tw-w-full'>
      <div className='tw-flex tw-flex-col tw-flex-1'>
        {question === 'venueExperience' && (
          <>
            <div className='tw-text-lg tw-mb-4 tw-mr-4'>
              <FormattedMessage defaultMessage='How was your experience presenting on Venue today?' />
            </div>
            <StarRatingRadio
              value={venueExperienceRating}
              onChange={_setVenueExperienceRating}
            />
          </>
        )}
        <Transition
          show={question === 'audienceEngagement'}
          enter='tw-transition tw-duration-150 tw-ease-out'
          enterFrom='tw-scale-0'
          enterTo='tw-scale-100'
          leave='tw-transition tw-duration-150 tw-ease-out'
          leaveFrom='tw-scale-100'
          leaveTo='tw-scale-0'
        >
          <>
            <div className='tw-text-lg tw-mb-4 tw-mr-4'>
              <FormattedMessage defaultMessage='How was the audience engagement?' />
            </div>

            <StarRatingRadio
              value={audienceEngagementRating}
              onChange={_setAudienceEngagementRating}
            />
          </>
        </Transition>
      </div>
      <div className='tw-text-lg tw-rounded-full tw-justify-end tw-absolute tw-top-4 tw-right-4'>
        <Tooltip content={<FormattedMessage defaultMessage='Dismiss' />}>
          <button className='' onClick={_onDismiss}>
            <FontAwesomeIcon
              className='tw-text-neutral-400 hover:tw-text-black tw-transition-colors'
              icon={faTimes}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
