import { Reducer } from 'redux';
import {
  NetworkingActions,
  NetworkingActionTypes,
  NetworkingState,
} from './types';

const initialState: NetworkingState = {
  clientId: null,
  localStreamId: null,
  remoteStreamId: null,
  isLocalAudioMuted: false,
  isLocalVideoMuted: false,
  isRemoteAudioMuted: false,
  isRemoteVideoMuted: false,
};

export const networking: Reducer<NetworkingState> = (
  state: NetworkingState = initialState,
  action: NetworkingActions
) => {
  switch (action.type) {
    case NetworkingActionTypes.SET_AGORA_CLIENT:
      return {
        ...state,
        clientId: action.clientId,
      };
    case NetworkingActionTypes.SET_LOCAL_STREAM:
      return {
        ...state,
        localStreamId: action.streamId,
      };
    case NetworkingActionTypes.SET_REMOTE_STREAM:
      return {
        ...state,
        remoteStreamId: action.streamId,
      };
    case NetworkingActionTypes.RESET_NETWORKING_FOR_NEXT_MATCH:
      return {
        ...state,
        localStreamId: null,
        remoteStreamId: null,
        isRemoteAudioMuted: false,
        isRemoteVideoMuted: false,
      };
    case NetworkingActionTypes.RESET_LOCAL_NETWORKING:
      return {
        ...state,
        localStreamId: null,
        isLocalAudioMuted: false,
        isLocalVideoMuted: false,
      };
    case NetworkingActionTypes.RESET_REMOTE_NETWORKING:
      return {
        ...state,
        remoteStreamId: null,
        isRemoteAudioMuted: false,
        isRemoteVideoMuted: false,
      };
    case NetworkingActionTypes.MUTE_LOCAL_AUDIO: {
      return {
        ...state,
        isLocalAudioMuted: true,
      };
    }
    case NetworkingActionTypes.UNMUTE_LOCAL_AUDIO: {
      return {
        ...state,
        isLocalAudioMuted: false,
      };
    }
    case NetworkingActionTypes.MUTE_LOCAL_VIDEO: {
      return {
        ...state,
        isLocalVideoMuted: true,
      };
    }
    case NetworkingActionTypes.UNMUTE_LOCAL_VIDEO: {
      return {
        ...state,
        isLocalVideoMuted: false,
      };
    }
    case NetworkingActionTypes.MUTE_REMOTE_AUDIO: {
      return {
        ...state,
        isRemoteAudioMuted: true,
      };
    }
    case NetworkingActionTypes.UNMUTE_REMOTE_AUDIO: {
      return {
        ...state,
        isRemoteAudioMuted: false,
      };
    }
    case NetworkingActionTypes.MUTE_REMOTE_VIDEO: {
      return {
        ...state,
        isRemoteVideoMuted: true,
      };
    }
    case NetworkingActionTypes.UNMUTE_REMOTE_VIDEO: {
      return {
        ...state,
        isRemoteVideoMuted: false,
      };
    }
    default:
      return state;
  }
};
