import { ColorScheme, Theme } from '@venue/common/theme';

export interface ThemeState {
  readonly theme: Theme;
  readonly colorScheme: ColorScheme;
  readonly titleColor: string;
  readonly themeColor: string;
  readonly backgroundColor: string;
  readonly registrationThemeColor: string;
  readonly registrationAccentColor: string;
  readonly registrationBackgroundColor: string;
}

export enum ThemeActionTypes {
  SET_THEME = '[Theme] Set Theme',
  SET_COLOR_SCHEME = '[Theme] Set Color Scheme',
}

export interface SetThemeAction {
  readonly type: typeof ThemeActionTypes.SET_THEME;
  readonly titleColor: string;
  readonly themeColor: string;
  readonly backgroundColor: string;
  readonly registrationThemeColor: string;
  readonly registrationAccentColor: string;
  readonly registrationBackgroundColor: string;
}

export interface SetColorSchemeAction {
  readonly type: typeof ThemeActionTypes.SET_COLOR_SCHEME;
  readonly colorScheme: ColorScheme;
}

export type ThemeActions = SetThemeAction | SetColorSchemeAction;
